define("tunespeak-ember/controllers/reset-password", ["exports", "tunespeak-ember/config/environment", "tunespeak-ember/controllers/login", "zxcvbn"], function (exports, _environment, _login, _zxcvbn) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ["token", "expired"],
    token: null,
    expired: null,
    ajax: Ember.inject.service(),
    password: null,
    rePassword: null,
    passwordsExist: Ember.computed.and("password", "rePassword"),
    error: null,
    success: false,
    working: false,

    validatePasswordStrength: function validatePasswordStrength(password) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var pwStrength = (0, _zxcvbn.default)(password);
        if (pwStrength.score < 2) {
          _this.set("error", "Password is too weak");
          reject();
        }
        resolve();
      });
    },


    actions: {
      resetPassword: function resetPassword() {
        var _this2 = this;

        if (this.get("working")) {
          return;
        }
        this.set("error", null);
        var password = this.password,
            rePassword = this.rePassword,
            token = this.token;
        // silently fail if no token

        if (!token) {
          return;
        }
        if (password !== rePassword) {
          return this.set("error", "Passwords do not match");
        }
        this.validatePasswordStrength(password).then(function () {
          _this2.set("working", true);
          return _this2.get("ajax").put(_environment.default.APP.API_ROOT + "/password_resets/" + token, {
            data: {
              password_reset: {
                password: password
              }
            }
          }).then(function () {
            _this2.set("success", true);
          }).catch(function (error) {
            if (error && error.payload && error.payload.message) {
              _this2.set("error", error.payload.message);
            }
          }).finally(function () {
            _this2.set("working", false);
          });
        });
      },
      goToPasswordReset: function goToPasswordReset() {
        this.transitionToRoute("login", {
          queryParams: {
            state: _login.States.forgotPassword,
            redirect: "/"
          }
        });
      },
      goToLogin: function goToLogin() {
        this.transitionToRoute("login", {
          queryParams: {
            state: _login.States.login,
            redirect: "/"
          }
        });
      }
    }
  });
});