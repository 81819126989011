define('tunespeak-ember/components/contest-actions/presale-referral', ['exports', 'tunespeak-ember/mixins/components/campaign-action', 'ember-data'], function (exports, _campaignAction, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignAction.default, {
    classNames: ['ts-list-item', 'campaign-action-item', 'presale-referral'],
    actions: {
      didEarnCredit: function didEarnCredit(credit) {
        var _this = this;

        this.get('store').findRecord('user-contest-action', this.get('userAction.id'), {
          reload: true,
          adapterOptions: { contestId: this.get('contest.id') }
        }).then(function (record) {
          _this.set('userAction', record);
        }).catch(function (error) {
          if (error instanceof _emberData.default.UnauthorizedError) {
            _this.get('session').open().then(function () {
              _this.send('didEarnCredit', credit);
            });
          }
        });
      }
    }
  });
});