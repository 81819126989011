define('tunespeak-ember/models/contest-action', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    template: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    directFactor: _emberData.default.attr('number'),
    indirectFactor: _emberData.default.attr('number'),
    pointValue: _emberData.default.attr('number'),
    content: _emberData.default.attr(),
    previewId: _emberData.default.attr('string'),
    previewContent: _emberData.default.attr(),
    previewPointValue: _emberData.default.attr('number'),
    directLimit: _emberData.default.attr('number'),
    directTimeLimit: _emberData.default.attr('number'),
    totalDirectLimit: _emberData.default.attr('number'),
    lengthThreshold: _emberData.default.attr('number'),
    preRelease: _emberData.default.attr('boolean'),
    preSave: _emberData.default.attr('boolean'),
    groupTemplate: _emberData.default.attr('string'),
    groupId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    songsMax: _emberData.default.attr('number'),
    songsMin: _emberData.default.attr('number'),
    spotifyContext: _emberData.default.attr('string'),
    expanded: _emberData.default.attr('boolean'),
    nextDirectResetAt: _emberData.default.attr('date'),
    showResponses: _emberData.default.attr('boolean'),
    groupLabel: Ember.computed('groupTemplate', 'groupId', function () {
      return [this.get('groupTemplate'), this.get('groupId')].compact().join(':');
    }),
    verifyResponses: _emberData.default.attr('boolean'),
    umgCountryOptions: Ember.computed('content.umg_form.consumer_country_options', 'content.umg_form.privacy_policies', function () {
      if (this.get('content.umg_form.consumer_country_options') && this.get('content.umg_form.privacy_policies')) {
        var countries = this.get('content.umg_form.consumer_country_options').sort();
        var supportedKeys = Object.keys(this.get('content.umg_form.privacy_policies')); // ['us', 'ca', 'default']
        // seperate countries into arrays if they have a non-default privacy policy or not
        var customCountries = [];
        var defaultCountries = [];
        countries.forEach(function (country) {
          if (supportedKeys.includes(country.id.toLowerCase())) {
            customCountries.push(country);
          } else {
            defaultCountries.push(country);
          }
        });
        // move 'US' to first of its array
        var usIndex = customCountries.findIndex(function (country) {
          return country.id === 'US';
        });
        if (usIndex) {
          var element = customCountries[usIndex];
          customCountries.splice(usIndex, 1);
          customCountries.splice(0, 0, element);
        }
        // re-combine them
        return customCountries.concat(defaultCountries);
      }
    })
  });
});