define('tunespeak-ember/components/contest-actions/apple-album-save', ['exports', 'tunespeak-ember/mixins/components/spotify-action', 'tunespeak-ember/utils/cookie-intent', 'ember-data'], function (exports, _spotifyAction, _cookieIntent, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_spotifyAction.default, {
    classNames: ['ts-list-item', 'campaign-action-item', 'apple-album-save'],
    apple: Ember.inject.service('apple-music'),
    showWebViewModal: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      // load apple MusicKit library
      this.get('apple').load();
      (0, _cookieIntent.handleFollowOrSaveCookie)(this.get('storage').get('authCookie'), this.get('model.id'), this);
    },
    normalSave: function normalSave() {
      var _this = this;

      if (this.get('session.isEmbeddedWebView')) {
        this.send('toggleWebViewModal');
        return;
      }
      this.get('apple').addToLibrary({
        albums: [this.get('model.content.itunes_id')]
      }).then(function () {
        var options = {};
        options.record = _this.get('store').createRecord('apple-album-save', {
          bandId: _this.get('model.content.band_id'),
          albumId: _this.get('model.content.id'),
          contestId: _this.get('contest.id'),
          promotionId: _this.get('promotionId'),
          contestActionId: _this.get('model.id')
        });
        options.intent = 'save';
        options.service = 'applemusic';
        options.actionId = _this.get('model.id');
        _this.send('saveRecord', options);
        _this.get('googleTracking')('apple-album-save', {
          category: 'campaign-action',
          label: _this.get("model.title")
        });
      }).catch(function (error) {
        if (error) {
          if (error.message === 'MusicKit Unauthorized') {
            _this.send('loginAndSave');
          } else if (error.message === 'Request was rejected because user is not permitted to perform this operation.') {
            _this.set('show401Modal', true);
          }
        }
      });
    },
    aeSave: function aeSave(force) {
      var _this2 = this;

      this.get('session').createAuthCookie({
        intent: 'save',
        action_id: this.get('model.id'),
        service: 'applemusic'
      });
      this.get('appreciationEngine').requireService('applemusic', this.get('contest.id'), force).then(function (aeJWT) {
        var options = {};
        options.record = _this2.get('store').createRecord('apple-album-save', {
          bandId: _this2.get('model.content.band_id'),
          albumId: _this2.get('model.content.id'),
          contestId: _this2.get('contest.id'),
          promotionId: _this2.get('promotionId'),
          contestActionId: _this2.get('model.id'),
          aeJWT: aeJWT
        });
        options.intent = 'save';
        options.service = 'applemusic';
        options.actionId = _this2.get('model.id');
        _this2.send('saveRecord', options);
        _this2.get('googleTracking')('apple-album-save', {
          category: 'campaign-action',
          label: _this2.get('model.title')
        });
      }).catch(function (error) {
        if (error instanceof _emberData.default.UnauthorizedError) {
          _this2.set('show401Modal', true);
        }
      });
    },

    actions: {
      save: function save() {
        if (this.get('useAeService')) {
          this.aeSave();
        } else {
          this.normalSave();
        }
      },
      loginAndSave: function loginAndSave() {
        var _this3 = this;

        this.set('show401Modal', false);
        if (this.get('useAeService')) {
          this.aeSave(true);
        } else {
          this.get('apple').authorize().then(function () {
            _this3.send('save');
          });
        }
      },
      toggleWebViewModal: function toggleWebViewModal() {
        this.toggleProperty('showWebViewModal');
      }
    }
  });
});