define('tunespeak-ember/components/contest-actions/q-follow', ['exports', 'tunespeak-ember/mixins/components/campaign-action', 'ember-data'], function (exports, _campaignAction, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignAction.default, {
    classNames: ['q-follow'],
    flash: Ember.inject.service('flashMessages'),
    waitingForDownload: false,
    userMobile: '',
    isMobileApple: Ember.computed(function () {
      return isMobile.apple.device;
    }),
    isMobileAndroid: Ember.computed(function () {
      return isMobile.android.device;
    }),
    formattedUserMobile: Ember.computed('userMobile', function () {
      var phone = this.get('userMobile').replace(/[^\d]/g, "");
      if (phone.length == 10) {
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      }
      return phone;
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('working', true);
      Ember.$.getScript('https://cdn-q.tunespeak.com/sdk/auth/app.js', function () {
        _this.set('working', false);
      });
    },
    createFollow: function createFollow() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this.get('googleTracking')('q-follow', {
        category: 'campaign-action',
        label: this.get('title')
      });
      options.contestId = this.get('contest.id');
      options.contestActionId = this.get('model.id');
      options.followableId = this.get('bandId');
      options.followableType = 'Band';
      options.promotionId = this.get('promotionId');
      var qFollow = this.get('store').createRecord('q-follow', options);
      return qFollow.save();
    },
    beforeDownload: function beforeDownload(userInput) {
      this.set('userMobile', userInput.mobile);
      return this.createFollow({
        mobile: userInput.mobile
      });
    },

    actions: {
      qFollow: function qFollow() {
        var _this2 = this;

        if (this.get('session.currentUser.q') && this.get('session.currentUser.q.token_valid')) {
          this.createFollow().then(function () {
            _this2.set('userAction.completed', true);
          }).catch(function (error) {
            if (error instanceof _emberData.default.UnauthorizedError) {
              _this2.send('forceLoginFlow');
            } else {
              throw error;
            }
          });
        } else {
          this.get('googleTracking')('opened-q-follow-modal', {
            category: 'campaign-action',
            label: this.get('title')
          });
          this.send('forceLoginFlow');
        }
      },
      forceLoginFlow: function forceLoginFlow() {
        var _this3 = this;

        this.get('session').loginService('q', {
          beforeDownload: this.beforeDownload.bind(this)
        }).then(function (authResponse) {
          _this3.get('store').createRecord('user-q', {
            extId: authResponse.session.user.id,
            username: authResponse.session.user.username,
            accessToken: authResponse.session.access_token.token,
            expiresAt: authResponse.session.access_token.expires_at
          }).save().then(function () {
            _this3.createFollow().then(function () {
              _this3.set('userAction.completed', true);
            });
          }).catch(function (error) {
            var message = 'An error occurred';
            if (error instanceof _emberData.default.InvalidError) {
              if (error.description) {
                message = error.description;
              }
            }
            _this3.get('flash').danger(message, {
              timeout: 5000,
              extendedTimeout: 500,
              destroyOnClick: false
            });
          });
        }).catch(function (error) {
          if (error && error.code) {
            //user created download link
            if (error.code === '1') {
              _this3.set('waitingForDownload', true);
              return;
              //user manually closed modal
            } else if (error.code === '2') {
              return;
            }
          }
          throw error;
        });
      },
      didEarnCredit: function didEarnCredit() {
        var _this4 = this;

        this.get('store').findRecord('user-contest-action', this.get('userAction.id'), {
          reload: true,
          adapterOptions: { contestId: this.get('contest.id') }
        }).then(function (record) {
          _this4.setProperties({
            userAction: record,
            waitingForDownload: false,
            downloaded: true
          });
        });
      }
    }
  });
});