define('tunespeak-ember/models/poll', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    question: _emberData.default.attr('string'),
    choices: _emberData.default.attr(),
    totalVotes: Ember.computed('choices.@each.votes', function () {
      return this.get('choices').reduce(function (acc, result) {
        return acc + result.votes;
      }, 0);
    })
  });
});