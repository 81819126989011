define('tunespeak-ember/mixins/routes/shareable-meta', ['exports', 'ember-cli-meta-tags/mixins/route-meta', 'tunespeak-ember/config/environment'], function (exports, _routeMeta, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_routeMeta.default, {
    setShareableMetaTags: function setShareableMetaTags(shareableModel) {
      var promotion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      var title = shareableModel.get('ogTitle'),
          description = shareableModel.get('ogDescription'),
          photo = shareableModel.get('shareableSocialPhoto');

      var meta = {
        property: {
          'fb:app_id': _environment.default.facebook.appId,
          'og:image': photo,
          'og:url': location,
          'og:type': 'website'
        },
        name: {
          'twitter:image:src': photo,
          'twitter:site': '@tunespeak',
          'twitter:card': 'summary_large_image',
          'twitter:domain': location.protocol + '//' + location.host
        }
      };

      if (title) {
        meta.property['og:title'] = title;
        meta.name['twitter:title'] = title;
      }

      if (description) {
        meta.property['og:description'] = description;
        meta.name['twitter:description'] = description;
      }

      if (promotion && promotion.get('promoterType') === 'User') {
        // if campaign shareable info will be on share action
        if (shareableModel.constructor.modelName === 'campaign') {
          var shareAction = shareableModel.get('contestActions').findBy('template', 'share');
          if (shareAction) {
            var ogTitle = shareAction.get('content.og_title');
            var ogDescription = shareAction.get('content.og_description');
            if (ogTitle) {
              meta.property['og:title'] = ogTitle;
            }
            if (ogDescription) {
              meta.property['og:description'] = ogDescription;
            }
          }
          // not campaign check for shareable info directly on model
        } else {
          var _shareableModel$getPr = shareableModel.getProperties(['shareOgTitle', 'shareOgDescription']),
              shareOgTitle = _shareableModel$getPr.shareOgTitle,
              shareOgDescription = _shareableModel$getPr.shareOgDescription;

          if (shareOgTitle) {
            meta.property['og:title'] = shareOgTitle;
          }
          if (shareOgDescription) {
            meta.property['og:description'] = shareOgDescription;
          }
        }
      }

      if (shareableModel.get('demo')) {
        meta.name['robots'] = 'noindex, nofollow';
      }

      this.set('meta', meta);
    }
  });
});