define('tunespeak-ember/controllers/redemption/release', ['exports', 'tunespeak-ember/mixins/controllers/redemption/affidavit', 'ember-data'], function (exports, _affidavit, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validationMessage = "Please fix errors with your information and try again.";

  exports.default = Ember.Controller.extend(_affidavit.default, {
    actions: {
      submit: function submit() {
        var _this = this;

        if (!this.get('canSubmit')) {
          return;
        }

        if (this.validate()) {
          var name = this.name,
              age = this.age,
              street = this.street,
              city = this.city,
              state = this.state,
              zip = this.zip,
              email = this.email,
              phone = this.phone,
              signature = this.signature;

          this.get('model.affidavit').setProperties({
            name: name,
            age: age,
            street: street,
            city: city,
            state: state,
            zip: zip,
            email: email,
            phone: phone,
            signature: signature
          });
          this.set('isSubmitting', true);
          this.get('model').setProperties({
            submitQuestions: false,
            submitAffidavit: true
          });
          this.get('model').save().catch(function (e) {
            var msg = 'An error occurred, please try again or contact support@tunespeak.com';
            if (e instanceof _emberData.default.InvalidError) {
              msg = validationMessage;
            } else if (e.description) {
              msg = e.description;
            }
            _this.get('flash').danger(msg, {
              timeout: 5000,
              extendedTimeout: 500,
              destroyOnClick: false
            });
          }).finally(function () {
            _this.set('isSubmitting', false);
          });
        } else {
          this.get('flash').danger(validationMessage, {
            timeout: 5000,
            extendedTimeout: 500,
            destroyOnClick: false
          });
        }
      }
    }
  });
});