define('tunespeak-ember/components/contest-actions/poll-response', ['exports', 'tunespeak-ember/mixins/components/campaign-action'], function (exports, _campaignAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignAction.default, {
    classNames: ['poll-response'],
    choices: null,
    results: null,
    isShowingResults: false,
    isVertical: Ember.computed.equal('model.content.layout', 'vertical'),
    selected: Ember.computed('choices.@each.value', function () {
      return this.get('choices') ? this.get('choices').findBy('value', true) : null;
    }),
    showModal: false,
    selectedPhoto: null,
    canToggleResults: Ember.computed('model.content.show_results', 'userAction.completed', function () {
      return this.get('model.content.show_results') && this.get('userAction.completed');
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (!this.get('userAction.completed')) {
        this.set('choices', this.get('model.content.choices').map(function (choice) {
          return new Ember.Object(choice);
        }));
      }
    },
    calculatePercentages: function calculatePercentages(choices, total) {
      // add percentage and floored_percentage to choice objects
      var extendedChoices = choices.map(function (choice) {
        choice.percentage = (choice.votes / total * 100).toFixed(3);
        choice.floored_percentage = Math.floor(choice.percentage);
        return choice;
      });

      // calculate diff between 100 and our total floored percentages
      var diff = 100 - extendedChoices.reduce(function (acc, choice) {
        return acc + choice.floored_percentage;
      }, 0);

      // sort choices descending by their decimal value
      var sorted = extendedChoices.sort(function (a, b) {
        return -1 * (a.percentage - a.floored_percentage - (b.percentage - b.floored_percentage));
      });

      // distribute the remaining percentage to the sorted array
      return sorted.map(function (choice, index) {
        choice.percentage = index < diff ? choice.floored_percentage + 1 : choice.floored_percentage;
        return choice;
      }).sortBy('percentage').reverse();
    },
    fetchResults: function fetchResults() {
      var _this = this;

      this.get('store').findRecord('poll', this.get('model.content.id'), {
        reload: true,
        adapterOptions: {
          contestId: this.get('contest.id'),
          contestActionId: this.get('model.id')
        }
      }).then(function (res) {
        if (res.get('choices')) {
          _this.set('results', _this.calculatePercentages(res.get('choices'), res.get('totalVotes')));
        }
      });
    },


    actions: {
      submitChoice: function submitChoice() {
        var _this2 = this;

        if (!this.get('working')) {
          this.get('store').createRecord('poll-response', {
            pollId: this.get('model.content.id'),
            pollChoiceId: this.get('selected.id'),
            contestId: this.get('contest.id'),
            contestActionId: this.get('model.id'),
            promotionId: this.get('promotionId')
          }).save().then(function () {
            _this2.set('userAction.completed', true);
            _this2.get('googleTracking')('poll-response', {
              category: 'campaign-action',
              label: _this2.get('title')
            });
          }).finally(function () {
            var res = _this2.get('store').peekRecord('poll', _this2.get('model.content.id'));
            _this2.setProperties({
              working: false,
              results: _this2.calculatePercentages(res.get('choices'), res.get('totalVotes')),
              isShowingResults: _this2.get('model.content.show_results')
            });
          });
        }
      },
      onSelect: function onSelect(id, value) {
        if (value) {
          this.get('choices').forEach(function (choice) {
            if (choice.get('id') !== id) {
              choice.set('value', false);
            }
          });
        }
      },
      showResults: function showResults() {
        this.toggleProperty('isShowingResults');
        if (!this.get('results')) {
          this.fetchResults();
        }
      },
      togglePhotoModal: function togglePhotoModal(photo) {
        this.toggleProperty('showModal');
        this.set('selectedPhoto', photo);
      }
    }
  });
});