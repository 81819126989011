define('tunespeak-ember/components/enrollment-warnings-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  var years = [];
  for (var i = new Date().getFullYear(); i >= 1900; i--) {
    years.push(i);
  }
  exports.default = Ember.Component.extend({
    enrollmentWarnings: Ember.inject.service(),
    warnings: null,
    activeWarning: Ember.computed('warnings.[]', function () {
      return this.get('warnings.lastObject');
    }),
    days: null,
    months: null,
    years: null,
    day: null,
    month: null,
    year: null,
    showAgeError: false,
    validBirthday: Ember.computed.and('day', 'month', 'year'),
    isOverMinAge: Ember.computed('validBirthday', 'activeWarning', function () {
      if (this.get('validBirthday')) {
        var day = this.day,
            month = this.month,
            year = this.year;

        var birthday = moment(month + '-' + day + '-' + year, 'MMM-DD-YYYY');
        return moment().diff(birthday, 'years') >= this.get('activeWarning.min_age');
      }
      return false;
    }),
    continueDisabled: Ember.computed('validBirthday', 'activeWarning.min_age', function () {
      return this.get('activeWarning.min_age') && !this.get('validBirthday');
    }),
    fiveSecondsOfSummer: Ember.computed('enrollmentWarnings.contestId', function () {
      // five seconds of summer campaign override
      return this.get('enrollmentWarnings.contestId') === '62615464d851c50001486323';
    }),

    didInsertElement: function didInsertElement() {
      this._super();
      this.setProperties({
        warnings: [].concat(_toConsumableArray(this.get('enrollmentWarnings.warnings'))),
        months: months,
        days: days,
        years: years
      });
    },
    _acknowledgeWarning: function _acknowledgeWarning() {
      var warnings = this.get('warnings');
      if (warnings.length > 1) {
        warnings.popObject();
      } else {
        this.get('enrollmentWarnings').done();
      }
    },


    actions: {
      closeModal: function closeModal() {
        this.get('enrollmentWarnings').cancel();
      },
      acknowledgeWarning: function acknowledgeWarning() {
        // if min-age check inputs
        if (this.get('activeWarning.min_age')) {
          this.set('showAgeError', false);
          if (this.get('isOverMinAge')) {
            this._acknowledgeWarning();
          } else {
            this.set('showAgeError', true);
          }
          // else acknowledge and continue
        } else {
          this._acknowledgeWarning();
        }
      },
      select: function select(key, val) {
        this.set(key, val);
      }
    }
  });
});