define('tunespeak-ember/components/contest-actions/setlist-submission', ['exports', 'tunespeak-ember/mixins/components/campaign-action', 'ember-data'], function (exports, _campaignAction, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  WebFont.load({
    google: {
      families: ['Permanent Marker']
    }
  });

  exports.default = Ember.Component.extend(_campaignAction.default, {
    classNames: ['setlist-submission'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('userAction.completed')) {
        this.get('store').query('setlist', { "contest_action_id": this.get('model.id') }).then(function (setlists) {
          _this.set('setlist', setlists.get('firstObject'));
        });
      } else {
        var init = new Array(this.get('model.songsMin'));
        for (var i = 0; i < init.length; i++) {
          init[i] = {};
        }
        this.setProperties({
          'setlist': init,
          'albums': []
        });
      }
    },

    setlistValid: Ember.computed('setlist.[]', 'setlistName', function () {
      var added = this.get('setlist').reduce(function (acc, item) {
        return item.type ? acc + 1 : acc; //initalized as {}, if type set then the song has been selected
      }, 0);

      return added >= this.get('model.songsMin');
    }),
    canAddAnotherTrack: Ember.computed('setlist.[]', function () {
      return this.get('setlist').isEvery('type', 'track') && this.get('setlist.length') < this.get('model.songsMax');
    }),
    albums: null,
    setlist: null,
    setlistName: null,
    setlistIds: Ember.computed.mapBy('setlist', 'id'),
    setlistOpen: Ember.computed('userAction.completed', 'model.expanded', function () {
      return this.get('userAction.completed') ? false : this.get('model.expanded');
    }),
    index: 0,
    creatingSpotifyPlaylist: false,
    needsReauth: false,

    actions: {
      clearSetlistName: function clearSetlistName() {
        this.set('setlistName', '');
      },
      openModal: function openModal(index) {
        var _this2 = this;

        this.set('index', index);
        this.set('modalOpen', true);
        if (!this.get('albums.length')) {
          this.set('loading', true);
          this.get('store').findRecord('setlist-call', this.get('model.content.id')).then(function (model) {
            _this2.get('albums').pushObjects(model.get('spotifyAlbumRes.albums'));
          }).finally(function () {
            _this2.set('loading', false);
          });
        }
      },
      closeModal: function closeModal() {
        this.set('modalOpen', false);
      },
      selectSong: function selectSong(song, album) {
        var track = Ember.Object.create(song);
        track.set('thumbnail', album.images[1].url);
        track.set('album_name', album.name);
        this.get('setlist').replace(this.get('index'), 1, [track]);
        this.set('modalOpen', false);
      },
      removeSong: function removeSong(index) {
        this.get('setlist').replace(index, 1, [{}]);
      },
      changePosition: function changePosition(index, direction) {
        var setlist = this.get('setlist');
        var newPosition = direction === 'down' ? index + 1 : index - 1;
        if (newPosition < 0 || newPosition > setlist.length - 1) return;
        var replaced = setlist[newPosition];
        setlist.replace(newPosition, 1, [setlist[index]]).replace(index, 1, [replaced]);
      },
      toggleMobileOptions: function toggleMobileOptions(index) {
        var setlistItem = Ember.$('.setlist').find('[data-index=\'' + index + '\']');
        setlistItem.toggleClass('selected');
        setlistItem.find('.mobile-menu').toggleClass('hidden');
      },
      addSongSlot: function addSongSlot() {
        this.get('setlist').pushObject({});
        this.send('openModal', this.get('setlist.length') - 1);
      },
      saveSetlist: function saveSetlist() {
        var _this3 = this;

        this.set('working', true);
        var filtered = this.get('setlist').filterBy('type', 'track');
        this.get('store').createRecord('setlist', {
          name: this.get('setlistName') || this.get('session.currentUser.firstName') + '\'s Setlist',
          userId: this.get('session.currentUser.id'),
          contestId: this.get('contest.id'),
          setlistCallId: this.get('model.content.id'),
          contestActionId: this.get('model.id'),
          promotionId: this.get('promotionId'),
          songs: filtered.map(function (track) {
            return { spotify_id: track.id };
          })
        }).save().then(function (setlist) {
          _this3.setProperties({
            setlist: setlist,
            'userAction.completed': true,
            setlistOpen: false
          });
        }).finally(function () {
          _this3.set('working', false);
        });
      },
      createSpotifyPlaylist: function createSpotifyPlaylist() {
        var _this4 = this;

        this.get('session').requireService('spotify').then(function () {
          _this4.set('creatingSpotifyPlaylist', true);
          _this4.get('setlist').createSpotifyPlaylist().catch(function (error) {
            if (error instanceof _emberData.default.UnauthorizedError) {
              _this4.set('needsReauth', true);
            }
          }).finally(function () {
            _this4.set('creatingSpotifyPlaylist', false);
          });
        });
      },
      authenticateSpotify: function authenticateSpotify() {
        var _this5 = this;

        this.get('session').requireService('spotify', true).then(function () {
          _this5.set('needsReauth', false);
          _this5.send('createSpotifyPlaylist');
        }).catch();
      }
    }
  });
});