define('tunespeak-ember/adapters/user-contest', ['exports', 'tunespeak-ember/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    queryRecord: function queryRecord(store, type, query) {
      return this.findRecord(store, type, query.contest_id);
    },
    urlForQuery: function urlForQuery(_ref) {
      var group_id = _ref.group_id,
          invenue_id = _ref.invenue_id;
      var host = this.host;

      if (invenue_id) {
        return host + '/in_venues/' + invenue_id + '/user_contests';
      }
      return host + '/contest_groups/' + group_id + '/user_contests';
    }
  });
});