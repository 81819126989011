define('tunespeak-ember/helpers/minutes-and-seconds', ['exports', 'tunespeak-ember/helpers/ts-pluralize'], function (exports, _tsPluralize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.minutesAndSeconds = minutesAndSeconds;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function minutesAndSeconds(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        time = _ref2[0],
        unit = _ref2[1];

    var input = unit === 'ms' ? time * 1000 : time;
    var minutes = Math.floor(input / 60);
    var seconds = input % 60;
    if (minutes >= 1) {
      if (seconds > 0) {
        return minutes + ' ' + (0, _tsPluralize.tsPluralizeWord)([minutes, 'min']) + ' & ' + seconds + ' ' + (0, _tsPluralize.tsPluralizeWord)([seconds, 'second']);
      }
      return minutes + ' ' + (0, _tsPluralize.tsPluralizeWord)([minutes, 'min']);
    } else {
      return seconds + ' ' + (0, _tsPluralize.tsPluralizeWord)([seconds, 'second']);
    }
  }

  exports.default = Ember.Helper.helper(minutesAndSeconds);
});