define('tunespeak-ember/components/ts-conditional-link', ['exports', '@tunespeak/tunespeak-elements/components/ts-conditional-link'], function (exports, _tsConditionalLink) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tsConditionalLink.default;
    }
  });
});