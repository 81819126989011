define('tunespeak-ember/components/share/twitter-share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  (function () {
    if (window.__twitterIntentHandler) {
      return;
    }
    var intentRegex = /twitter\.com\/intent\/(\w+)/,
        windowOptions = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes',
        width = 550,
        height = 420,
        winHeight = screen.height,
        winWidth = screen.width;

    function handleIntent(e) {
      e = e || window.event;
      var target = e.target || e.srcElement,
          m,
          left,
          top;

      while (target && target.nodeName.toLowerCase() !== 'a') {
        target = target.parentNode;
      }

      if (target && target.nodeName.toLowerCase() === 'a' && target.href) {
        m = target.href.match(intentRegex);
        if (m) {
          left = Math.round(winWidth / 2 - width / 2);
          top = 0;

          if (winHeight > height) {
            top = Math.round(winHeight / 2 - height / 2);
          }

          window.open(target.href, 'intent', windowOptions + ',width=' + width + ',height=' + height + ',left=' + left + ',top=' + top);
          return e.preventDefault ? e.preventDefault() : e.returnValue = false;
        }
      }
    }

    if (document.addEventListener) {
      document.addEventListener('click', handleIntent, false);
    } else if (document.attachEvent) {
      document.attachEvent('onclick', handleIntent);
    }
    window.__twitterIntentHandler = true;
  })();

  exports.default = Ember.Component.extend({
    text: null,
    tweetText: Ember.computed.or('text', 'model.content.tweet_text'),
    twitterLink: Ember.computed('shareLink', 'tweetText', function () {
      var params = {
        url: 'http://' + this.get('shareLink'),
        text: this.get('tweetText')
      };

      params = Ember.$.param(params);

      return 'https://twitter.com/intent/tweet?' + params;
    }),
    click: function click() {
      this.get("googleTracking")("twitter-share", {
        category: "share",
        label: this.get("contest.title")
      });
    }
  });
});