define('tunespeak-ember/models/contest-group', ['exports', 'ember-data', 'tunespeak-ember/mixins/models/shareable', 'tunespeak-ember/helpers/ts-oxford-array'], function (exports, _emberData, _shareable, _tsOxfordArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_shareable.default, {
    bandId: _emberData.default.attr('string'),
    startsAt: _emberData.default.attr('date'),
    endsAt: _emberData.default.attr('date'),
    title: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    contestPreviews: _emberData.default.hasMany('contest-preview'),
    photo: _emberData.default.attr('string'),
    backgroundPhoto: _emberData.default.attr('string'),
    headerPhoto: _emberData.default.attr('string'),
    headerBgPhoto: _emberData.default.attr('string'),
    contestBands: _emberData.default.hasMany('contest-band', { async: false }),
    campaignTemplate: _emberData.default.attr('string'),
    themeCss: _emberData.default.attr('string'),
    tmVerifiedFan: _emberData.default.attr('boolean'),
    layout: _emberData.default.attr('string'),
    embeddedUrl: _emberData.default.attr('string'),
    demo: _emberData.default.attr('boolean'),
    htmlSnippets: _emberData.default.hasMany('html-snippet', { async: false }),
    name: _emberData.default.attr('string'),
    recordLabelName: _emberData.default.attr('string'),
    recordLabelParent: _emberData.default.attr('string'),
    isUniversal: Ember.computed.equal('recordLabelParent', 'umg'),
    isSony: Ember.computed.equal('recordLabelParent', 'sme'),
    isAeg: Ember.computed.equal('recordLabelParent', 'aeg'),

    recordLabelPrivacyPolicy: _emberData.default.attr('string'),

    bandNames: Ember.computed('contestBands', function () {
      var names = this.get('contestBands').mapBy('name');
      return (0, _tsOxfordArray.tsOxfordArray)(names);
    })
  });
});