define('tunespeak-ember/helpers/ending-soon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.endingSoon = endingSoon;
  function endingSoon(endsAt) {
    endsAt = moment(endsAt[0]);
    var now = moment();
    return endsAt.diff(now, 'days') <= 3;
  }

  exports.default = Ember.Helper.helper(endingSoon);
});