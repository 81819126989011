define('tunespeak-ember/models/song', ['exports', 'ember-data', 'tunespeak-ember/mixins/models/shareable'], function (exports, _emberData, _shareable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_shareable.default, {
    bandId: _emberData.default.attr('string'),
    track: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    albumTitle: _emberData.default.attr('string'),
    spotifyId: _emberData.default.attr('string'),
    length: _emberData.default.attr('number'),
    formattedLength: _emberData.default.attr('string'),
    streamUrl: _emberData.default.attr('string'),
    verb: 'Listen to'
  });
});