define('tunespeak-ember/serializers/user-post', ['exports', 'tunespeak-ember/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    includesRootInJson: false,
    serialize: function serialize() {
      var hash = this._super.apply(this, arguments);
      if (hash.type !== 'TumblrPost') {
        delete hash.tumblr_tags;
      }
      return hash;
    }
  });
});