define('tunespeak-ember/components/contest-actions/youtube-subscription', ['exports', 'tunespeak-ember/mixins/components/campaign-action', 'tunespeak-ember/utils/cookie-intent'], function (exports, _campaignAction, _cookieIntent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignAction.default, {
    classNames: ['ts-list-item', 'campaign-action-item', 'spotify-action-item'],
    youtubeId: Ember.computed.alias('model.content.youtube_id'),
    photo: Ember.computed.or('model.content.youtube_photo', 'model.content.photo'),
    showSubscribeModal: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      //check for cookies with follow intent matching this action's id
      (0, _cookieIntent.handleFollowOrSaveCookie)(this.get('storage').get('authCookie'), this.get('model.id'), this);

      // fetch youtube subscribe btn script
      if (!this.get('useAeService')) {
        (function (d, s, id) {
          var js,
              fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = 'https://apis.google.com/js/platform.js';
          fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'youtube-subscribe-btn-script');
      }
    },

    completedCallback: function completedCallback() {
      if (!this.get('useAeService')) {
        this.set('showSubscribeModal', true);
      }
    },


    actions: {
      follow: function follow() {
        var options = {};
        options.record = this.get('store').createRecord('youtube-subscription', {
          contestId: this.get('contest.id'),
          followableId: this.get('bandId'),
          followableType: 'Band',
          promotionId: this.get('promotionId'),
          contestActionId: this.get('model.id')
        });
        options.intent = 'follow';
        options.service = 'youtube';
        options.actionId = this.get('model.id');

        this.send('saveRecord', options);

        this.get("googleTracking")("youtube-subscribe", {
          category: "campaign-action",
          label: this.get("title")
        });
      },
      loginToYoutubeSubscribe: function loginToYoutubeSubscribe() {
        var _this = this;

        this.set('isLoggingIn', true);
        if (this.get('useAeService')) {
          this.get('appreciationEngine').requireService('youtube', this.get('contest.id'), true).then(function (res) {
            _this.toggleProperty('show401Modal');
            _this.send('follow');
          }).finally(function () {
            _this.set('isLoggingIn', false);
          });
        } else {
          this.get('session').loginService('youtube').then(function () {
            _this.toggleProperty('show401Modal');
            _this.send('follow');
          }).finally(function () {
            _this.set('isLoggingIn', false);
          });
        }
      },
      closeSubscribeModal: function closeSubscribeModal() {
        this.set('showSubscribeModal', false);
      }
    }
  });
});