define('tunespeak-ember/serializers/message', ['exports', 'tunespeak-ember/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Ember.merge(hash, this.serialize(record, options));
    }
  });
});