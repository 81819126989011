define('tunespeak-ember/models/soundcloud-song-save', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    contestId: _emberData.default.attr('string'),
    bandId: _emberData.default.attr('string'),
    songId: _emberData.default.attr('string'),
    promotionId: _emberData.default.attr('string'),
    contestActionId: _emberData.default.attr('string')
  });
});