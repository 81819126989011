define('tunespeak-ember/adapters/campaign', ['exports', 'tunespeak-ember/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    buildMemberActionURL: function buildMemberActionURL(model, action) {
      return this.buildURL('campaign', model.get('id')) + '/' + action;
    },

    urlForWinners: function urlForWinners(id) {
      return this.buildURL('campaign', id) + '/redeemed_packages';
    }
  });
});