define('tunespeak-ember/components/contest-actions/soundcloud-song-play', ['exports', 'tunespeak-ember/mixins/components/campaign-action', 'tunespeak-ember/utils/soundcloud-player', 'ember-data'], function (exports, _campaignAction, _soundcloudPlayer, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var poller = null;

  exports.default = Ember.Component.extend(_campaignAction.default, {
    classNames: ['soundcloud-song-play'],
    playerId: Ember.computed('model.id', function () {
      return 'player-' + this.get('model.id');
    }),
    mediaManager: Ember.inject.service(),
    store: Ember.inject.service(),
    widget: null,
    needsReauth: false,
    pollingInterval: 30000,
    notIdle: Ember.computed.not('isIdle'),
    isBuffering: Ember.computed.alias('player.isBuffering'),
    isPlaying: Ember.computed.alias('player.isPlaying'),
    dailyPointLimit: Ember.computed('model.totalDirectLimit', 'model.pointValue', function () {
      return this.get('model.totalDirectLimit') * this.get('model.pointValue');
    }),
    soundcloudSongId: null,
    songId: Ember.computed('soundcloudSongId', function () {
      var _this = this;

      if (this.get('isAlbum')) {
        var song = this.get('model.content.songs').find(function (song) {
          return song.soundcloud_id === _this.get('soundcloudSongId');
        });
        return song ? song.id : null;
      } else {
        return this.get('model.content.id');
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setupPlayer();
    },
    willDestroy: function willDestroy() {
      if (poller) {
        Ember.run.cancel(poller);
      }
      this.set('play', null);
      this.get('mediaManager').destroy(this.get('player'));
    },
    setupPlayer: function setupPlayer() {
      var _this2 = this;

      return new Promise(function (resolve) {
        var player = new _soundcloudPlayer.default({
          playerId: _this2.get('playerId'),
          onReady: function onReady() {
            _this2.set('player', player);
            _this2.get('mediaManager').add(player);
            resolve(player);
          },
          onStart: function onStart(state, _, event) {
            player.__proto__.onStart.call(player);
            _this2.get('mediaManager').pauseAllExcept(player);
            _this2.playerReset(event);
            _this2.playingPoller(event);
          },
          onPause: function onPause(state, _, event) {
            player.__proto__.onPause.call(player);
            Ember.run.cancel(poller);
            _this2.updatePlay();
          },
          onResume: function onResume(state, _, event) {
            player.__proto__.onResume.call(player);
            _this2.get('mediaManager').pauseAllExcept(player);
            if (_this2.get('soundcloudSongId') !== event.soundId) _this2.playerReset(event);
            _this2.playingPoller(event);
          },
          onFinish: function onFinish() {
            Ember.run.cancel(poller);
            _this2.updatePlay();
            _this2.set('play', null);
          }
        });
        _this2.set('player', player);
      });
    },
    createPlay: function createPlay() {
      var _this3 = this;

      var play = this.get('store').createRecord('play', {
        duration: Math.ceil(this.get('player.totalPlayed') / 1000),
        songId: this.get('songId'),
        promotionId: this.get('promotionId'),
        contestId: this.get('contest.id'),
        contestActionId: this.get('model.id'),
        source: 'soundcloud'
      });
      this.set('play', play);
      play.save().catch(function (error) {
        _this3.set('play', null);
        _this3.send('pause');
        if (error instanceof _emberData.default.UnauthorizedError) {
          _this3.set('needsReauth', true);
        }
      });
    },
    updatePlay: function updatePlay() {
      var _this4 = this;

      if (this.get('play')) {
        this.set('play.duration', Math.ceil(this.get('player.totalPlayed') / 1000));
        return this.get('play').save().catch(function (error) {
          if (error instanceof _emberData.default.UnauthorizedError) {
            _this4.set('needsReauth', true);
          }
          _this4.send('pause');
        });
      }
    },
    playingPoller: function playingPoller() {
      var _this5 = this;

      var interval = this.get('pollingInterval');
      if (this.get('play')) {
        this.updatePlay();
      } else {
        this.createPlay();
      }
      poller = Ember.run.later(function () {
        _this5.playingPoller();
      }, interval);
    },
    playerReset: function playerReset(event) {
      if (this.get('play')) {
        this.set('play', null);
      }
      if (this.get('player')) {
        // set totalplayed and position back to 0
        this.get('player').resetPlayer();
      }
      this.set('soundcloudSongId', event.soundId);
      Ember.run.cancel(poller);
    },
    fetchUserContestAction: function fetchUserContestAction() {
      var _this6 = this;

      this.get('store').findRecord('user-contest-action', this.get('userAction.id'), {
        reload: true,
        adapterOptions: { contestId: this.get('contest.id') }
      }).then(function (record) {
        _this6.set('userAction', record);
      }).catch(function (error) {
        if (error instanceof _emberData.default.UnauthorizedError) {
          _this6.get('session').open().then(function () {
            _this6.send('didEarnCredit');
          });
        }
      });
    },
    updateRemainingContent: function updateRemainingContent() {
      var _this7 = this;

      var uca = this.get('store').peekRecord('user-contest-action', this.get('userAction.id'));
      this.get('store').query('user-contest-item', {
        contest_id: this.get('contest.id'),
        contest_action_id: uca.get('id')
      }).then(function (activities) {
        uca.set('remainingContent', activities);
        uca.set('remaining', activities.reduce(function (acc, activity) {
          return acc + activity.get('remaining');
        }, 0));
      }).catch(function (error) {
        if (error instanceof _emberData.default.UnauthorizedError) {
          _this7.get('session').open();
        }
      });
    },


    actions: {
      didReceiveCredit: function didReceiveCredit(credit) {
        if (credit.get('points')) {
          this._super(credit);
        }
      },
      didEarnCredit: function didEarnCredit() {
        Ember.run.debounce(this, this.fetchUserContestAction, 1000);
      },
      togglePlay: function togglePlay() {
        if (this.get('isPlaying')) {
          this.send('pause');
        } else {
          this.send('play');
        }
      },
      play: function play() {
        this.get('mediaManager').play(this.get('player'));
      },
      pause: function pause() {
        this.get('mediaManager').pause(this.get('player'));
      },
      authenticateSoundcloud: function authenticateSoundcloud() {
        var _this8 = this;

        this.get('session').loginService('soundcloud').then(function () {
          _this8.set('needsReauth', false);
        }).catch(function () {});
      },
      toggleSoundcloudAuth: function toggleSoundcloudAuth() {
        this.set('needsReauth', !this.get('needsReauth'));
      }
    }
  });
});