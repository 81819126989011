define('tunespeak-ember/routes/redemption/skilled-question', ['exports', 'tunespeak-ember/mixins/ts-route'], function (exports, _tsRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRoute.default, {
    alwaysResetScroll: true,
    beforeModel: function beforeModel() {
      var redemption = this.modelFor('redemption');
      // redemption form not complete go to index
      if (!redemption.get('complete')) {
        this.transitionTo('redemption.index');
      } else if (redemption.get('skilledQuestion') && redemption.get('skilledQuestion.responseStatus') !== 'Unanswered') {
        // answered correctly already && affidavit go to release
        if (redemption.get('skilledQuestion.responseStatus') === 'Correct' && redemption.get('affidavit')) {
          this.transitionTo('redemption.release');
          // else go to review
        } else {
          this.transitionTo('redemption.review');
        }
      }
    },
    model: function model() {
      return this.get('store').createRecord('skilled-question', {
        redemptionId: this.modelFor('redemption').id
      }).save().catch(function () {});
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties({
        redemption: this.modelFor('redemption'),
        started: false,
        response: null,
        expired: false
      });
    }
  });
});