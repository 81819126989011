define('tunespeak-ember/components/contest-preview-link', ['exports', 'tunespeak-ember/mixins/enrollment-warnings'], function (exports, _enrollmentWarnings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_enrollmentWarnings.default, {
    tagName: '',
    contestGroup: null,
    userContests: null,
    userContest: Ember.computed('contestId', 'userContests', function () {
      if (this.get('userContests')) {
        return this.get('userContests').findBy('contestId', this.get('contestId'));
      }
    }),
    working: false,
    model: null,
    contestId: Ember.computed.alias('model.id'),
    showLocation: Ember.computed.alias('model.showLocation'),
    showStartsAt: Ember.computed.alias('model.showStartsAt'),
    showVenue: Ember.computed.alias('model.showVenue'),
    isPresale: Ember.computed.equal('contestGroup.campaignTemplate', 'presale'),
    isDeluxeWithPresale: Ember.computed.equal('contestGroup.campaignTemplate', 'deluxe-with-presale'),
    isUnlocked: Ember.computed('model.hasEnrollmentLock', 'userContest.enrollmentLockState', function () {
      if (!this.get('model.hasEnrollmentLock')) return true;
      return this.get('userContest.enrollmentLockState') === 'unlocked';
    }),

    contestStatus: Ember.computed('model.{canceled,ended,started}', function () {
      if (this.get('model.canceled')) {
        return 'CANCELED';
      } else if (this.get('model.ended')) {
        return 'ENDED';
      } else if (!this.get('model.started')) {
        return 'NOT_STARTED';
      } else {
        return 'IN_PROGRESS';
      }
    }),
    enrolled: Ember.computed('userContest', function () {
      var uc = this.get('userContest');
      if (this.get('isPresale')) {
        return !!uc && uc.get('current') !== false;
      }
      return !!uc;
    }),
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.stopWorking);
    },


    actions: {
      enroll: function enroll(id) {
        var _this = this;

        if (this.get('working')) {
          return false;
        }

        this.set('working', true);
        this.stopWorking = Ember.run.later(function () {
          _this.set('working', false);
        }, 3000);

        this.get('enroll')({
          contestId: id,
          title: this.get('model.title'),
          enrollmentConsentIds: this.get('model.enrollmentConsentIds'),
          enrollmentWarnings: this.get('enrollmentWarnings'),
          brand: this.get('model.brand'),
          requireVerifiedEmail: this.get('model.requireVerifiedEmail')
        });
      },
      openRulesModal: function openRulesModal(id) {
        this.get('openRulesModal')(id, this.get('model.rulesPageUrl'));
      }
    }
  });
});