define('tunespeak-ember/adapters/skilled-question', ['exports', 'tunespeak-ember/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    buildURL: function buildURL(modelName, id, snapshot) {
      return this.get('host') + '/redemptions/' + snapshot.attr('redemptionId') + '/skilled_question';
    }
  });
});