define('tunespeak-ember/components/redemption-question/email', ['exports', 'tunespeak-ember/components/redemption-question/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    validate: function validate() {
      this._super();
      if (this.get('errors.length')) {
        return;
      }

      if (this.get('model.inputType') === 'email') {
        if (!this.get('model.answer').match(/^.+@.+\..+$/)) {
          this.get('errors').pushObject('Invalid email address.');
        }
      }
    }
  });
});