define('tunespeak-ember/services/fullscreen', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    _createFullscreenListener: function _createFullscreenListener(caller) {
      var self = this;

      Ember.$(document).on('webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange', function () {
        if (!(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement)) {
          //destroy event listener
          self._removeFullscreenListener();

          //caller callback
          if (caller) {
            caller.didExitFullscreen();
          }
        }
      });
    },
    _removeFullscreenListener: function _removeFullscreenListener() {
      Ember.$(document).off('webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange');
    },
    requestFullscreen: function requestFullscreen(el, caller) {
      //request Fullscreen
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      } else if (el.webkitRequestFullScreen) {
        el.webkitRequestFullScreen();
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen();
      }

      //create document listener
      this._createFullscreenListener(caller);

      //caller callback
      if (caller) {
        caller.didEnterFullscreen();
      }
    },
    exitFullscreen: function exitFullscreen(caller) {
      //destroy document listener
      this._removeFullscreenListener();

      //exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }

      //caller callback
      if (caller) {
        caller.didExitFullscreen();
      }
    }
  });
});