define('tunespeak-ember/models/skilled-question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    redemptionId: _emberData.default.attr('string'),
    question: _emberData.default.attr('string'),
    label: _emberData.default.attr('string'),
    response: _emberData.default.attr('string'),
    responseStatus: _emberData.default.attr('string'),
    isCorrect: Ember.computed.equal('responseStatus', 'Correct')
  });
});