define('tunespeak-ember/helpers/ts-time-ago', ['exports', '@tunespeak/tunespeak-elements/helpers/ts-time-ago'], function (exports, _tsTimeAgo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tsTimeAgo.default;
    }
  });
  Object.defineProperty(exports, 'tsTimeAgo', {
    enumerable: true,
    get: function () {
      return _tsTimeAgo.tsTimeAgo;
    }
  });
});