define('tunespeak-ember/components/appreciation-engine-authorize-apple-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appreciationEngine: Ember.inject.service(),
    callback: Ember.computed.alias('appreciationEngine.callback'),
    isAuthorizing: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('isAuthorizing', false);
    },


    actions: {
      click: function click() {
        if (this.get('isAuthorizing')) return;
        this.set('isAuthorizing', true);
        this.get('appreciationEngine').authorize('applemusic', this.get('callback'));
      },
      close: function close() {
        this.set('appreciationEngine.authorizeAppleMusicModal', false);
      }
    }
  });
});