define('tunespeak-ember/models/band-suggestion', ['exports', 'ember-data', 'tunespeak-ember/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    photo: _emberData.default.attr('string'),
    bandId: Ember.computed.alias('id')
  });
});