define('tunespeak-ember/helpers/ts-lower-case', ['exports', '@tunespeak/tunespeak-elements/helpers/ts-lower-case'], function (exports, _tsLowerCase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tsLowerCase.default;
    }
  });
  Object.defineProperty(exports, 'tsLowerCase', {
    enumerable: true,
    get: function () {
      return _tsLowerCase.tsLowerCase;
    }
  });
});