define('tunespeak-ember/models/conversation', ['exports', 'ember-data', 'tunespeak-ember/mixins/models/conversation'], function (exports, _emberData, _conversation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_conversation.default, {
    thumbnail: Ember.computed(function () {
      return this.get('participants').filterBy('participant_type', 'Band').get('firstObject.avatar');
    }),

    fetchMessages: function fetchMessages() {
      var _this = this;

      return this.store.query('message', { conversation_id: this.id }).then(function (messages) {
        _this.set('messages', messages.toArray());
      });
    }
  });
});