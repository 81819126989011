define('tunespeak-ember/controllers/update-phone', ['exports', 'tunespeak-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        preferredCountries: []
      });
    },


    mustVerify: false,
    working: false,
    token: null,
    error: null,
    success: false,
    phoneVerificationSent: false,

    // Phone Settings
    userPhone: Ember.computed('model.{phone,pendingPhone}', function () {
      if (this.get('model.phone')) {
        return this.get('model.phone');
      }
      return this.get('model.pendingPhone');
    }),
    phoneValue: Ember.computed.oneWay('userPhone'),
    phone: Ember.computed('phoneValue', function () {
      var val = this.get('phoneValue') || '';
      return val.replace(/\D/g, '');
    }),
    phoneChanged: Ember.computed('model.{phone,countryCode}', 'selectedCountryData.iso2', 'phone', 'phoneValue', function () {
      var iso2 = this.get('selectedCountryData.iso2');
      var countryCode = (this.get('model.countryCode') || 'us').toLowerCase();
      var numberChanged = this.get('phone') != (this.get('model.phone') || '');
      var countryChanged = !!(iso2 && iso2 !== countryCode);
      return numberChanged || countryChanged;
    }),
    phoneValid: null,
    phoneSaveDisabled: Ember.computed('phoneValid', function () {
      return !this.get('phoneValid');
    }),
    defaultCountry: Ember.computed('model.countryCode', function () {
      return (this.get('model.countryCode') || 'us').toLowerCase();
    }),
    onlyCountries: null,
    preferredCountries: null,
    selectedCountryData: null,

    actions: {
      updatePhone: function updatePhone() {
        var _this = this;

        this.set('working', true);
        var countryCode = this.get('selectedCountryData.iso2');
        this.get('ajax').put(_environment.default.APP.API_ROOT + '/user_profiles/' + this.get('model.id'), {
          data: { user_profile: {
              phone_verification_attributes: {
                phone: this.get('phone'),
                country_code: countryCode ? countryCode.toUpperCase() : null
              }
            } }
        }).then(function () {
          _this.set('mustVerify', true);
          _this.set('model.pendingPhone', _this.get('phone'));
          _this.send('sendPhoneVerification');
        }).finally(function () {
          _this.set('working', false);
        });
      },
      deletePhone: function deletePhone() {
        var _this2 = this;

        this.get('ajax').put(_environment.default.APP.API_ROOT + '/user_profiles/' + this.get('model.id'), {
          data: { user_profile: {
              phone_verification_attributes: {
                phone: null,
                country_code: null
              }
            } }
        }).then(function () {
          _this2.set('model.phone', null);
          _this2.set('model.pendingPhone', null);
          window.location = '/settings/account';
        });
      },
      verifyPhone: function verifyPhone() {
        var _this3 = this;

        var token = this.token;

        this.set('error', null);
        if (token && token.length === 4) {
          this.set('working', true);
          this.get('ajax').post(_environment.default.APP.API_ROOT + '/verify_phone', {
            data: {
              token: token
            }
          }).then(function () {
            _this3.get('model').reload();
            _this3.setProperties({
              working: false,
              success: true
            });
          }).catch(function (error) {
            _this3.setProperties({
              working: false,
              error: error.payload.message
            });
          });
        }
      },
      sendPhoneVerification: function sendPhoneVerification() {
        var _this4 = this;

        this.set('phoneVerificationSent', true);
        this.get('ajax').post(_environment.default.APP.API_ROOT + '/user_profiles/send_phone_verification');
        Ember.run.later(function () {
          if (!_this4.get('isDestroyed') && !_this4.get('isDestroying')) {
            _this4.set('phoneVerificationSent', false);
          }
        }, 3000);
      }
    }
  });
});