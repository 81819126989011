define('tunespeak-ember/adapters/user-contest-action', ['exports', 'tunespeak-ember/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var host = this.get('host');
      var contestId = snapshot.adapterOptions.contestId;
      return host + '/user_contest_actions/' + id + '?contest_id=' + contestId;
    }
  });
});