define('tunespeak-ember/models/redemption', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    expiresAt: _emberData.default.attr('date'),
    redeemedAt: _emberData.default.attr('date'),
    declinedAt: _emberData.default.attr('date'),
    user: _emberData.default.attr(),
    prizePackage: _emberData.default.attr(),
    decline: _emberData.default.attr('boolean'),
    redeemed: Ember.computed.notEmpty('redeemedAt'),
    declined: Ember.computed.notEmpty('declinedAt'),
    releasedParties: _emberData.default.attr('string'),
    questions: _emberData.default.hasMany('question', { async: false }),
    affidavit: _emberData.default.belongsTo('affidavit', { async: false }),
    skilledQuestion: _emberData.default.belongsTo('skilled-question', { async: false }),
    groupId: _emberData.default.attr('string'),
    contestId: _emberData.default.attr('string'),
    prizeTitle: _emberData.default.attr('string'),
    instructions: _emberData.default.attr('string'),
    token: _emberData.default.attr('string'),
    proxySession: _emberData.default.attr(),
    contactWindowStartsAt: _emberData.default.attr('date'),
    contactWindowEndsAt: _emberData.default.attr('date'),

    isGrandPrize: Ember.computed.equal('type', 'GrandPrizeRedemption'),
    campaignRoute: Ember.computed('isGrandPrize', function () {
      return this.get('isGrandPrize') ? 'tour' : 'campaign';
    }),
    campaignId: Ember.computed('isGrandPrize', 'groupId', 'contestId', function () {
      return this.get('isGrandPrize') ? this.get('groupId') : this.get('contestId');
    }),

    failed: Ember.computed('skilledQuestion', function () {
      return this.get('skilledQuestion') && this.get('skilledQuestion.responseStatus') === 'Incorrect';
    }),

    expired: Ember.computed('expiresAt', function () {
      return moment(this.get('expiresAt')).isBefore(moment());
    }).volatile(),

    available: Ember.computed('redeemed', 'declined', 'expired', 'failed', function () {
      return !this.get('redeemed') && !this.get('declined') && !this.get('expired') && !this.get('failed');
    }),

    complete: Ember.computed('questions.@each.answer', function () {
      return this.get('questions').every(function (q) {
        return Ember.isPresent(q.get('answer'));
      });
    }),

    submitQuestions: false,
    submitAffidavit: false
  });
});