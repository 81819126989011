define('tunespeak-ember/routes/share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CONTEST_TYPES = ['Contest', 'Raffle', 'LeaderboardContest', 'PresaleContest'];

  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.get('store').findRecord('promotion', params.id);
    },
    redirect: function redirect(model, transition) {
      var route = void 0,
          id = void 0;
      if (model.get('contestId')) {
        route = 'campaign';
        id = model.get('contestId');
      } else if (model.get('shareableType') === 'ContestGroup') {
        route = 'tour';
        id = model.get('shareableId'); //contest-group id
      } else if (CONTEST_TYPES.contains(this.get('type'))) {
        route = 'campaign';
        id = model.get('shareableId');
      }

      transition.abort();
      if (route) {
        this.transitionTo('/' + route + '/' + id + '?p=' + model.get('id'));
      } else {
        this.transitionTo('index');
      }
    },

    actions: {
      error: function error(_error, transition) {
        transition.abort();
        this.transitionTo('index');
      }
    }
  });
});