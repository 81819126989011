define('tunespeak-ember/components/html-snippet', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    cookie: Ember.inject.service(),
    htmlSnippet: Ember.inject.service(),
    model: null,
    initialData: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('model.triggerName')) {
        this.get('htmlSnippet').on(this.get('model.triggerName'), this, this.injectSnippet);
      } else {
        this.injectSnippet();
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get('model.triggerName')) {
        this.get('htmlSnippet').off(this.get('model.triggerName'), this, this.injectSnippet);
      }
      window._ts_snippet = null;
    },
    injectSnippet: function injectSnippet(data) {
      var _this = this;

      window._ts_snippet = Object.assign({}, this.get('initialData'), data);
      if (this.get('model.setsCookie')) {
        this.get('cookie.whenAccepted').then(function () {
          _this.appendToBody(_this.get('model.body'));
        });
      } else {
        this.appendToBody(this.get('model.body'));
      }
    },
    appendToBody: function appendToBody(html) {
      Ember.$('body').append(html);
    }
  });
});