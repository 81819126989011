define('tunespeak-ember/routes/reward', ['exports', 'tunespeak-ember/mixins/ts-route', 'tunespeak-ember/config/environment'], function (exports, _tsRoute, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRoute.default, {
    ajax: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('reward', params.id);
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('applicationLayout', 'whitelabel');
      controller.set('showClaimForm', false);
      controller.clearErrors();
      controller.set('selectedVariant', null);
      if (this.get('session.currentUser') && this.get('session.currentUser.profile.emailVerified')) {
        this.get('ajax').request(_environment.default.APP.API_ROOT + '/rewards/' + model.get('id') + '/eligibility').then(function (res) {
          var claim = res.eligibility.claim;
          if (claim) {
            _this.get('store').pushPayload({ claim: claim });
            claim = _this.get('store').peekRecord('claim', claim.id);
          }
          controller.setProperties({
            status: res.eligibility.status,
            claim: claim
          });
        });
      }
    },
    afterModel: function afterModel() {
      Ember.run.scheduleOnce('afterRender', function () {
        var navbar = Ember.$('.whitelabel-navbar');
        Ember.$(window).bind('touchmove.whitelabel-navbar scroll.whitelabel-navbar', function () {
          Ember.run(function () {
            var scrollPosition = Ember.$(window).scrollTop();
            navbar.toggleClass('scrolled', scrollPosition >= 1);
          });
        });
      });
    },
    deactivate: function deactivate() {
      Ember.$(window).unbind('touchmove.whitelabel-navbar scroll.whitelabel-navbar');
    }
  });
});