define('tunespeak-ember/initializers/fb-pixel', ['exports', '@tunespeak/tunespeak-elements/initializers/fb-pixel'], function (exports, _fbPixel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbPixel.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _fbPixel.initialize;
    }
  });
});