define('tunespeak-ember/templates/login', ['exports', '@tunespeak/tunespeak-elements/templates/login'], function (exports, _login) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _login.default;
    }
  });
});