define('tunespeak-ember/routes/update-phone', ['exports', 'tunespeak-ember/mixins/ts-route-requires-login', 'tunespeak-ember/mixins/ts-route', 'tunespeak-ember/mixins/no-robots', 'tunespeak-ember/mixins/routes/full-screen'], function (exports, _tsRouteRequiresLogin, _tsRoute, _noRobots, _fullScreen) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRouteRequiresLogin.default, _tsRoute.default, _noRobots.default, _fullScreen.default, {
    model: function model() {
      return this.store.findRecord('user-profile', this.get('session.currentUser.id'));
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.setProperties({
        mustVerify: false,
        success: false,
        token: null,
        error: null,
        working: false
      });
    }
  });
});