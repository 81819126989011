define('tunespeak-ember/routes/campaign', ['exports', 'tunespeak-ember/mixins/ts-route', 'tunespeak-ember/mixins/promotion', 'tunespeak-ember/mixins/routes/shareable-meta', 'tunespeak-ember/mixins/routes/ts-route-requires-activation', 'tunespeak-ember/config/environment'], function (exports, _tsRoute, _promotion, _shareableMeta, _tsRouteRequiresActivation, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var appendParamsToUrl = function appendParamsToUrl(url, params) {
    var keys = Object.keys(params);
    if (keys && keys.length) {
      var hasParams = url.includes('?');
      url += hasParams ? '&' : '?';
      url += Ember.$.param(params);
    }
    return url;
  };

  exports.default = Ember.Route.extend(_tsRoute.default, _promotion.default, _shareableMeta.default, _tsRouteRequiresActivation.default, {
    queryParams: {
      modal: { replace: true },
      enroll: { replace: true }
    },
    headTagsService: Ember.inject.service('head-tags'),
    ajax: Ember.inject.service(),
    model: function model(params, transition) {
      var userContest = null;
      if (this.get('session.isAuthenticated')) {
        var reloadUserContest = !transition.queryParams.ucLoaded;
        var contestId = params.id;

        if (!reloadUserContest) {
          // don't reload right after enrolling from tour page, data updated from POST
          userContest = this.store.peekAll('user-contest').findBy('contestId', contestId);
        }
        if (!userContest) {
          userContest = this.store.queryRecord('user-contest', { contest_id: contestId }).catch(function () {
            return null;
          });
        }
      }
      return Ember.RSVP.hash({
        campaign: this.store.findRecord('campaign', params.id),
        userContest: userContest
      });
    },
    afterModel: function afterModel(hash, transition) {
      var _this = this;

      var campaign = hash.campaign;

      // if inVenue campaign redirect
      if (campaign.get('inVenueId')) {
        this.transitionTo('invenue.campaign', campaign.get('inVenueId'), campaign.get('id'));
      }

      if (campaign.get('customUrl')) {
        window.location.replace(appendParamsToUrl(campaign.get('customUrl'), transition.queryParams));
      }

      if (this.get('isEmbedded') && transition.queryParams.enroll) {
        document.cookie = '_ts.thirdparty.test=1; secure=true; max-age=300; samesite=none;';
        var cookieExists = document.cookie.indexOf("_ts.thirdparty.test") > -1;

        if (!cookieExists) {
          transition.abort();
          var nonEmbeddedUrl = appendParamsToUrl(window.location.href, { noembed: true, 'em-show-image': 1 });
          this.get('embeddable').navigateTopTo(nonEmbeddedUrl);
        }
      }

      if (!transition.queryParams.noembed) {
        this.redirectToEmbeddedUrlIfExists(transition, campaign);
      }

      var promotionId = transition.queryParams.p;
      var emPromotionId = transition.queryParams['em-promotion-id'];
      var promotionScope = campaign.get('groupId') || campaign.id;

      // If there is an embedded promotion id and no other promotion id, use default as promo id.
      if (!promotionId && emPromotionId && !this.get('session').getPromotionFor(promotionScope)) {
        promotionId = emPromotionId;
      }

      if (campaign.get('layout')) {
        this.set('applicationLayout', campaign.get('layout'));
      }

      if (promotionId) {
        this.get('store').findRecord('promotion', promotionId).then(function (promotion) {
          if (_this.get('session.currentUser.id') !== promotion.get('promoterId')) {
            _this.get('session').setLastPromotion(promotionId);
            _this.get('session').setPromotionFor(promotionScope, promotionId);
            _this.trackPromotionClick(promotionId);
          }
          _this.setShareableMetaTags(campaign, promotion);
        }).catch(function () {
          _this.setShareableMetaTags(campaign);
        }).finally(function () {
          _this.get('headTagsService').collectHeadTags();
        });
      } else {
        this.setShareableMetaTags(campaign);
      }

      if (campaign.get('useAeService') && campaign.get('isSony')) {
        var elems = document.head.getElementsByClassName('apple-touch-icon');
        Array.from(elems).forEach(function (elem) {
          elem.href = 'https://cdn.smehost.net/formssonymusicfanscom-appirioprod/wp-content/uploads/2019/07/sony_music_logo.png';
        });
      }

      if (campaign.get('layout') === 'whitelabel') {
        Ember.run.scheduleOnce('afterRender', function () {
          var navbar = Ember.$('.whitelabel-navbar');
          Ember.$(window).bind('touchmove.whitelabel-navbar scroll.whitelabel-navbar', function () {
            Ember.run(function () {
              var scrollPosition = Ember.$(window).scrollTop();
              navbar.toggleClass('scrolled', scrollPosition >= 1);
            });
          });
        });
      }

      // For Theming Tool
      window.parent.postMessage("rendered:" + promotionScope, _environment.default.APP.REACT_ROOT);
    },
    setupController: function setupController(controller, hash) {
      var campaign = hash.campaign,
          userContest = hash.userContest;

      this._super(controller, campaign);
      controller.set('userContest', userContest);

      // fetch grandPrizeStatus
      var gppDrawingAt = campaign.get('grandPrizePackage.drawing_at');
      if (userContest && gppDrawingAt && moment(gppDrawingAt).isBefore(moment())) {
        this.fetchGrandPrizeResults(campaign.get('groupId')).then(function (res) {
          controller.set('grandPrizeStatus', res.grand_prize_result.status);
          controller.set('grandPrizeRedemptionId', res.grand_prize_result.redemption_id);
        });
      }

      // check if auth token in params
      controller.checkForAuthToken();
      // enroll in campaign if just logged in or verified
      controller.checkShouldEnroll();
    },
    loadUserContest: function loadUserContest() {
      var _this2 = this;

      this.store.queryRecord('user-contest', { contest_id: this.controller.get('model.id') }).then(function (userContest) {
        _this2.controller.set('userContest', userContest);
      }).catch(function () {
        return;
      });
    },
    fetchGrandPrizeResults: function fetchGrandPrizeResults(groupId) {
      return this.get('ajax').request(_environment.default.APP.API_ROOT + '/contest_groups/' + groupId + '/grand_prize_results');
    },
    activate: function activate() {
      this.get('session').on('userAuthenticated', this, this.loadUserContest);
    },
    deactivate: function deactivate() {
      this.get('session').off('userAuthenticated', this, this.loadUserContest);
      Ember.$(window).unbind('touchmove.whitelabel-navbar scroll.whitelabel-navbar');
    },

    actions: {
      error: function error() {
        this.intermediateTransitionTo('error', '');
      }
    }
  });
});