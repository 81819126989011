define('tunespeak-ember/models/apple-song-add', ['exports', 'ember-data', 'tunespeak-ember/mixins/models/appreciation-engine-action'], function (exports, _emberData, _appreciationEngineAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_appreciationEngineAction.default, {
    extPlaylistId: _emberData.default.attr('string'),
    extPlaylistName: _emberData.default.attr('string'),
    songId: _emberData.default.attr('string'),
    contestId: _emberData.default.attr('string'),
    bandId: _emberData.default.attr('string'),
    promotionId: _emberData.default.attr('string'),
    contestActionId: _emberData.default.attr('string')
  });
});