define('tunespeak-ember/components/redemption-question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    question: null,
    index: null,

    number: Ember.computed('index', function () {
      return this.get('index') + 1;
    }),

    classNames: ['redemption-question'],
    classNameBindings: ['isActive:active'],

    formComponent: null,
    errors: Ember.computed.alias('formComponent.errors'),
    isValid: Ember.computed.alias('formComponent.isValid'),
    isInvalid: Ember.computed.not('isValid'),

    isActive: Ember.computed('activeIndex', function () {
      return this.get('index') === this.get('activeIndex');
    }),
    isInFocus: false,
    isFirstTime: true,

    showErrorState: Ember.computed('isInvalid', 'isFirstTime', 'isInFocus', function () {
      return this.get('isInvalid') && !this.get('isFirstTime') && !this.get('isInFocus');
    }),

    showErrorMessages: Ember.computed('errors.length', 'isFirstTime', function () {
      return this.get('errors.length') && !this.get('isFirstTime');
    }),

    onAnswerChange: Ember.observer('question.answer', function () {
      this.get('onChange')(this.get('question'));
    }),

    activeIndexDidChange: Ember.observer('isActive', function () {
      if (this.get('isActive')) {
        this.send('focus');
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('register')(this);
      this.activeIndexDidChange();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('deregister')(this);
    },


    actions: {
      inputFocus: function inputFocus() {
        this.get('question').send('becomeDirty');
        this.get('focus')(this.get('index'));
        this.set('isInFocus', true);
      },
      focus: function focus() {
        if (!this.get('isInFocus')) {
          this.get('formComponent').delegateFocus();
        }
        this.get('question').send('becomeDirty');
        this.get('focus')(this.get('index'));
        this.set('isInFocus', true);
      },
      didBlur: function didBlur() {
        this.set('isFirstTime', false);
        this.set('isInFocus', false);
      }
    }
  });
});