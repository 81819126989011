define('tunespeak-ember/components/ziggeo-player', ['exports', '@tunespeak/tunespeak-elements/components/ziggeo-player'], function (exports, _ziggeoPlayer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ziggeoPlayer.default;
    }
  });
});