define('tunespeak-ember/mixins/components/campaign-ad', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    ad: null,
    tagName: 'a',
    attributeBindings: ['href', 'target'],
    target: '_blank',
    href: Ember.computed.alias('ad.url'),
    classNames: ['campaign-fad', 'link-fad'],

    click: function click() {
      this.send('trackClick');
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.get('googleTracking')('ad_impression', {
        category: 'ad',
        value: this.get('ad.typeClass') + ' - ' + this.get('contest.bandNames') + ' - ' + this.get('contest.title')
      });
    },


    actions: {
      trackClick: function trackClick() {
        this.get("googleTracking")("ad_impression", {
          category: "ad",
          value: this.get("ad.typeClass") + ' - ' + this.get("contest.bandNames") + ' - ' + this.get("contest.title")
        });
      }
    }
  });
});