define('tunespeak-ember/mixins/components/video-action', ['exports', 'tunespeak-ember/mixins/components/campaign-action', 'ember-data'], function (exports, _campaignAction, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var mouseTimeout = null;
  var poller = null;

  exports.default = Ember.Mixin.create(_campaignAction.default, {
    classNames: ['ts-list-item', 'campaign-action-item'],
    mediaManager: Ember.inject.service(),
    fullscreenService: Ember.inject.service('fullscreen'),
    store: Ember.inject.service(),
    player: null,
    view: null,
    creatingPlayer: false,
    playerId: Ember.computed('elementId', function () {
      return 'player-' + this.elementId;
    }),
    containerClass: 'player-container', //set in child class for fullscreen
    isMobileApple: Ember.computed(function () {
      return isMobile.apple.device;
    }),
    isFullscreen: false,
    pollingInterval: 30000,
    controlsHideDelay: 2000, //time to wait after mousemove before hiding video controls
    isIdle: Ember.computed('player.{isIdle,isFinished}', 'player', function () {
      return !this.get('player') || this.get('player.isIdle') || this.get('player.isFinished');
    }),
    notIdle: Ember.computed.not('isIdle'),
    isBuffering: Ember.computed.alias('player.isBuffering'),
    isPlaying: Ember.computed.alias('player.isPlaying'),
    _playingObserver: Ember.observer('isPlaying', function () {
      if (this.get('isPlaying')) {
        this.playingPoller();
      } else {
        Ember.run.cancel(poller);
      }
    }),
    isFinished: Ember.computed.alias('player.isFinished'),
    _playerFinished: Ember.observer('isFinished', function () {
      var _this = this;

      if (this.get('isFinished')) {
        this.updateView().then(function () {
          _this.playerReset();
        });
      }
    }),
    progressStyle: Ember.computed('player.progress', function () {
      return Ember.String.htmlSafe('width: ' + Math.round(this.get('player.progress')) + '%');
    }),
    mouseMove: function mouseMove() {
      if (!isMobile.any) {
        this.touchStart();
      }
    },
    touchStart: function touchStart() {
      var controls = this.$('.player-controls');
      var delay = this.get('controlsHideDelay');
      controls.addClass('active');
      if (mouseTimeout) {
        Ember.run.cancel(mouseTimeout);
      }
      mouseTimeout = Ember.run.later(function () {
        controls.removeClass('active');
      }, delay);
    },
    didEnterFullscreen: function didEnterFullscreen() {
      this.set('isFullscreen', true);
    },
    didExitFullscreen: function didExitFullscreen() {
      this.set('isFullscreen', false);
    },
    setupPlayer: function setupPlayer() {
      /**
       * Implement in instance
       * @return Promise
       */
    },
    createView: function createView() {
      var _this2 = this;

      var view = this.get('store').createRecord('view', {
        duration: this.get('player.totalPlayed'),
        videoId: this.get('model.content.id'),
        promotionId: this.get('promotionId'),
        contestId: this.get('contest.id'),
        contestActionId: this.get('model.id')
      });
      this.set('view', view);
      return view.save().catch(function (error) {
        _this2.send('pause');
        if (error instanceof _emberData.default.UnauthorizedError) {
          _this2.get('session').open().then(function () {
            _this2.playerReset();
          });
        }
      });
    },
    updateView: function updateView() {
      var _this3 = this;

      this.set('view.duration', this.get('player.totalPlayed'));
      return this.get('view').save().catch(function () {
        _this3.send('togglePlay');
        _this3.get('session').open().then(function () {
          _this3.updateView();
        });
      });
    },
    playingPoller: function playingPoller() {
      var _this4 = this;

      var interval = this.get('pollingInterval');
      if (this.get('view')) {
        this.updateView();
      } else {
        this.createView();
      }
      poller = Ember.run.later(function () {
        _this4.playingPoller();
      }, interval);
    },
    playerReset: function playerReset() {
      this.get('store').unloadRecord(this.get('view'));
      this.set('view', null);
      if (isMobile.any) {
        this.get('player').resetPlayer();
      } else {
        this.get('mediaManager').destroy(this.get('player'));
        this.set('player', null);
      }
    },
    willDestroy: function willDestroy() {
      if (poller) {
        Ember.run.cancel(poller);
      }
      if (mouseTimeout) {
        Ember.run.cancel(mouseTimeout);
      }
      this.set('view', null);
      this.get('mediaManager').destroy(this.get('player'));
    },

    actions: {
      togglePlay: function togglePlay() {
        var _this5 = this;

        if (!this.get('player')) {
          if (!this.get('creatingPlayer')) {
            this.set('creatingPlayer', true);
            this.setupPlayer().then(function () {
              _this5.set('creatingPlayer', false);
              _this5.send('play');
            });
          }
        } else {
          if (this.get('isPlaying')) {
            this.send('pause');
          } else {
            this.send('play');
          }
        }
      },
      play: function play() {
        this.get('mediaManager').play(this.get('player'));
      },
      pause: function pause() {
        this.get('mediaManager').pause(this.get('player'));
      },
      toggleFullscreen: function toggleFullscreen() {
        if (this.get('isFullscreen')) {
          this.get('fullscreenService').exitFullscreen(this);
        } else {
          this.get('fullscreenService').requestFullscreen(Ember.$('#' + this.elementId + ' .' + this.get('containerClass'))[0], this);
        }
      },
      didReceiveCredit: function didReceiveCredit(credit) {
        if (credit.get('points')) {
          this._super(credit);
        }
      },
      didEarnCredit: function didEarnCredit(credit) {
        var _this6 = this;

        this.get('store').findRecord('user-contest-action', this.get('userAction.id'), {
          reload: true,
          adapterOptions: { contestId: this.get('contest.id') }
        }).then(function (record) {
          _this6.set('userAction', record);
        }).catch(function (error) {
          if (error instanceof _emberData.default.UnauthorizedError) {
            _this6.get('session').open().then(function () {
              _this6.send('didEarnCredit', credit);
            });
          }
        });
      }
    }
  });
});