define('tunespeak-ember/components/points-odometer', ['exports', 'odometer'], function (exports, _odometer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    points: 0,
    duration: 100,
    format: '(,ddd)',
    odometer: null,
    lastPoints: 0,
    ticker: null,
    chunkSize: function chunkSize(diff) {
      var chunk = 1;
      if (diff > 100 && diff <= 200) {
        chunk = 2;
      } else if (diff > 200 && diff <= 500) {
        chunk = 4;
      } else if (diff > 500) {
        chunk = 5;
      }
      return chunk;
    },
    pointsObserver: Ember.observer('points', function () {
      var _this = this;

      //cancel any active counters
      Ember.run.cancel(this.get('ticker'));

      var updater = function updater(i, target, chunk) {
        if (i < target && i + chunk <= target) {
          //less than target && adding chunk won't go over target
          _this.set('ticker', Ember.run.later(_this, function () {
            this.get('odometer').update(i + chunk);
            updater(i + chunk, target, chunk);
          }, 100));
          _this.set('lastPoints', i);
        } else {
          //i is target or within chunk distance
          _this.get('odometer').update(target);
          _this.set('lastPoints', target);
        }
      };

      updater(this.get('lastPoints'), this.get('points'), this.chunkSize(this.get('points') - this.get('lastPoints')));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      //create points odometer
      var od = new _odometer.default({
        el: Ember.$('#' + this.elementId)[0],
        value: this.get('points'),
        duration: this.get('duration'),
        format: this.get('format')
      });

      this.setProperties({
        odometer: od,
        lastPoints: this.get('points')
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.get('ticker'));
    }
  });
});