define('tunespeak-ember/components/campaign/deluxe-with-presale-template', ['exports', 'tunespeak-ember/mixins/components/campaign-template'], function (exports, _campaignTemplate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignTemplate.default, {
    presaleAction: Ember.computed('model.contestActions', function () {
      return this.get('model.contestActions').findBy('template', 'presale-registration');
    }),
    presaleSortBy: Ember.A(['show_starts_at']),
    presaleOptions: Ember.computed.sort('presaleAction.content.presale_options', 'presaleSortBy'),
    multiplePresaleOptions: Ember.computed.gt('presaleOptions.length', 1),
    groupedContestActionsExceptPresale: Ember.computed('groupedContestActions.@each.template', function () {
      return this.get('groupedContestActions').rejectBy('template', 'presale-registration');
    }),

    actions: {
      enroll: function enroll(presaleId) {
        presaleId = presaleId || this.get('presaleOptions.firstObject.id');
        this.get('enroll')({
          presaleId: presaleId
        });
      },
      setPresaleState: function setPresaleState(state) {
        var userAction = this.get('userContest.actions').findBy('id', this.get('presaleAction.id'));
        switch (state) {
          case 'CODE':
            userAction.set('presaleCode', 'ABCDEFG');
            break;
          case 'NOCODE':
            userAction.set('presaleCode', null);
            break;
          default:
            break;
        }
      }
    }
  });
});