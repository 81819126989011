define('tunespeak-ember/models/user-video', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    contestActionId: _emberData.default.attr('string'),
    promotionId: _emberData.default.attr('string'),
    ziggeoId: _emberData.default.attr('string'),
    shortUrl: _emberData.default.attr('string'),
    emailBody: _emberData.default.attr('string'),
    smsBody: _emberData.default.attr('string'),
    tweetText: _emberData.default.attr('string'),
    votesCount: _emberData.default.attr('number'),
    ogTitle: _emberData.default.attr('string'),
    ogDescription: _emberData.default.attr('string'),
    contestPreview: _emberData.default.belongsTo('contest-preview', { async: false }),
    shareableFacebookPhoto: _emberData.default.attr('string'),
    backgroundPhoto: _emberData.default.attr('string'),
    headerPhoto: _emberData.default.attr('string'),
    headerBgPhoto: _emberData.default.attr('string'),
    themeCss: _emberData.default.attr('string'),
    gifUrl: _emberData.default.attr('string'),
    maxLength: _emberData.default.attr('number'),
    contestId: _emberData.default.attr('string'),
    shareableSocialPhoto: Ember.computed.alias('shareableFacebookPhoto')
  });
});