define('tunespeak-ember/components/contest-actions/video-grid', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['video-grid', 'campaign-action-item', 'ts-list-item'],
    contestActions: Ember.computed.alias('model.contestActions')
  });
});