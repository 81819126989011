define('tunespeak-ember/mixins/components/campaign-template', ['exports', 'tunespeak-ember/utils/enum', 'tunespeak-ember/mixins/components/translate'], function (exports, _enum, _translate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UserContestState = new _enum.default({
    WON: 0,
    LOST: 1,
    INPROGRESS: 2,
    ENDED: 3, //if not enrolled & ended
    WINNERPENDING: 4,
    CANCELED: 5,
    DISQUALIFIED: 6,
    NOTSTARTED: 7
  });

  function ContestActionGroup(label, template, id) {
    this.label = label;
    this.id = id;
    this.template = template;
    this.contestActions = [];
  }
  ContestActionGroup.prototype.push = function (contestAction) {
    this.contestActions.push(contestAction);
  };

  exports.default = Ember.Mixin.create(_translate.default, {
    model: null,
    userContest: null,
    enrolled: null,
    isEnrolling: null,
    confetti: new Array(30),
    showDemoToolbar: false,

    shareLink: Ember.computed.alias('userContest.shortUrl'),

    session: Ember.inject.service(),
    store: Ember.inject.service(),
    appreciationEngine: Ember.inject.service(),
    scrollTo: Ember.inject.service('ts-scroll-to'),

    creditsName: Ember.computed.alias('model.creditsName'),
    creditName: Ember.computed.alias('model.creditName'),
    promotionId: Ember.computed('model.{groupId,id}', function () {
      return this.get('session').getPromotionFor(this.get('model.groupId') || this.get('model.id'));
    }),

    hasEnrollmentLock: Ember.computed.alias('model.enrollmentLock'),

    referrals: Ember.computed.filter('model.contestActions', function (contestAction) {
      var template = contestAction.get('template');
      return template === 'presale-referral' || template === 'registration-referral';
    }),

    isPresale: Ember.computed.equal('model.type', 'PresaleContest'),
    isRaffle: Ember.computed.equal('model.type', 'Raffle'),
    isEmbedded: Ember.computed.equal('model.layout', 'embed'),

    allowedCountries: Ember.computed.alias('model.allowedCountries'),

    startsAtSet: Ember.computed('model.startsAt', function () {
      return moment(this.get('model.startsAt')).isValid();
    }),

    userContestState: Ember.computed('model.{type,canceled,ended,numAvailable,finalized}', 'userContest.{disqualified,place}', 'enrolled', function () {
      if (this.get('model.canceled')) {
        return UserContestState.CANCELED;
      }

      if (this.get('enrolled')) {
        if (this.get('userContest.disqualified')) {
          return UserContestState.DISQUALIFIED;
        }
        if (this.get('model.ended')) {
          //contest has ended
          if (this.get('isRaffle') || this.get('model.type') === 'VideoSubmissionContest') {
            if (!this.get('model.finalized')) {
              //contest has not been finalized
              return UserContestState.WINNERPENDING;
            } else if (this.get('userContest.place')) {
              return UserContestState.WON;
            }
          } else {
            if (this.get('userContest.winner')) {
              return UserContestState.WON;
            }
          }
          return UserContestState.LOST;
        } else if (this.get('model.started')) {
          // contest hasn't ended
          return UserContestState.INPROGRESS;
        } else {
          // contest hasn't started
          return UserContestState.NOTSTARTED;
        }
      } else {
        //user is not enrolled
        if (this.get('model.ended')) {
          return UserContestState.ENDED;
        } else if (this.get('model.started')) {
          // contest hasn't ended
          return UserContestState.INPROGRESS;
        } else {
          // contest hasn't started
          return UserContestState.NOTSTARTED;
        }
      }
    }),

    won: Ember.computed.equal('userContestState', UserContestState.WON),
    lost: Ember.computed.equal('userContestState', UserContestState.LOST),
    canceled: Ember.computed.equal('userContestState', UserContestState.CANCELED),
    ended: Ember.computed.equal('userContestState', UserContestState.ENDED),
    winnersPending: Ember.computed.equal('userContestState', UserContestState.WINNERPENDING),
    inProgress: Ember.computed.equal('userContestState', UserContestState.INPROGRESS),
    disqualified: Ember.computed.equal('userContestState', UserContestState.DISQUALIFIED),

    userContestStateStr: Ember.computed('userContestState', function () {
      return UserContestState.key(this.get('userContestState')).toLowerCase();
    }),

    grandPrizeWon: Ember.computed.equal('grandPrizeStatus', 'Won'),
    grandPrizeLost: Ember.computed.equal('grandPrizeStatus', 'Lost'),
    grandPrizeDrawingStarted: Ember.computed('model.grandPrizePackage', function () {
      if (!this.get('model.grandPrizePackage')) {
        return false;
      }
      return moment(this.get('model.grandPrizePackage.drawing_at')).isBefore(moment());
    }),

    isUnlocked: Ember.computed('model.enrollmentLock', 'userContest.enrollmentLock.state', function () {
      if (!this.get('model.enrollmentLock')) return true;
      return this.get('userContest.enrollmentLock.state') === 'unlocked';
    }),

    sortBy: ['position'],
    contestActions: Ember.computed.sort('model.contestActions', 'sortBy'),
    hasContestActions: Ember.computed.gt('contestActions.length', 0),
    visibleContestActions: Ember.computed('isUnlocked', 'model.enrollmentLock.requirementCaIds', 'contestActions', function () {
      var _this = this;

      if (this.get('isUnlocked')) {
        return this.get('model.contestActions');
      }
      return this.get('contestActions').filter(function (ca) {
        return _this.get('model.enrollmentLock.requirementCaIds').includes(ca.get('id'));
      });
    }),

    groupedContestActions: Ember.computed('visibleContestActions', function () {
      var map = {};
      return this.get('visibleContestActions').reduce(function (grouped, contestAction) {
        var template = contestAction.get('groupTemplate');

        if (template) {
          var groupId = contestAction.get('groupId'),
              key = contestAction.get('groupLabel');

          if (!map[key]) {
            map[key] = new ContestActionGroup(key, template, groupId);
            grouped.push(map[key]);
          }
          map[key].push(contestAction);
        } else {
          grouped.push(contestAction);
        }
        return grouped;
      }, []);
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var aeWidgetId = this.get('model.recordLabelAeWidgetId'),
          aeSegmentId = this.get('model.aeSegmentId');

      if (aeWidgetId) {
        this.get('appreciationEngine').load(aeWidgetId, aeSegmentId);
      }
    },


    actions: {
      goDown: function goDown() {
        this.get('scrollTo').scrollVertical(Ember.$('.actions-container'), { offset: -110, duration: 500 });
      },
      login: function login() {
        this.sendAction('login');
      },
      enroll: function enroll() {
        this.get('enroll')();
      },
      logout: function logout() {
        this.sendAction('logout');
      },
      toggle: function toggle() {
        this.sendAction.apply(this, ['toggle'].concat(Array.prototype.slice.call(arguments)));
      },
      toggleDemoToolbar: function toggleDemoToolbar() {
        this.toggleProperty('showDemoToolbar');
      },
      setDemoState: function setDemoState(state) {
        switch (state) {
          case 'WON':
            this.set('userContestState', UserContestState[state]);
            this.set('userContest.place', 1);
            break;
          case 'GRANDPRIZEPENDING':
            this.set('grandPrizeDrawingStarted', true);
            this.set('grandPrizeStatus', null);
            break;
          case 'GRANDPRIZEWON':
            this.set('grandPrizeDrawingStarted', true);
            this.set('grandPrizeStatus', 'Won');
            break;
          case 'GRANDPRIZELOST':
            this.set('grandPrizeDrawingStarted', true);
            this.set('grandPrizeStatus', 'Lost');
            break;
          default:
            this.set('userContestState', UserContestState[state]);
        }
      },
      reloadUserContest: function reloadUserContest(uc) {
        this.sendAction('reloadUserContest', uc);
      }
    }
  });
});