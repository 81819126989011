define('tunespeak-ember/components/points-navbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['points-navbar'],
    totalPoints: 0,
    points: 0,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.$(window).bind('touchmove.' + this.elementId + ' scroll.' + this.elementId, function () {
        Ember.run(function () {
          var scrollPosition = Ember.$(window).scrollTop();
          _this.$().toggleClass('scrolled', scrollPosition >= 1);
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).unbind('touchmove.' + this.elementId + ' scroll.' + this.elementId);
    }
  });
});