define('tunespeak-ember/components/rules-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    ajax: Ember.inject.service(),
    contestRules: '',
    legacyContestRules: false,
    rulesModalOpen: false,

    actions: {
      fetchRules: function fetchRules() {
        var _this = this;

        this.set('rulesModalOpen', true);
        this.get('ajax').request(this.get('model.rulesPageUrl'), {
          dataType: 'text',
          xhrFields: {
            withCredentials: false
          }
        }).then(function (response) {
          _this.set('contestRules', {
            body: response
          });
        }).catch(function () {
          _this.set('legacyContestRules', true);
        });
      },
      closeRulesModal: function closeRulesModal() {
        this.set('rulesModalOpen', false);
      }
    }
  });
});