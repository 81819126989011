define('tunespeak-ember/components/invenue-preview-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userContests: null,
    userContest: Ember.computed('model.id', 'userContests', function () {
      if (this.get('userContests')) {
        return this.get('userContests').findBy('contestId', this.get('model.id'));
      }
    })
  });
});