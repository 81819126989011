define('tunespeak-ember/components/contest-actions/spotify-unlock', ['exports', 'tunespeak-ember/utils/cookie-intent'], function (exports, _cookieIntent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UnlockState = {
    DEFAULT: 0,
    UNLOCKED: 1,
    LOADING: 2,
    OVERRIDE: 3,
    ERROR: 4,
    LOGIN: 5,
    SPOTIFYCONNECT: 6
  };

  exports.default = Ember.Component.extend({
    spotifyBrokeAF: false,
    classNames: ['spotify-unlock'],
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    scrollTo: Ember.inject.service('ts-scroll-to'),
    contestActions: Ember.computed.alias('model.contestActions'),
    contest: null,
    userContest: null,
    unlockState: Ember.computed('userContest', function () {
      return this.get('userContest.extTrackingRef') ? UnlockState.UNLOCKED : UnlockState.DEFAULT;
    }),
    syncInProgress: Ember.computed.or('isLoading', 'isOverriding', 'isError'),
    isUnlocked: Ember.computed.equal('unlockState', UnlockState.UNLOCKED),
    isLoading: Ember.computed.equal('unlockState', UnlockState.LOADING),
    isOverriding: Ember.computed.equal('unlockState', UnlockState.OVERRIDE),
    isError: Ember.computed.equal('unlockState', UnlockState.ERROR),
    isLogin: Ember.computed.equal('unlockState', UnlockState.LOGIN),
    isSpotifyConnect: Ember.computed.equal('unlockState', UnlockState.SPOTIFYCONNECT),
    contestActionsLength: Ember.computed('contestActions', function () {
      return this.get('contestActions').reduce(function (acc, i) {
        return i.get('content.songs_count') ? acc + i.get('content.songs_count') : acc + 1;
      }, 0);
    }),
    multipleSpotifyActions: Ember.computed.gt('contestActionsLength', 1),
    spotifyActionsDailyPointLimit: Ember.computed('contestActions', function () {
      return this.get('contestActions').reduce(function (acc, i) {
        if (i.get('preRelease')) {
          return acc;
        }
        return acc + i.get('totalDirectLimit') * i.get('pointValue');
      }, 0);
    }),
    firstAction: Ember.computed.alias('contestActions.firstObject'),
    helpModalOpen: false,
    userHasSpotify: Ember.computed.bool('session.currentUser.spotify'),
    isUserFacebookTokenInvalid: Ember.computed.not('session.currentUser.facebook.token_valid'),
    isUserSpotifyNeedsReauth: Ember.computed.alias('session.currentUser.spotify.reauthorize'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (0, _cookieIntent.handleExtTrackingRefCookie)(this.get('session').getAuthCookie(), this);
    },


    actions: {
      toggleHelpModal: function toggleHelpModal() {
        this.toggleProperty('helpModalOpen');
      },
      setTrackingTutorialState: function setTrackingTutorialState(state) {
        var track = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        Ember.run.scheduleOnce('afterRender', this, function () {
          this.set('unlockState', UnlockState[state.toUpperCase()]);
          this.get('scrollTo').scrollVertical(Ember.$('.spotify-unlock'), { offset: -125, duration: 500 });
        });

        if (track) {
          this.get("googleTracking")("unlock-spotify-player", {
            category: "campaign-action"
          });
        }
      },
      createExtTrackingRef: function createExtTrackingRef() {
        var _this = this;

        var confirm = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        var options = {
          contestId: this.get('contest.id'),
          promotionId: this.get('promotionId')
        };

        if (confirm) {
          options.confirm = true;
        }

        this.get('store').createRecord('ext-tracking-ref', options).save().then(function (extTrackingRef) {
          _this.set('userContest.extTrackingRef', extTrackingRef);
          _this.send('setTrackingTutorialState', 'unlocked');
        }).catch(function (error) {
          var errorMsg = void 0;
          if (error && error.errors && error.errors[0]) {
            errorMsg = error.errors[0].detail;
          }
          switch (errorMsg) {
            case "Please confirm that you'd like to start spotify tracking":
              _this.send('setTrackingTutorialState', 'override');
              break;
            case "There was an issue with authentication. Please try again.":
              _this.send('setTrackingTutorialState', 'login');
              break;
            case "Please connect to Spotify.":
              _this.send('setTrackingTutorialState', 'spotifyconnect');
              break;
            default:
              _this.send('setTrackingTutorialState', 'error');
          }
        });
      },
      loginToCreateTrackingRef: function loginToCreateTrackingRef() {
        var _this2 = this;

        this.send('setTrackingTutorialState', 'loading', false);
        this.get('session').open().then(function () {
          _this2.send('confirmSpotifyInstructions', true);
        }).catch(function () {
          _this2.send('setTrackingTutorialState', 'login');
        });
      },
      spotifyConnectToCreateTrackingRef: function spotifyConnectToCreateTrackingRef() {
        var _this3 = this;

        if (this.get('session.isEmbeddedWebView')) {
          this.get('session').createAuthCookie({
            intent: 'etr', // ExtTrackingRef
            contest_id: this.get('contest.id'),
            service: 'spotify'
          });
        }

        this.send('setTrackingTutorialState', 'loading', false);
        this.get('session').requireService('spotify', this.get('isUserSpotifyNeedsReauth')).then(function () {
          _this3.send('confirmSpotifyInstructions');
          _this3.get('session').removeAuthCookie();
        }).catch(function () {
          _this3.send('setTrackingTutorialState', 'default');
        });
      },
      forceSpotifyAuth: function forceSpotifyAuth() {
        var _this4 = this;

        this.get('session').requireService('spotify', true).then(function () {
          _this4.send('confirmSpotifyInstructions', true);
        }).catch(function () {});
      },

      confirmSpotifyInstructions: function confirmSpotifyInstructions() {
        var confirm = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        this.send('setTrackingTutorialState', 'loading', false);

        //if exists && not overridden then don't create another one
        if (this.get('userContest.extTrackingRef') && !this.get('userContest.extTrackingRef.overridden')) {
          return this.send('setTrackingTutorialState', 'unlocked');
        }

        this.send('createExtTrackingRef', confirm);
      }
    }
  });
});