define('tunespeak-ember/mixins/no-robots', ['exports', 'ember-cli-meta-tags/mixins/route-meta'], function (exports, _routeMeta) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var noRobots = {
    name: {
      robots: 'noindex, nofollow'
    }
  };

  exports.default = Ember.Mixin.create(_routeMeta.default, {
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      this.set('meta', Object.assign({}, noRobots, this.get('meta')));
    }
  });
});