define('tunespeak-ember/adapters/user-profile', ['exports', 'tunespeak-ember/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForResendEmailVerification: function urlForResendEmailVerification(id) {
      return this.buildURL('user-profile', id) + '/resend_email_verification';
    }
  });
});