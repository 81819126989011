define('tunespeak-ember/initializers/ember-cli-uuid', ['exports', 'ember-data', 'ember-cli-uuid', 'tunespeak-ember/config/environment', 'ember-cli-uuid/configuration'], function (exports, _emberData, _emberCliUuid, _environment, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    name: 'ember-cli-uuid',

    initialize: function initialize() {

      var config = _environment.default['ember-cli-uuid'] || {};
      _configuration.default.load(config);

      _emberData.default.Adapter.reopen({
        generateIdForRecord: function generateIdForRecord() {
          return _configuration.default.defaultUUID ? (0, _emberCliUuid.uuid)() : null;
        }
      });
    }
  };
});