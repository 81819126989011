define('tunespeak-ember/components/campaign-ad-wormhole', ['exports', 'ember-wormhole/components/ember-wormhole'], function (exports, _emberWormhole) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberWormhole.default.extend({
    position: null,
    maxPosition: null,
    destinationElementId: Ember.computed('position', 'maxPosition', function () {
      return 'ad-placeholder-' + Math.min(this.get('maxPosition'), this.get('position'));
    })
  });
});