define('tunespeak-ember/routes/redemption/review', ['exports', 'tunespeak-ember/mixins/ts-route'], function (exports, _tsRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRoute.default, {
    alwaysResetScroll: true,
    afterModel: function afterModel(model) {
      if (!model.get('complete')) {
        this.transitionTo('redemption.index');
      }
    }
  });
});