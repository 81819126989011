define('tunespeak-ember/components/ts-flipclock', ['exports', 'flipclock'], function (exports, _flipclock) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // needed for ember-auto-import

  var clock = null;
  var then = null;

  exports.default = Ember.Component.extend({
    endsAt: null,
    interval: null,
    iszero: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super();
      Ember.run.next(function () {
        then = moment(_this.get('endsAt'));
        var now = moment();
        var time = Math.max(then.diff(now, 'seconds'), 0);
        clock = _this.$().FlipClock(time, {
          countdown: true,
          callbacks: {
            stop: function stop() {
              // stop triggers too soon
              Ember.run.later(function () {
                _this.send('countdownComplete');
              }, 1000);
            }
          }
        });
        if (time > 0) {
          _this.resetClock();
        } else {
          _this.set('isZero', true);
        }
      });
      if (this.get('countdownInitialized')) {
        this.get('countdownInitialized')();
      }
    },
    resetClock: function resetClock() {
      var _this2 = this;

      this.set('interval', Ember.run.later(function () {
        var now = moment();
        var diff = then.diff(now, 'seconds');
        clock.setTime(Math.max(diff, 0));
        _this2.resetClock();
      }, 30000));
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.get('interval'));
    },

    actions: {
      countdownComplete: function countdownComplete() {
        Ember.run.cancel(this.get('interval'));
        this.get('countdownComplete')();
      }
    }
  });
});