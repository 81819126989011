define('tunespeak-ember/components/cookie-notification', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['cookie-notification'],
    isVisible: false,
    cookie: Ember.inject.service(),

    actions: {
      acknowledgeCookies: function acknowledgeCookies(consent) {
        consent ? this.get('cookie').allow() : this.get('cookie').deny();
        this.set('isVisible', false);
      }
    }
  });
});