define('tunespeak-ember/mixins/components/spotify-action', ['exports', 'tunespeak-ember/mixins/components/campaign-action', 'ember-data'], function (exports, _campaignAction, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_campaignAction.default, {
    spotifyId: Ember.computed.alias('model.content.spotify_id'),
    isOpenSpotifyLoginModal: false,
    handleSaveError: function handleSaveError(error, options) {
      var _this = this;

      if (error instanceof _emberData.default.UnauthorizedError) {
        // Spotify not authorized
        if (error.code === 7001) {
          this.set('isOpenSpotifyLoginModal', true);
        } else {
          if (this.get('useAeService')) {
            this.get('appreciationEngine').requireService('spotify', this.get('contest.id'), true).then(function () {
              _this.send('saveRecord', options);
            });
          } else {
            this.get('session').open().then(function () {
              _this.send('saveRecord', options);
            });
          }
        }
      }
    },

    actions: {
      closeSpotifyLoginModal: function closeSpotifyLoginModal() {
        this.set('isOpenSpotifyLoginModal', false);
      }
    }
  });
});