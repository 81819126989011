define('tunespeak-ember/controllers/conversation', ['exports', 'tunespeak-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    hideFooter: true,
    messagesSortBy: Ember.A(['createdAt']),
    sortedMessages: Ember.computed.sort('model.messages', 'messagesSortBy'),
    newMessage: null,
    loadingMessages: false,

    canSendMessage: Ember.computed('isSending', 'newMessage', function () {
      return !this.get('isSending') && Ember.isPresent(this.get('newMessage'));
    }),
    isSending: false,

    sendLabel: Ember.computed('isSending', function () {
      return this.get('isSending') ? 'Sending...' : 'Send';
    }),

    lastMessageFromBand: Ember.computed('sortedMessages.lastObject', function () {
      return this.get('sortedMessages').filterBy('senderType', 'Band').get('lastObject');
    }),

    isLockingConvo: false,
    isUnlockingConvo: false,

    bandId: Ember.computed('model', function () {
      return this.get('model.participants').filterBy('participant_type', 'Band').get('firstObject.participant_id');
    }),

    actions: {
      reloadMessages: function reloadMessages() {
        var _this = this;

        this.set('loadingMessages', true);
        this.get('model').fetchMessages().finally(function () {
          _this.set('loadingMessages', false);
        });
      },
      toggleLock: function toggleLock() {
        this.toggleProperty('isLockingConvo');
      },
      toggleUnlock: function toggleUnlock() {
        this.toggleProperty('isUnlockingConvo');
      },
      toggleMessaging: function toggleMessaging(bool) {
        var _this2 = this;

        var url = _environment.default.APP.API_ROOT + '/user_bands/' + this.get('bandId');
        this.get('ajax').put(url, {
          data: {
            user_band: {
              messaging_enabled: bool
            }
          }
        }).then(function () {
          _this2.get('model').set('locked', !bool);
        });
      },
      confirmLock: function confirmLock() {
        this.send('toggleLock');
        this.send('toggleMessaging', false);
      },
      confirmUnlock: function confirmUnlock() {
        this.send('toggleUnlock');
        this.send('toggleMessaging', true);
      },
      markAsRead: function markAsRead() {
        this.get('model').markAsRead();
      },
      sendMessage: function sendMessage() {
        var _this3 = this;

        if (this.get('canSendMessage')) {
          this.setProperties({
            isSending: true,
            errorMessage: null
          });

          var conversation = this.model;
          var newMessage = this.store.createRecord('message', {
            body: this.get('newMessage'),
            conversation: conversation,
            band: conversation.get('band')
          });

          newMessage.save().then(function () {
            _this3.set('newMessage', null);
          }).catch(function (error) {
            if (error.code === 6002) {
              _this3.set('errorMessage', "Your message had invalid HTML. Don't worry, we stripped it out! Try again now:");
              _this3.set('newMessage', error.sanitized_body);
            } else {
              _this3.set('errorMessage', error.description);
            }
          }).finally(function () {
            _this3.set('isSending', false);
          });
        }
      }
    }
  });
});