// //
// export default Controller.extend({
//   isEnrolled: false,
//   hasDescription: notEmpty('description'),
//   queryParams: ['tab'],
//   tab: 'info',
//
//   showLeaderboard: equal('tab', 'leaderboard'),
//   showInfo: equal('tab', 'info'),
//
//   leaderboard: [{
//     rank: 1,
//     id: 601095419,
//     name: "Mateo A.",
//     winner: true
//   }, {
//     rank: 2,
//     id: 100001457797732,
//     name: "Giselle A.",
//     winner: false
//   }, {
//     rank: 3,
//     id: 699804224,
//     name: "John B.",
//     winner: false
//   }, {
//     rank: 4,
//     id: 100002558531565,
//     name: "Bob T.",
//     winner: false
//   }, {
//     rank: 5,
//     id: 1062218009,
//     name: "Brian A.",
//     winner: false
//   }, {
//     rank: 6,
//     id: 704449321,
//     name: "Amanda D.",
//     winner: false
//   }, {
//     rank: 7,
//     id: 690244485,
//     name: "Hilary W.",
//     winner: false
//   }, {
//     rank: 8,
//     id: 100001043602748,
//     name: "Brandon K.",
//     winner: false
//   }, {
//     rank: 9,
//     id: 179202433,
//     name: "Loren G.",
//     winner: false
//   }, {
//     rank: 10,
//     id: 100000328373571,
//     name: "Mimi R.",
//     winner: false
//   }]
// });
define("tunespeak-ember/controllers/contest", [], function () {
  "use strict";
});