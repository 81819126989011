define('tunespeak-ember/models/user-contest-action', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    template: _emberData.default.attr('string'),
    directLimit: _emberData.default.attr('number'),
    pointValue: _emberData.default.attr('number'),
    completed: _emberData.default.attr('boolean'),
    points: _emberData.default.attr('number'),
    totalDirectLimit: _emberData.default.attr('number'),
    currentCount: _emberData.default.attr('number'),
    previewCompleted: _emberData.default.attr('boolean'),
    remaining: _emberData.default.attr('number'),
    nextActionAt: _emberData.default.attr('date'),
    visibleAt: _emberData.default.attr('date'),
    showTimeZone: _emberData.default.attr('string'),
    presaleCode: _emberData.default.attr('string'),
    extUrl: _emberData.default.attr('string'),
    presaleId: _emberData.default.attr('string'),
    onSaleAt: _emberData.default.attr('date'),
    startsAt: _emberData.default.attr('date'),
    countryCode: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    presaleRegistrationId: _emberData.default.attr('string'),
    remainingContent: _emberData.default.attr(),
    verifying: _emberData.default.attr('boolean')
  });
});