define('tunespeak-ember/routes/update-password', ['exports', 'tunespeak-ember/mixins/ts-route', 'tunespeak-ember/mixins/routes/full-screen', 'tunespeak-ember/mixins/ts-route-requires-login'], function (exports, _tsRoute, _fullScreen, _tsRouteRequiresLogin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRoute.default, _fullScreen.default, _tsRouteRequiresLogin.default, {
    setupController: function setupController(controller) {
      controller.set('success', false);
    }
  });
});