define('tunespeak-ember/router', ['exports', 'tunespeak-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');

    this.route('inbox');
    this.route('conversation', { path: 'inbox/:id' });

    this.route('update-phone');

    this.route('email-verified', { path: 'settings/email-verified' });
    this.route('unsubscribe-email', { path: 'settings/unsubscribe-email' });
    this.route('reset-password');

    this.route('redemption', { path: '/redemption/:id' }, function () {
      this.route('decline');
      this.route('review');
      this.route('release');
      this.route('skilled-question');
    });

    this.route('reward', { path: 'reward/:id' });

    this.route('tour', { path: 'tour/:id' });
    this.route('campaign', { path: 'campaign/:id' });
    this.route('presale-faq', { path: 'campaign/:id/faq' });
    this.route('share', { path: 'share/:id' });
    this.route('setlist', { path: 'setlist/:id' });

    this.route('invenue', { path: 'invenue/:invenue_id' }, function () {
      this.route('campaign', { path: 'campaign/:campaign_id' });
    });

    this.route('loading');
    this.route('campaign-ad', { path: 'campaign-ads/:id' });

    this.route('404', { path: '/*path' });
    this.route('update-password');
    this.route('user-video', { path: 'user-video/:id' });
    this.route('winners', { path: 'campaign/:campaign_id/winners' });
    this.route('redemption-contact', { path: 'redemption-contact/:id' });
  });

  exports.default = Router;
});