define("tunespeak-ember/controllers/redemption-contact", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ["token"],
    token: null,
    backgroundImage: "/assets/images/crowd-bg.png",

    session: Ember.inject.service(),
    store: Ember.inject.service(),
    flash: Ember.inject.service("flashMessages"),
    working: false,
    redemptionProxySession: null,

    started: Ember.computed('model.contactWindowStartsAt', function () {
      return moment(this.get('model.contactWindowStartsAt')).isBefore(moment());
    }),
    ended: Ember.computed('model.contactWindowEndsAt', function () {
      return moment(this.get('model.contactWindowEndsAt')).isBefore(moment());
    }),
    inWindow: Ember.computed('started', 'ended', function () {
      return this.get('started') && !this.get('ended');
    }),

    // the number the user will use to contact the manager
    userProxyNumber: Ember.computed("model.proxySession.user_proxy_number", "redemptionProxySession.userProxyNumber", function () {
      return this.get("redemptionProxySession.userProxyNumber") || this.get("model.proxySession.user_proxy_number");
    }),
    formattedUserProxyNumber: Ember.computed('userProxyNumber', function () {
      return this.formatPhoneNumber(this.get('userProxyNumber'));
    }),

    // the number the user submitted to create the proxy session
    submittedPhone: Ember.computed('model.proxySession.user_phone', 'redemptionProxySession.userPhone', function () {
      var phone = this.get('redemptionProxySession.userPhone') || this.get('model.proxySession.user_phone');
      return this.formatPhoneNumber(phone);
    }),

    formatPhoneNumber: function formatPhoneNumber(phoneNumberString) {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return null;
    },


    userProfile: Ember.computed.alias("session.currentUser.profile"),
    phoneValue: Ember.computed.oneWay("userProfile.phone"),
    phone: Ember.computed("phoneValue", function () {
      var val = this.get("phoneValue") || "";
      return val.replace(/\D/g, "");
    }),
    phoneValid: true,
    phoneSaveDisabled: Ember.computed.not("canSavePhone"),
    defaultCountry: Ember.computed("userProfile.countryCode", function () {
      return (this.get("userProfile.countryCode") || "us").toLowerCase();
    }),
    onlyCountries: null,
    preferredCountries: null,
    selectedCountryData: null,

    isCopied: false,
    smsBody: Ember.computed(function () {
      var smsBody = "Message from a tunespeak winner: ";
      if (isMobile.android.device) smsBody = smsBody.replace(/&/g, "and");
      return encodeURIComponent(smsBody);
    }),

    actions: {
      createProxySession: function createProxySession() {
        var _this = this;

        this.set("contactError", null);
        this.set('numbersError', null);
        this.set("working", true);
        this.get("store").createRecord("redemption-proxy-session", {
          userPhone: this.get("phone"),
          redemptionId: this.get("model.id"),
          token: this.get("token")
        }).save().then(function (res) {
          _this.set("redemptionProxySession", res);
        }).catch(function (error) {
          if (error.code === 422) {
            _this.set('contactError', true);
          } else {
            _this.set('numbersError', true);
          }
        }).finally(function () {
          _this.set("working", false);
        });
      },
      didCopyToClipboard: function didCopyToClipboard() {
        var _this2 = this;

        this.set("isCopied", true);
        Ember.run.later(function () {
          _this2.set("isCopied", false);
        }, 3000);
      },
      didNotCopyToClipboard: function didNotCopyToClipboard() {
        this.set("isCopied", false);
        this.get("flash").danger("Sorry, couldn't copy link to your clipboard.", {
          timeout: 3000,
          extendedTimeout: 500,
          destroyOnClick: false
        });
      },
      resetForm: function resetForm() {
        this.set('redemptionProxySession', null);
        this.set('model.proxySession.user_proxy_number', null);
      }
    }
  });
});