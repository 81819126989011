define('tunespeak-ember/mixins/controllers/redemption/affidavit', ['exports', 'tunespeak-ember/utils/validate-email'], function (exports, _validateEmail) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    flash: Ember.inject.service('flashMessages'),

    hideFooter: true,
    errors: null,
    errorsPresent: false,
    phoneIsValidNumber: true,
    phoneOnlyCountries: null,
    defaultCountry: 'us',
    requireAddress: true,

    name: null,
    age: null,
    street: null,
    city: null,
    state: null,
    zip: null,
    phone: null,
    email: null,
    signature: null,
    date: null,
    agreed: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        errors: {},
        phoneOnlyCountries: ['us', 'ca', 'gb', 'fr', 'de', 'es', 'mx', 'ar', 'au']
      });
    },


    canSubmit: Ember.computed('name', 'age', 'street', 'city', 'state', 'zip', 'email', 'phone', 'signature', 'agreed', function () {
      var name = this.name,
          age = this.age,
          street = this.street,
          city = this.city,
          state = this.state,
          zip = this.zip,
          email = this.email,
          phone = this.phone,
          signature = this.signature,
          agreed = this.agreed;


      var addressPresent = true;
      if (this.get('requireAddress')) {
        addressPresent = Ember.isPresent(street) && Ember.isPresent(city) && Ember.isPresent(zip);
      }

      return Ember.isPresent(name) && Ember.isPresent(age) && Ember.isPresent(state) && Ember.isPresent(email) && Ember.isPresent(phone) && Ember.isPresent(signature) && addressPresent && agreed;
    }),

    clearErrors: function clearErrors() {
      this.setProperties({
        errors: {},
        errorsPresent: false
      });
    },
    addError: function addError(key, message) {
      this.set('errors.' + key, [{ message: message }]);
      this.set('errorsPresent', true);
    },
    copyErrorsFromServer: function copyErrorsFromServer() {
      var _this = this;

      this.clearErrors();
      ['name', 'age', 'street', 'city', 'state', 'zip', 'phone', 'email', 'signature'].forEach(function (key) {
        _this.set('errors.' + key, _this.get('model.affidavit.errors.' + key));
      });
    },


    updateErrorsFromServer: Ember.observer('model.affidavit.errors.{name,age,street,city,state,zip,phone,email,signature}', function () {
      Ember.run.once(this, 'copyErrorsFromServer');
    }),

    validate: function validate() {
      this.clearErrors();
      var age = this.age,
          state = this.state,
          email = this.email;


      if (!(0, _validateEmail.default)(email)) {
        this.addError('email', 'is invalid.');
      }

      if (!this.get('phoneIsValidNumber')) {
        this.addError('phone', 'is invalid.');
      }

      if (age < 18) {
        this.addError('age', "must be greater or equal to 18. Please have your parent or legal guardian fill out this form in order to redeem your prize.");
      }

      if (state.length < 2) {
        this.addError('state', 'must be at least 2 letters.');
      }

      return !this.get('errorsPresent');
    }
  });
});