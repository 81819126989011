define('tunespeak-ember/services/ts-socket', ['exports', '@tunespeak/tunespeak-elements/services/ts-socket'], function (exports, _tsSocket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tsSocket.default;
    }
  });
});