define('tunespeak-ember/controllers/campaign', ['exports', 'tunespeak-ember/mixins/enrollment-warnings'], function (exports, _enrollmentWarnings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_enrollmentWarnings.default, {
    queryParams: ['modal', 'enroll', 'force', 'authToken'],
    modal: null,
    enroll: null,
    force: null,
    authToken: null,
    loginService: Ember.computed('model.tmVerifiedFan', function () {
      return this.get('model.tmVerifiedFan') ? 'ticketmaster' : null;
    }),
    enrollment: Ember.inject.service(),
    storage: Ember.inject.service(),
    application: Ember.inject.controller(),
    emShowImage: Ember.computed.alias('application.emShowImage'),
    emShowTitle: Ember.computed.alias('application.emShowTitle'),
    showMenuAlways: Ember.computed.notEmpty('model.groupId'),
    shareLink: Ember.computed.alias('userContest.shortUrl'),
    ads: Ember.computed('model.hasAds', function () {
      if (this.get('model.hasAds')) {
        return this.store.query('campaign-ad', { 'contest_id': this.get('model.id'), 'group_id': this.get('model.groupId') });
      }
      return [];
    }),
    grandPrizeStatus: null,

    campaignTemplate: Ember.computed('model.template', function () {
      var template = this.get('model.template');
      if (template === 'spotify') {
        template = 'basic';
      }
      return 'campaign/' + template + '-template';
    }),

    backgroundImage: Ember.computed.alias('model.backgroundPhoto'),
    isMobile: Ember.computed(function () {
      return isMobile.any;
    }),

    // data for html snippets to access if need be
    snippetData: Ember.computed('model.title', 'model.show.startsAt', 'model.show.location', 'model.groupId', 'session.currentUser.id', 'userContest.id', function () {
      return {
        contest_title: this.get('model.title'),
        contest_show_date: this.get('model.show.startsAt'),
        contest_show_location: this.get('model.show.location'),
        contest_group_id: this.get('model.groupId'),
        user_id: this.get('session.currentUser.id'),
        user_contest_id: this.get('userContest.id')
      };
    }),

    isEnrolling: false,
    isPresale: Ember.computed.equal('model.template', 'presale'),
    isDeluxeWithPresale: Ember.computed.equal('model.template', 'deluxe-with-presale'),
    enrolled: Ember.computed('userContest', 'userContest.current', function () {
      var uc = this.get('userContest');
      if (this.get('isPresale')) {
        return !!uc && uc.get('current') !== false;
      }
      return !!uc;
    }),

    checkForAuthToken: function checkForAuthToken() {
      if (this.get('authToken')) {
        this.get('storage').set('authToken', this.get('authToken'));
        this.set('authToken', null);
      }
    },
    checkShouldEnroll: function checkShouldEnroll() {
      if (this.get('enroll')) {
        this.set('enroll', null);
        if (this.get('storage').get('autoEnroll') === this.get('model.id') || this.get('force')) {
          this.set('force', null);
          this.send('enroll', this.get('storage').get('enrollParams'));
        }
      }
    },


    actions: {
      googleTracking: function googleTracking(eventName, options) {
        options.artist = this.get("model.bandNames");
        options.contest = this.get('model.id');
        if (!options.label) options.label = this.get('model.title');
        gtag('event', eventName, options);
      },
      enroll: function enroll() {
        var _this = this;

        var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        params = params || {};
        if (!this.get('enrolled')) {
          gtag('event', 'enrollment', {
            artist: this.get('model.bandNames'),
            contest: this.get('model.id'),
            label: this.get('model.title')
          });

          this.set('isEnrolling', true);

          var contestId = this.get('model.id');
          // set id in storage to try to auto enroll after login completes
          this.get('storage').set('autoEnroll', contestId);
          this.get('storage').set('enrollParams', params);
          // add 'enroll=true' to redirectURL for 2nd auto enroll condition
          var search = window.location.search.indexOf('?') === -1 ? '?enroll=true' : window.location.search + '&enroll=true';
          var options = {
            id: contestId,
            enrollmentConsentIds: this.get('model.enrollmentConsentIds'),
            redirectURL: '/campaign/' + contestId + search,
            enrollmentWarnings: this.get('enrollmentWarnings'),
            requirePhone: this.get('isDeluxeWithPresale'),
            presaleId: params.presaleId,
            requireVerifiedEmail: this.get('model.requireVerifiedEmail')
          };
          var brand = this.get('model.brand');
          if (brand) {
            options.brand = brand;
          }

          if (this.get('isPresale')) {
            this.get('enrollment').enrollInPresale(options).then(function (userContest) {
              _this.send('enrollmentFinished', userContest);
            }).catch(function () {
              // don't throw error
            }).finally(function () {
              _this.set('isEnrolling', false);
            });
          } else {
            this.get('enrollment').enroll(options).then(function (userContest) {
              _this.send('enrollmentFinished', userContest);
            }).catch(function () {
              // don't throw error
            }).finally(function () {
              _this.set('isEnrolling', false);
            });
          }
        }
      },
      enrollmentFinished: function enrollmentFinished(userContest) {
        // remove auto enroll id
        this.get('storage').remove('autoEnroll');
        this.get('storage').remove('enrollParams');

        if (!this.get('userContest')) {
          this.set('userContest', userContest);

          Ember.run.scheduleOnce('afterRender', this, function () {
            Ember.$('.enrolled-container').css('display', 'none'); //has to be display none for slide animation to work
            Ember.$('.enrolled-container').slideDown(1000);
          });

          gtag('event', 'enrollment-complete', {
            artist: this.get('model.bandNames'),
            contest: this.get('model.id'),
            label: this.get('model.title')
          });
        }
      },
      reloadUserContest: function reloadUserContest(uc) {
        this.set('userContest', uc);
      }
    }
  });
});