define('tunespeak-ember/models/user-playlist', ['exports', 'ember-data', 'tunespeak-ember/mixins/models/shareable', 'tunespeak-ember/mixins/models/appreciation-engine-action'], function (exports, _emberData, _shareable, _appreciationEngineAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_shareable.default, _appreciationEngineAction.default, {
    name: _emberData.default.attr('string'),
    userId: _emberData.default.attr('string'),
    contestId: _emberData.default.attr('string'),
    playlistCallId: _emberData.default.attr('string'),
    songs: _emberData.default.attr(),
    promotionId: _emberData.default.attr('string'),
    contestActionId: _emberData.default.attr('string'),
    shortUrl: _emberData.default.attr('string'),
    spotifyPlaylistUrl: _emberData.default.attr('string'),
    extPlaylistId: _emberData.default.attr('string'),
    backgroundPhoto: _emberData.default.attr('string'),
    source: _emberData.default.attr('string'),
    photo: _emberData.default.attr('string'),
    logo: _emberData.default.attr('string'),
    tweetText: _emberData.default.attr('string'),
    smsBody: _emberData.default.attr('string'),
    emailBody: _emberData.default.attr('string'),
    bandName: _emberData.default.attr('string'),
    contestPreview: _emberData.default.belongsTo('contest-preview', { async: false }),
    contestGroupPreview: _emberData.default.belongsTo('contest-group-preview', { async: false })
  });
});