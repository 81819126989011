define('tunespeak-ember/controllers/redemption/review', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    hideFooter: true,
    actions: {
      submit: function submit() {
        var _this = this;

        var model = this.get('model');
        this.set('isSubmitting', true);
        model.setProperties({
          submitQuestions: true,
          submitAffidavit: false
        });
        model.save().then(function (redemption) {
          var canadianQuestion = redemption.get('questions').findBy('label', 'canadian');
          // if canadian answer skilled question
          if (canadianQuestion && canadianQuestion.get('answer') === 'yes') {
            _this.transitionToRoute('redemption.skilled-question');
          } else if (redemption.get('affidavit')) {
            _this.transitionToRoute('redemption.release');
          }
        }).finally(function () {
          _this.set('isSubmitting', false);
        });
      }
    }
  });
});