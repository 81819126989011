define('tunespeak-ember/components/contest-actions/instagram-album-look', ['exports', 'ember-data', 'tunespeak-ember/mixins/components/spotify-action'], function (exports, _emberData, _spotifyAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_spotifyAction.default, {
    classNames: ['instagram-album-look'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('photos', []);
      this.updateRemainingContent(true);
    },

    remaining: Ember.computed.gt('userAction.remaining', 0),
    afterChange: Ember.computed(function () {
      var _this = this;

      return function (e, slick, currentSlide) {
        _this.set('currentSlide', currentSlide++);
      };
    }),
    totalPoints: Ember.computed('model.{totalDirectLimit,pointValue}', function () {
      return this.get('model.totalDirectLimit') * this.get('model.pointValue');
    }),
    currentSlide: null,
    photos: null,
    canExpand: Ember.computed.gt('photos.length', 8),
    showPhotoViewer: false,
    prevArrow: '<button class="btn slick-prev"><i class="icon-chevron-left"></i></button>',
    nextArrow: '<button class="btn slick-next"><i class="icon-chevron-right"></i></button>',
    updateRemainingContent: function updateRemainingContent() {
      var _this2 = this;

      var loadPhotos = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var uca = this.get('store').peekRecord('user-contest-action', this.get('userAction.id'));
      this.get('store').query('user-contest-item', {
        contest_id: this.get('contest.id'),
        contest_action_id: uca.get('id')
      }).then(function (activities) {
        if (loadPhotos) _this2.set('photos', activities.map(function (activity) {
          return activity.get('item');
        }));
        uca.set('remainingContent', activities);
        uca.set('remaining', activities.reduce(function (acc, activity) {
          return acc + activity.get('remaining');
        }, 0));
      }).catch(function (error) {
        if (error instanceof _emberData.default.UnauthorizedError) {
          _this2.get('session').open();
        }
      });
    },

    actions: {
      showPhotoViewer: function showPhotoViewer(index) {
        this.toggleProperty('showPhotoViewer');
        Ember.run.later(function () {
          Ember.$('.photo-carousel').slick('slickGoTo', index, true);
        });
      },
      hidePhotoViewer: function hidePhotoViewer() {
        this.set('showPhotoViewer', false);
      },
      showPhotos: function showPhotos() {
        this.$('.photo-container').addClass('full');
        this.$('.bottom-shadow').hide();
      },
      didEarnCredit: function didEarnCredit() {
        Ember.run.throttle(this, this.updateRemainingContent, 3000, false);
      }
    }
  });
});