define('tunespeak-ember/serializers/html-snippet', ['exports', 'tunespeak-ember/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    keyForAttribute: function keyForAttribute(key) {
      if (key === 'triggerName') {
        return 'trigger';
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
});