define('tunespeak-ember/utils/youtube-player', ['exports', '@tunespeak/tunespeak-elements/utils/youtube-player'], function (exports, _youtubePlayer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _youtubePlayer.default;
    }
  });
});