define('tunespeak-ember/components/contest-actions/presale-registration', ['exports', 'tunespeak-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    classNames: ['ts-list-item', 'campaign-action-item'],
    requirePhone: Ember.inject.service(),
    model: null,
    contest: null,
    userContest: null,
    showPresaleChooser: false,
    session: Ember.inject.service(),
    presaleOptions: Ember.computed('model', function () {
      return this.get('model.content.presale_options').sortBy('show_starts_at');
    }),
    multiplePresaleOptions: Ember.computed.gt('presaleOptions.length', 1),

    parsedPhone: Ember.computed('userAction.{phone,country_code}', function () {
      var phone = this.get('userAction.phone');
      if (phone) {
        return '(' + phone.slice(0, 3) + ') ' + phone.slice(3, 6) + '-' + phone.slice(6);
      }
      return '(___) ___-____';
    }),

    userAction: Ember.computed('userContest.actions', function () {
      if (this.get('userContest.actions')) {
        return this.get('userContest.actions').findBy('id', this.get('model.id'));
      }
      return null;
    }),
    presaleHasStarted: Ember.computed('userAction.onSaleAt', function () {
      return moment(this.get('userAction.onSaleAt')).isBefore();
    }),
    selectedOption: Ember.computed('presaleOptions', 'userAction.presaleId', function () {
      return this.get('presaleOptions').findBy('id', this.get('userAction.presaleId'));
    }),

    updatePresaleRegistration: function updatePresaleRegistration(enabled) {
      var _this = this;

      var profile = this.get('session.currentUser.profile');
      var json = {
        unverified_phone: null,
        unverified_country_code: null
      };

      if (enabled) {
        json.unverified_phone = profile.get('phone');
        json.unverified_country_code = profile.get('countryCode');
      }

      var regId = this.get('userAction.presaleRegistrationId');
      this.get('ajax').put(_environment.default.APP.API_ROOT + '/presale_registrations/' + regId, {
        data: { presale_registration: json }
      }).then(function (reg) {
        var _reg$presale_registra = reg.presale_registration,
            unverified_phone = _reg$presale_registra.unverified_phone,
            unverified_country_code = _reg$presale_registra.unverified_country_code;

        _this.get('userAction').setProperties({
          phone: unverified_phone,
          countryCode: unverified_country_code
        });
      });
    },

    actions: {
      didCopyToClipboard: function didCopyToClipboard() {
        var _this2 = this;

        this.set('isCopiedError', false);
        this.set('isCopied', true);
        Ember.run.later(function () {
          _this2.set('isCopied', false);
        }, 3000);
      },
      didNotCopyToClipboard: function didNotCopyToClipboard() {
        this.set('isCopied', false);
        this.set('isCopiedError', true);
      },
      togglePresaleChooser: function togglePresaleChooser() {
        this.toggleProperty('showPresaleChooser');
      },
      updatePresale: function updatePresale(id) {
        alert('Updating presale not currently supported.');
        // if (id === this.get('selectedOption.id')) {
        //   return this.send('togglePresaleChooser');
        // }
        // this.get('userContest').set('presaleId', id);
        // this.get('userContest').save().finally(() => {
        //   this.send('togglePresaleChooser');
        // });
      },
      editPhone: function editPhone() {
        var _this3 = this;

        this.get('requirePhone').requirePhone().then(function (textEnabled) {
          _this3.updatePresaleRegistration(textEnabled);
        });
      }
    }
  });
});