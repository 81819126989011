define('tunespeak-ember/models/in-venue', ['exports', 'ember-data', 'tunespeak-ember/mixins/models/shareable'], function (exports, _emberData, _shareable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_shareable.default, {
    title: _emberData.default.attr('string'),
    startsAt: _emberData.default.attr('date'),
    started: _emberData.default.attr('boolean'),
    checkInStartsAt: _emberData.default.attr('date'),
    location: _emberData.default.attr('string'),
    backgroundPhoto: _emberData.default.attr('string'),
    headerPhoto: _emberData.default.attr('string'),
    shareableFacebookPhoto: _emberData.default.attr('string'),
    checkInOpen: _emberData.default.attr('boolean'),
    smsBody: _emberData.default.attr('string'),
    tweetText: _emberData.default.attr('string'),
    emailBody: _emberData.default.attr('string'),
    themeCss: _emberData.default.attr('string'),
    ticketUrl: _emberData.default.attr('string'),
    venueName: _emberData.default.attr('string'),
    bandNames: _emberData.default.attr('string'),
    contestPreviews: _emberData.default.hasMany('contest-preview')
  });
});