define('tunespeak-ember/components/contest-actions/facebook-like', ['exports', 'tunespeak-ember/mixins/components/campaign-action', 'tunespeak-ember/torii-providers/session-facebook', 'tunespeak-ember/utils/cookie-intent'], function (exports, _campaignAction, _sessionFacebook, _cookieIntent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignAction.default, {
    classNames: ['facebook-like', 'ts-list-item', 'campaign-action-item'],
    session: Ember.inject.service(),
    fbId: Ember.computed.alias('model.content.facebook_id'),
    fbUrl: Ember.computed(function () {
      return 'https://www.facebook.com/' + this.get('fbId');
    }),
    isChecking: false,
    needsLogin: false,
    showModal: false,
    needsPermission: false,
    showPermissionModal: false,
    showReloadModal: false,
    showLoginModal: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (0, _cookieIntent.handleFacebookLikeCookie)(this.get('storage').get('authCookie'), this.get('model.id'), this);
    },

    fbLikeChecker: function fbLikeChecker() {
      var _this = this;

      this.set('isChecking', true);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _sessionFacebook.fbStatusPromise.then(function () {
          if (FB.getAuthResponse()) {
            FB.api('/me/likes?target_id=' + _this.get('fbId'), function (response) {
              if (response.data && response.data[0]) {
                resolve();
              } else {
                FB.api('/me/permissions/user_likes', function (response) {
                  if (response.data && response.data[0] && response.data[0].status === 'declined') {
                    _this.set('needsPermission', true);
                    reject();
                  } else {
                    reject();
                  }
                });
              }
            });
          } else {
            _this.set('needsLogin', true);
            reject();
          }
        });
      }).finally(function () {
        _this.set('isChecking', false);
      });
    },
    renderFacebookButton: function renderFacebookButton() {
      _sessionFacebook.fbInitPromise.then(function () {
        Ember.run.next(function () {
          //re-render facebook widgets
          FB.XFBML.parse();
        });
      });
    },
    doLike: function doLike() {
      var _this2 = this;

      this.set('working', true);
      var record = this.get('store').createRecord('facebook-like', {
        contestId: this.get('contest.id'),
        followableId: this.get('bandId'),
        followableType: 'Band',
        promotionId: this.get('promotionId'),
        contestActionId: this.get('model.id')
      });

      record.save().then(function () {
        _this2.set('complete', true);
        _this2.set('working', false);
        _this2.get('googleTracking')('facebook-like', {
          category: 'campaign-action',
          label: _this2.get('title')
        });
      });
    },
    createAuthCookie: function createAuthCookie() {
      if (this.get('session.isEmbeddedWebView')) {
        this.get('session').createAuthCookie({
          intent: 'facebook-like',
          action_id: this.get('model.id'),
          service: 'facebook'
        });
      }
    },


    actions: {
      like: function like() {
        var _this3 = this;

        this.get('session').removeAuthCookie();
        this.fbLikeChecker().then(function () {
          _this3.doLike();
        }).catch(function () {
          if (_this3.get('needsPermission')) {
            _this3.send('openPermissionModal');
          } else if (_this3.get('needsLogin')) {
            _this3.send('openLoginModal');
          } else {
            _this3.send('showModal');
          }
        });
      },
      closeModal: function closeModal() {
        var _this4 = this;

        this.get('session').removeAuthCookie();
        this.set('showModal', false);
        this.fbLikeChecker().then(function () {
          _this4.doLike();
        });
      },
      showModal: function showModal() {
        var _this5 = this;

        this.createAuthCookie();
        this.set('showModal', true);
        Ember.run.next(function () {
          _this5.renderFacebookButton();
        });
      },
      doLogin: function doLogin() {
        var _this6 = this;

        this.createAuthCookie();
        var fbId = this.get('session.currentUser.facebook.ext_id');
        this.get('session').loginService('facebook').then(function () {
          if (fbId != _this6.get('session.currentUser.facebook.ext_id')) {
            _this6.set('showReloadModal', true);
          } else {
            _this6.setProperties({
              needsLogin: false,
              showLoginModal: false
            });
            _this6.send('like');
          }
        });
      },
      openPermissionModal: function openPermissionModal() {
        this.set('showPermissionModal', true);
      },
      closePermissionModal: function closePermissionModal() {
        this.get('session').removeAuthCookie();
        this.set('showPermissionModal', false);
      },
      openLoginModal: function openLoginModal() {
        this.set('showLoginModal', true);
      },
      closeLoginModal: function closeLoginModal() {
        this.get('session').removeAuthCookie();
        this.set('showLoginModal', false);
      },
      requestPermission: function requestPermission() {
        var _this7 = this;

        this.createAuthCookie();
        this.get('session').loginService('facebook-reauth', { scope: 'user_likes' }).then(function () {
          _this7.send('closePermissionModal');
          _this7.set('needsPermission', false);
          _this7.send('like');
        });
      },
      reload: function reload() {
        window.location.reload();
      }
    }
  });
});