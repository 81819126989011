define('tunespeak-ember/models/user-profile', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    emailVerified: _emberData.default.attr('boolean'),
    emailConfirmed: _emberData.default.attr('boolean'),
    emailEnabled: _emberData.default.attr(),
    email: _emberData.default.attr('string'),
    emailCampaignReminder: _emberData.default.attr(),
    emailWeeklySummary: _emberData.default.attr(),
    emailBandMessage: _emberData.default.attr(),
    emailFavoriteBands: _emberData.default.attr(),
    phone: _emberData.default.attr('string'),
    pendingPhone: _emberData.default.attr('string'),
    phoneVerified: _emberData.default.attr('string'),
    phoneFormatted: _emberData.default.attr('string'),
    phoneEnabled: _emberData.default.attr(),
    countryCode: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    password: _emberData.default.attr(),
    birthday: _emberData.default.attr('string'),
    afterVerifyRedirectUrl: _emberData.default.attr('string'),
    canEnroll: Ember.computed.and('emailVerified', 'emailConfirmed'),
    recaptchaToken: _emberData.default.attr('string'),
    promotionId: _emberData.default.attr('string'),
    locale: _emberData.default.attr('string'),
    unverifiedPhone: _emberData.default.attr('string'),
    unverifiedCountryCode: _emberData.default.attr('string'),

    resendEmailVerification: function resendEmailVerification() {
      var _this = this;

      this.set('isSendingEmailVerification', true);
      var adapter = Ember.getOwner(this).lookup('adapter:userProfile');
      return adapter.ajax(adapter.urlForResendEmailVerification(this.id), 'POST').finally(function () {
        _this.set('isSendingEmailVerification', false);
      });
    }
  });
});