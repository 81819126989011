define('tunespeak-ember/torii-providers/session-google', ['exports', '@tunespeak/tunespeak-elements/torii-providers/session-google'], function (exports, _sessionGoogle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sessionGoogle.default;
    }
  });
});