define('tunespeak-ember/services/require-phone', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var deferred;
  var resolveDeferred = function resolveDeferred(consented) {
    if (deferred) {
      deferred.resolve(consented);
    }
  };

  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    flash: Ember.inject.service('flashMessages'),
    store: Ember.inject.service(),
    showModal: false,
    isLoading: false, //boolean to know when an async function is working
    allowSkip: true,

    updatePhone: function updatePhone(options) {
      var _this = this;

      this.get('store').findRecord('user-profile', this.get('session.currentUser.id')).then(function (profile) {
        profile.setProperties(options);
        profile.save().then(function () {
          _this.toggleProperty('showModal');
          resolveDeferred(true);
        }).catch(function (error) {
          var message = 'An error occurred';
          if (error instanceof _emberData.default.InvalidError) {
            message = 'Please Enter a valid phone number';
          }
          _this.get('flash').danger(message, {
            timeout: 5000,
            extendedTimeout: 500,
            destroyOnClick: false
          });
        });
      });
    },
    cancel: function cancel() {
      deferred.reject();
      this.set('showModal', false);
    },
    skip: function skip() {
      this.toggleProperty('showModal');
      resolveDeferred(false);
    },
    requirePhone: function requirePhone(options) {
      if (options) {
        this.setProperties(options);
      }
      deferred = new Ember.RSVP.defer();
      this.toggleProperty('showModal');
      return deferred.promise;
    }
  });
});