define('tunespeak-ember/initializers/ember-cli-webfontloader', ['exports', 'tunespeak-ember/config/environment', 'ember-cli-webfontloader/initializers/ember-cli-webfontloader'], function (exports, _environment, _emberCliWebfontloader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'ember-cli-webfontloader',

    initialize: function initialize() {
      if (typeof FastBoot === 'undefined') {
        var config = Ember.get(_environment.default, 'webFontConfig') || {};
        (0, _emberCliWebfontloader.default)(config);
      }
    }
  };
});