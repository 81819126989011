define('tunespeak-ember/models/user', ['exports', 'ember-data', 'tunespeak-ember/mixins/models/user'], function (exports, _emberData, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_user.default, {
    unreadCount: Ember.computed.alias('notifications.unreadCount'),
    hasUnreadMessages: Ember.computed.gt('unreadCount', 0),
    profile: _emberData.default.belongsTo('user-profile', { async: false }),
    firstNameLastInitial: Ember.computed('firstName', 'lastName', function () {
      var name = [this.get('firstName')];
      var ln = this.get('lastName');
      if (ln) {
        name.push(ln.slice(0, 1) + '.');
      }
      return name.join(' ');
    }),
    visitorId: _emberData.default.attr('string'),
    cfCountry: _emberData.default.attr('string')
  });
});