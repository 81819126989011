define('tunespeak-ember/components/mobile-phone-reminder', ['exports', 'tunespeak-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['mobile-phone-reminder'],
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    flash: Ember.inject.service('flashMessages'),
    storage: Ember.inject.service(),
    campaign: null,
    storageId: Ember.computed('campaign.id', function () {
      return 'hideMobilePhoneReminder-' + this.get('campaign.id');
    }),
    isMobile: Ember.computed(function () {
      return isMobile.any;
    }),
    phoneValue: null,
    phone: Ember.computed('phoneValue', function () {
      var val = this.get('phoneValue') || '';
      return val.replace(/\D/g, '');
    }),
    isVerifying: false,
    isValidPhone: null,
    resentVerification: false, // true if verification was resent
    expanded: false, // true when mobile user clicked to add phone #
    userProfile: Ember.computed.alias('session.currentUser.profile'),
    defaultCountry: Ember.computed('userProfile.countryCode', function () {
      return (this.get('userProfile.countryCode') || 'us').toLowerCase();
    }),
    onlyCountries: null,
    preferredCountries: null,
    selectedCountryData: null,
    formattedPhone: Ember.computed('phone', 'selectedCountryData.iso2', function () {
      return intlTelInputUtils.formatNumber(this.get('phone'), this.get('selectedCountryData.iso2'), "E164");
    }),

    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      this.setProperties({
        preferredCountries: this.get('preferredCountries') || []
      });
      if (!this.get('session.isAuthenticated') || this.get('userProfile.phone') && this.get('userProfile.phoneVerified') || this.get('storage').get(this.get('storageId'))) this.set('isVisible', false);
    },
    _hideReminder: function _hideReminder() {
      this.set('isVisible', false);
      this.destroy();
    },


    actions: {
      googleTracking: function googleTracking(eventName, options) {
        options.artist = this.get("campaign.bandNames");
        options.contest = this.get("campaign.id");
        options.label = this.get("campaign.title");
        gtag("event", eventName, options);
      },
      expandMobile: function expandMobile() {
        this.set('expanded', true);
      },
      userHideReminder: function userHideReminder() {
        this.get('storage').set(this.get('storageId'), true);
        this._hideReminder();
      },
      sendPhoneVerification: function sendPhoneVerification() {
        this.set('isVerifying', true);
        this.set('phoneVerificationSent', true);
        this.get('ajax').post(_environment.default.APP.API_ROOT + '/user_profiles/send_phone_verification');
        this.send("googleTracking", "send-mobile-verification-code", {
          category: "mobile-phone-reminder"
        });
      },
      resendPhoneVerification: function resendPhoneVerification() {
        var _this = this;

        this.set('resentVerification', true);
        this.send('sendPhoneVerification');
        Ember.run.later(function () {
          if (!_this.get('isDestroyed') && !_this.get('isDestroying')) {
            _this.set('resentVerification', false);
          }
        }, 3000);
        this.send("googleTracking", "resend-mobile-verification-code", {
          category: "mobile-phone-reminder"
        });
        return false;
      },
      updatePhone: function updatePhone() {
        var _this2 = this;

        if (intlTelInputUtils.isValidNumber('+' + this.get('selectedCountryData.dialCode') + this.get('phone'))) {
          var cc = this.get('selectedCountryData.iso2');
          this.get('ajax').put(_environment.default.APP.API_ROOT + '/user_profiles/' + this.get('userProfile.id'), {
            data: {
              user_profile: {
                phone_verification_attributes: {
                  phone: this.get('phone'),
                  country_code: cc ? cc.toUpperCase() : null
                }
              }
            }
          }).then(function () {
            _this2.set('userProfile.pendingPhone', _this2.get('phone'));
            _this2.send('sendPhoneVerification');
            _this2.send("googleTracking", "user-added-mobile-number", {
              category: "mobile-phone-reminder"
            });
          }).catch(function () {
            _this2.get('flash').danger('Something went wrong', {
              timeout: 5000,
              extendedTimeout: 500,
              destroyOnClick: true
            });
          });
        } else {
          this.get('flash').danger('Mobile number is invalid', {
            timeout: 5000,
            extendedTimeout: 500,
            destroyOnClick: true
          });
        }
        return false;
      },
      verifyPhone: function verifyPhone() {
        var _this3 = this;

        var token = this.token;

        if (token && token.length === 4) {
          this.get('ajax').post(_environment.default.APP.API_ROOT + '/verify_phone', {
            data: {
              token: token
            }
          }).then(function () {
            _this3.get('flash').success('Phone Verified', {
              timeout: 5000,
              extendedTimeout: 500,
              destroyOnClick: true
            });
            _this3._hideReminder();
            _this3.send("googleTracking", "user-verified-mobile-number", {
              category: "mobile-phone-reminder"
            });
          }).catch(function (e) {
            _this3.get('flash').danger('Verification Failed', {
              timeout: 5000,
              extendedTimeout: 500,
              destroyOnClick: true
            });
          });
        }
        return false;
      }
    }
  });
});