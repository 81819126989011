define('tunespeak-ember/mixins/routes/redemption/affidavit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var properties = {
        date: moment().format('LL'),
        name: null,
        street: null,
        city: null,
        state: null,
        zip: null,
        age: null,
        email: null,
        phone: null
      };

      var user = model.get('user');

      var addressQ = model.get('questions').findBy('label', 'address');
      if (addressQ) {
        var address = addressQ.get('address');
        if (address) {
          properties.street = address.street;
          properties.city = address.city;
          properties.state = address.state;
          properties.zip = address.zip;
        }
      }

      var nameQ = model.get('questions').findBy('label', 'name');
      if (nameQ && nameQ.get('answer')) {
        properties.name = nameQ.get('answer');
      } else {
        properties.name = user.first_name + ' ' + user.last_name;
      }

      var phoneQ = model.get('questions').findBy('label', 'phone');
      if (phoneQ && phoneQ.get('answer')) {
        properties.phone = phoneQ.get('answer');
      } else {
        properties.phone = '' + user.phone;
      }

      var emailQ = model.get('questions').findBy('label', 'email');
      if (emailQ && emailQ.get('answer')) {
        properties.email = emailQ.get('answer');
      } else {
        properties.email = '' + user.email;
      }

      var ageQ = model.get('questions').findBy('label', 'age');
      if (ageQ && ageQ.get('answer')) {
        properties.age = ageQ.get('answer');
      } else if (user.age) {
        properties.age = user.age.toString();
      }

      controller.setProperties(properties);
    }
  });
});