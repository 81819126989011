define('tunespeak-ember/controllers/unsubscribe-email', ['exports', 'tunespeak-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    backgroundImage: '/assets/images/crowd-bg.png',
    queryParams: ['token', 'id', 'type', { bandId: 'band_id' }],
    id: null,
    token: null,
    type: null,
    bandId: null,

    ajax: Ember.inject.service(),
    store: Ember.inject.service(),

    isUserBand: Ember.computed.equal('type', 'band'),
    userBand: Ember.computed('bandId', function () {
      var bandId = this.get('bandId');
      return bandId ? this.get('store').findRecord('band', bandId) : null;
    }),
    isCampaignReminder: Ember.computed.equal('type', 'campaign_reminder'),
    isWeeklySummary: Ember.computed.equal('type', 'user_weekly_summary'),
    isFavoriteBand: Ember.computed.equal('type', 'favorite_band'),
    validParams: Ember.computed.and('token', 'id'),
    isPost: Ember.computed.or('success', 'error'),

    unsubscribe: function unsubscribe(url, workingBoolean) {
      var _this = this;

      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      this.set(workingBoolean, true);
      options.token = this.get('token');
      this.get('ajax').post(url, {
        data: options
      }).then(function () {
        _this.set('success', true);
        if (_this.get('session.currentUser')) {
          _this.get('session.currentUser').reload();
        }
      }).catch(function () {
        _this.set('error', true);
      }).finally(function () {
        _this.set(workingBoolean, false);
      });
    },


    actions: {
      unsubscribeBandEmails: function unsubscribeBandEmails() {
        var url = _environment.default.APP.API_ROOT + '/user_bands/unsubscribe_email';
        this.unsubscribe(url, 'bandEmailsWorking', {
          band_id: this.get('bandId'),
          user_id: this.get('id')
        });
      },
      unsubscribeCampaignReminders: function unsubscribeCampaignReminders() {
        var url = _environment.default.APP.API_ROOT + '/user_profiles/unsubscribe_campaign_reminder_email';
        this.unsubscribe(url, 'campaignRemindersWorking', {
          id: this.get('id')
        });
      },
      unsubscribeWeeklySummaryEmails: function unsubscribeWeeklySummaryEmails() {
        var url = _environment.default.APP.API_ROOT + '/user_profiles/unsubscribe_weekly_summary_email';
        this.unsubscribe(url, 'weeklySummaryEmailsWorking', {
          id: this.get('id')
        });
      },
      unsubscribeFavoriteBandEmails: function unsubscribeFavoriteBandEmails() {
        var url = _environment.default.APP.API_ROOT + '/user_profiles/unsubscribe_email_favorite_bands';
        this.unsubscribe(url, 'favoriteBandEmailsWorking', {
          id: this.get('id')
        });
      },
      unsubscribeAllEmails: function unsubscribeAllEmails() {
        var url = _environment.default.APP.API_ROOT + '/user_profiles/unsubscribe_email';
        this.unsubscribe(url, 'allEmailsWorking', {
          id: this.get('id')
        });
      }
    }
  });
});