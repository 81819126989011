define('tunespeak-ember/models/ext-tracking-ref', ['exports', 'ember-data', 'tunespeak-ember/mixins/models/appreciation-engine-action'], function (exports, _emberData, _appreciationEngineAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_appreciationEngineAction.default, {
    contestId: _emberData.default.attr('string'),
    promotionId: _emberData.default.attr('string'),
    confirm: _emberData.default.attr('boolean'),
    overridden: _emberData.default.attr('boolean')
  });
});