define('tunespeak-ember/components/share/email-share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    text: null,
    emailBody: Ember.computed('text', 'model.content.email_body', 'shareLink', function () {
      var emailBody = this.get('text') || this.get('model.content.email_body');
      return encodeURIComponent(emailBody + '\n\n' + this.get('shareLink') + '\n\nThanks,\n' + this.get('session.currentUser.firstName'));
    }),
    click: function click() {
      this.get("googleTracking")("email-share", {
        category: "share",
        label: this.get("contest.title")
      });
    }
  });
});