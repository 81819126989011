define('tunespeak-ember/components/redemption-question/address', ['exports', 'tunespeak-ember/components/redemption-question/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    watchAddressChange: Ember.observer('street', 'city', 'state', 'zip', function () {
      // without `once` this will run 4x every time
      Ember.run.once(this, 'copyAddressToAnswer');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var address = this.get('model.address');
      if (address) {
        this.setProperties({
          street: address.street,
          city: address.city,
          state: address.state,
          zip: address.zip
        });
      }
    },


    copyAddressToAnswer: function copyAddressToAnswer() {
      var street = this.street,
          city = this.city,
          state = this.state,
          zip = this.zip;

      this.get('model').setProperties({
        answer: street + ', ' + city + ', ' + state + ' ' + zip,
        address: {
          street: street,
          city: city,
          state: state,
          zip: zip
        }
      });
    },

    validate: function validate() {
      this._super();
      var errors = [];
      if (Ember.isBlank(this.get('street'))) {
        errors.push('Street');
      }
      if (Ember.isBlank(this.get('city'))) {
        errors.push('City');
      }
      if (Ember.isBlank(this.get('state'))) {
        errors.push('State');
      }
      if (Ember.isBlank(this.get('zip'))) {
        errors.push('Zip');
      }

      if (errors.length) {
        var msg = errors.join(', ') + (errors.length === 1 ? ' is ' : ' are ') + 'required.';
        this.get('errors').pushObject(msg);
      }
    }
  });
});