define('tunespeak-ember/models/album', ['exports', 'ember-data', 'tunespeak-ember/mixins/models/shareable'], function (exports, _emberData, _shareable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_shareable.default, {
    bandId: _emberData.default.attr('string'),
    songs: _emberData.default.hasMany('song'),
    title: _emberData.default.attr('string'),
    cover: _emberData.default.attr('string'),
    thumbnail: _emberData.default.attr('string'),
    year: _emberData.default.attr('string'),
    spotifyId: _emberData.default.attr('string'),
    article: 'an',
    verb: 'Listen to'
  });
});