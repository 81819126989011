define('tunespeak-ember/mixins/routes/inline-navbar', ['exports', '@tunespeak/tunespeak-elements/mixins/routes/inline-navbar'], function (exports, _inlineNavbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _inlineNavbar.default;
    }
  });
});