define('tunespeak-ember/components/enrollment-lock', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    contest: null,
    userContest: null,
    creditService: Ember.inject.service('credit'),
    flash: Ember.inject.service('flashMessages'),
    isUnlocked: Ember.computed('userContest.enrollmentLock.state', function () {
      return this.get('userContest.enrollmentLock.state') === 'unlocked';
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('creditService').on('didReceiveCredit', this, this.creditHandler);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('creditService').off('didReceiveCredit', this, this.creditHandler);
    },
    creditHandler: function creditHandler(credit) {
      if (this.get('userContest.enrollmentLock.requirementCaIds').includes(credit.get('contestActionId'))) {
        this.send('reloadUserContest');
      }
    },


    actions: {
      reloadUserContest: function reloadUserContest() {
        var _this = this;

        var prevState = this.get('userContest.enrollmentLock.state');
        this.get('userContest').reload().then(function (uc) {
          _this.set('userContest', uc);
          _this.notifyPropertyChange('userContest');
          _this.sendAction('reloadUserContest', uc);
          if (prevState === 'locked' && uc.get('enrollmentLock.state') === 'unlocked') {
            _this.get('flash').info("You're entered to win!", {
              timeout: 3000,
              extendedTimeout: 500,
              destroyOnClick: false
            });
          }
        });
      }
    }
  });
});