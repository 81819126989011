// // import TsRouteRequiresLoginMixin from '../mixins/ts-route-requires-login';
// import TsRoute from '../mixins/ts-route';
//
// export default Route.extend(TsRouteRequiresLoginMixin, TsRoute, {
//   model: function (params) {
//     return this.store.findRecord('contest', params.id);
//   }
// });
define("tunespeak-ember/routes/contest", [], function () {
  "use strict";
});