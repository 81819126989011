define('tunespeak-ember/helpers/ts-linebreaks', ['exports', '@tunespeak/tunespeak-elements/helpers/ts-linebreaks'], function (exports, _tsLinebreaks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tsLinebreaks.default;
    }
  });
  Object.defineProperty(exports, 'tsLinebreaks', {
    enumerable: true,
    get: function () {
      return _tsLinebreaks.tsLinebreaks;
    }
  });
});