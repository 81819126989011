define('tunespeak-ember/components/contest-actions/soundcloud-album-play', ['exports', 'tunespeak-ember/components/contest-actions/soundcloud-song-play', 'ember-data'], function (exports, _soundcloudSongPlay, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _soundcloudSongPlay.default.extend({
    isAlbum: true,

    updateRemainingContent: function updateRemainingContent() {
      var _this = this;

      var uca = this.get('store').peekRecord('user-contest-action', this.get('userAction.id'));
      this.get('store').query('user-contest-item', {
        contest_id: this.get('contest.id'),
        contest_action_id: uca.get('id')
      }).then(function (activities) {
        uca.set('remainingContent', activities);
        uca.set('remaining', activities.reduce(function (acc, activity) {
          return acc + activity.get('remaining');
        }, 0));
      }).catch(function (error) {
        if (error instanceof _emberData.default.UnauthorizedError) {
          _this.get('session').open();
        }
      });
    },


    actions: {
      toggleRemainingModal: function toggleRemainingModal() {
        if (!this.get('showRemainingModal')) {
          this.updateRemainingContent();
        }
        this.toggleProperty('showRemainingModal');
      }
    }
  });
});