define('tunespeak-ember/components/contest-actions/youtube-video-view', ['exports', 'tunespeak-ember/mixins/components/video-action', 'tunespeak-ember/utils/youtube-player', 'ember-data'], function (exports, _videoAction, _youtubePlayer, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_videoAction.default, {
    classNames: ['youtube-video-view'],
    containerClass: 'youtube-player-container',
    totalPoints: Ember.computed('model.{totalDirectLimit,pointValue}', function () {
      return this.get('model.totalDirectLimit') * this.get('model.pointValue');
    }),
    isYoutubeVideoGroup: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.setupPlayer().then(function (player) {
        // optional callback when setup finishes
        if (_this.get('onSetupFinish')) {
          _this.get('onSetupFinish')(player);
        }
      });
    },

    _youtubeIdObserver: Ember.observer('model.content.youtube_id', function () {
      if (this.get('player')) {
        if (this.get('view') && !this.get('view.isSaving')) {
          // if view is not currently being saved try to update it one last time
          this.updateView();
        }
        this.playerReset(); // set view null
        this.get('player').resetPlayer(); // reset player count & total-played
        this.get('player').api.cueVideoById({ // load new video but don't autoplay
          videoId: this.get('model.content.youtube_id')
        });
      }
    }),
    _playerFinished: Ember.observer('isFinished', function () {
      var _this2 = this;

      if (this.get('isFinished')) {
        this.updateView().then(function () {
          _this2.playerReset();
          if (_this2.get('onFinish')) {
            _this2.get('onFinish')(_this2.get('model.id'));
          }
        });
        if (!this.get('isYoutubeVideoGroup')) {
          this.removePlayer();
        }
      }
    }),
    setupPlayer: function setupPlayer() {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var player = new _youtubePlayer.default({
          playerId: _this3.get('playerId'),
          videoId: _this3.get('model.content.youtube_id'),
          width: '100%',
          height: '100%',
          playerVars: {
            html5: _this3.supportsHtml5 ? 1 : 0,
            playsinline: 1,
            rel: 0,
            showinfo: 0,
            modestbranding: 1,
            disablekb: 1,
            iv_load_policy: 3,
            enablejsapi: 1
          },
          onReady: function onReady() {
            _this3.set('player', player);
            _this3.get('mediaManager').add(player);
            resolve(player);
          },
          onStart: function onStart() {
            player.__proto__.onStart.call(player);
            _this3.get('mediaManager').pauseAllExcept(player);
          },
          onResume: function onResume() {
            player.__proto__.onResume.call(player);
            _this3.get('mediaManager').pauseAllExcept(player);
          }
        });
      });
    },
    updateView: function updateView() {
      var _this4 = this;

      this.set('view.duration', this.get('player.totalPlayed'));
      return this.get('view').save().catch(function (error) {
        if (error instanceof _emberData.default.UnauthorizedError) {
          _this4.get('session').open().then(function () {
            _this4.updateView();
          });
        }
      });
    },
    playerReset: function playerReset() {
      this.set('view', null);
      if (!this.get('isYoutubeVideoGroup')) {
        this.setupPlayer();
      }
    },
    removePlayer: function removePlayer() {
      this.get('mediaManager').destroy(this.get('player'));
      this.set('player', null);
    }
  });
});