define('tunespeak-ember/components/contest-actions/spotify-song-preview', ['exports', 'tunespeak-ember/mixins/components/spotify-song-preview-player', 'tunespeak-ember/mixins/components/spotify-action'], function (exports, _spotifySongPreviewPlayer, _spotifyAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_spotifySongPreviewPlayer.default, _spotifyAction.default, {
    classNames: ['spotify-song-preview', 'ts-list-item', 'campaign-action-item', 'spotify-action-item'],
    isFinished: Ember.computed.or('complete', 'player.isFinished'),
    trackUrl: Ember.computed.alias('model.content.spotify_preview_url'),

    actions: {
      previewComplete: function previewComplete() {
        var _this = this;

        var preview = this.get('store').createRecord('song-preview', {
          contestId: this.get('contest.id'),
          promotionId: this.get('promotionId'),
          bandId: this.get('bandId'),
          songId: this.get('itemId'),
          contestActionId: this.get('model.id')
        });

        this.set('userAction.completed', true);
        preview.save().then(function () {
          _this.get("googleTracking")("song-preview-complete", {
            category: "campaign-action",
            label: _this.get("title")
          });
        });
      }
    }
  });
});