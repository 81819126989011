define('tunespeak-ember/components/google-recaptcha', ['exports', '@tunespeak/tunespeak-elements/components/google-recaptcha'], function (exports, _googleRecaptcha) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _googleRecaptcha.default;
    }
  });
});