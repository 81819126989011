define('tunespeak-ember/components/redemption-question/radio', ['exports', 'tunespeak-ember/components/redemption-question/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    validate: function validate() {
      this._super();
      if (this.get('model.label') === 'able to attend') {
        this.set('shouldDecline', false);
        if (this.get('model.answer') === 'no') {
          this.get('errors').pushObject('You must attend the show to redeem your prize. Please decline if you cannot attend.');
          this.set('shouldDecline', true);
        }
      }
    },


    actions: {
      didFocus: function didFocus() {
        this.delegateFocus();
      }
    }
  });
});