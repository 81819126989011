define('tunespeak-ember/routes/campaign-ad', ['exports', 'tunespeak-ember/mixins/ts-route', 'tunespeak-ember/mixins/routes/full-screen', 'tunespeak-ember/mixins/ts-transparent-route'], function (exports, _tsRoute, _fullScreen, _tsTransparentRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRoute.default, _fullScreen.default, _tsTransparentRoute.default, {
    model: function model(params) {
      return this.get('store').findRecord('campaign-ad', params.id);
    }
  });
});