define('tunespeak-ember/controllers/presale-faq', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    loginService: Ember.computed('model.tmVerifiedFan', function () {
      return this.get('model.tmVerifiedFan') ? 'ticketmaster' : null;
    }),
    showMenuAlways: Ember.computed.notEmpty('model.groupId'),
    backgroundImage: Ember.computed.alias('model.backgroundPhoto'),
    application: Ember.inject.controller(),
    emShowImage: Ember.computed.alias('application.emShowImage'),
    emShowTitle: Ember.computed.alias('application.emShowTitle')
  });
});