define('tunespeak-ember/adapters/application', ['exports', 'active-model-adapter', 'tunespeak-ember/config/environment'], function (exports, _activeModelAdapter, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.default.extend({
    host: _environment.default.APP.API_ROOT,

    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    handleResponse: function handleResponse(status, headers, payload) {
      var error = this._super.apply(this, arguments);
      if (payload && payload.code) {
        error.code = payload.code;
      }
      if (payload && payload.message) {
        error.description = payload.message;
      }
      return error;
    }
  });
});