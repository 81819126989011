define('tunespeak-ember/controllers/redemption/decline', ['exports', 'tunespeak-ember/mixins/controllers/redemption/affidavit', 'ember-data'], function (exports, _affidavit, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validationMessage = "Please fix errors with your information and try again.";

  exports.default = Ember.Controller.extend(_affidavit.default, {
    requireAddress: false,
    releasedParties: Ember.computed('model.releasedParties', function () {
      var rp = this.get('model.releasedParties');
      return rp ? ', ' + rp + ',' : '';
    }),

    actions: {
      submit: function submit() {
        var _this = this;

        if (!this.get('canSubmit')) {
          return;
        }

        if (this.validate()) {
          var name = this.name,
              age = this.age,
              state = this.state,
              email = this.email,
              phone = this.phone,
              signature = this.signature;

          this.get('model.affidavit').setProperties({
            name: name,
            age: age,
            state: state,
            email: email,
            phone: phone,
            signature: signature
          });
          this.set('isSubmitting', true);
          this.get('model').setProperties({
            decline: true,
            submitQuestions: false,
            submitAffidavit: true
          });
          this.get('model').save().catch(function (error) {
            var msg = 'An error occurred, please try again or contact support@tunespeak.com';
            if (error instanceof _emberData.default.InvalidError) {
              msg = validationMessage;
            } else if (error.description) {
              msg = error.description;
            }
            _this.get('flash').danger(msg, {
              timeout: 5000,
              extendedTimeout: 500
            });
          }).finally(function () {
            _this.set('isSubmitting', false);
          });
        } else {
          this.get('flash').danger(validationMessage, {
            timeout: 5000,
            extendedTimeout: 500
          });
        }
      },
      submitNoAffidavit: function submitNoAffidavit() {
        var _this2 = this;

        this.set('isSubmitting', true);
        this.get('model').setProperties({
          decline: true,
          submitQuestions: false,
          submitAffidavit: false
        });
        this.get('model').save().finally(function () {
          _this2.set('isSubmitting', false);
        });
      }
    }
  });
});