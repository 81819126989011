define('tunespeak-ember/mixins/routes/no-header', ['exports', '@tunespeak/tunespeak-elements/mixins/routes/no-header'], function (exports, _noHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _noHeader.default;
    }
  });
});