define('tunespeak-ember/components/contest-actions/product-purchase', ['exports', 'tunespeak-ember/mixins/components/campaign-action'], function (exports, _campaignAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignAction.default, {
    layoutName: Ember.computed('model.groupTemplate', function () {
      var template = this.get('model.groupTemplate') === 'purchase-container' ? 'product-purchase-slide' : 'product-purchase-item';
      return 'components/contest-actions/' + template;
    }),
    classNames: ['product-purchase', 'campaign-action-item', 'spotify-action-item', 'ts-list-item'],
    href: Ember.computed('model.content.link', 'contest.id', 'promotionId', 'variantId', function () {
      var params = {
        contest_action_id: this.get('model.id')
      },
          contestId = this.get('contest.id'),
          variantId = this.get('variantId'),
          promotionId = this.get('promotionId');

      if (contestId) {
        params['contest_id'] = contestId;
      }
      if (promotionId) {
        params['promotion_id'] = promotionId;
      }
      if (variantId) {
        params['variant'] = variantId;
      }

      return this.get('model.content.link') + '?' + Ember.$.param(params);
    }),
    hasMultipleVariants: Ember.computed.gt('model.content.variants.length', 1),
    variants: Ember.computed('model.content.variants', function () {
      if (this.get('hasMultipleVariants')) {
        return this.get('model.content.variants').map(function (variant) {
          return {
            id: variant.ext_id,
            label: variant.title + ' - $' + variant.price
          };
        });
      }
    }),
    variantId: Ember.computed('hasMultipleVariants', function () {
      return this.get('hasMultipleVariants') ? null : this.get('model.content.variants.firstObject.ext_id');
    }),
    variant: Ember.computed('variantId', function () {
      var _this = this;

      if (this.get('variantId')) {
        return this.get('model.content.variants').find(function (v) {
          return v.ext_id === _this.get('variantId');
        });
      }
    }),
    noVariantSelected: Ember.computed('variants', 'variantId', function () {
      return this.get('variants') && !this.get('variantId');
    }),
    price: Ember.computed('model.content.price', 'variant', function () {
      return this.get('model.content.price') || (this.get('model.content.variants.length') === 1 ? this.get('variant.price') : null);
    }),
    productPhoto: Ember.computed('photo', 'variant', function () {
      var v = this.get('variant');
      return v && v.photo ? v.photo : this.get('photo');
    }),
    click: function click() {
      this.get('googleTracking')('product-purchase-click', {
        category: 'campaign-action',
        label: this.get('title')
      });
    },

    actions: {
      didEarnCredit: function didEarnCredit() {
        var _this2 = this;

        this.get('store').findRecord('user-contest-action', this.get('userAction.id'), {
          reload: true,
          adapterOptions: { contestId: this.get('contest.id') }
        }).then(function (record) {
          _this2.set('userAction', record);
        });
      }
    }
  });
});