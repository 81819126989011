define('tunespeak-ember/components/redemption-question/base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    errors: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('errors', []);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('parentView').set('formComponent', this);
    },
    validate: function validate() {
      this.get('errors').clear();
      if (Ember.isBlank(this.get('model.answer'))) {
        this.get('errors').pushObject('Answer is required.');
      }
    },


    isValid: Ember.computed('model.answer', function () {
      this.validate();
      return this.get('errors.length') === 0;
    }),

    delegateFocus: function delegateFocus() {
      try {
        this.$('input, textarea, select').not('.focus-delegate').first().focus();
      } catch (e) {
        // ignore errors
      }
    },


    actions: {
      focusIn: function focusIn() {
        this.get('focusIn')();
      },
      focusOut: function focusOut() {
        this.get('focusOut')();
      }
    }
  });
});