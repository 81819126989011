define('tunespeak-ember/models/setlist', ['exports', 'ember-data', 'tunespeak-ember/mixins/models/shareable'], function (exports, _emberData, _shareable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_shareable.default, {
    name: _emberData.default.attr('string'),
    userId: _emberData.default.attr('string'),
    contestId: _emberData.default.attr('string'),
    setlistCallId: _emberData.default.attr('string'),
    songs: _emberData.default.attr(),
    promotionId: _emberData.default.attr('string'),
    contestActionId: _emberData.default.attr('string'),
    shortUrl: _emberData.default.attr('string'),
    spotifyPlaylistUrl: _emberData.default.attr('string'),
    backgroundPhoto: _emberData.default.attr('string'),
    photo: _emberData.default.attr('string'),
    logo: _emberData.default.attr('string'),
    tweetText: _emberData.default.attr('string'),
    smsBody: _emberData.default.attr('string'),
    emailBody: _emberData.default.attr('string'),
    bandName: _emberData.default.attr('string'),
    contestPreview: _emberData.default.belongsTo('contest-preview', { async: false }),
    contestGroupPreview: _emberData.default.belongsTo('contest-group-preview', { async: false }),

    createSpotifyPlaylist: function createSpotifyPlaylist() {
      var _this = this;

      var adapter = Ember.getOwner(this).lookup('adapter:setlist');
      return adapter.ajax(adapter.urlForCreateSpotifyPlaylist(this.id), 'POST').then(function (model) {
        _this.store.pushPayload(model);
      });
    }
  });
});