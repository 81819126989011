define('tunespeak-ember/models/in-venue-rsvp', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    inVenueId: _emberData.default.attr('string'),
    checkedIn: _emberData.default.attr('boolean'),
    lat: _emberData.default.attr('string'),
    lng: _emberData.default.attr('string'),
    shortUrl: _emberData.default.attr('string'),
    promotionId: _emberData.default.attr('string')
  });
});