define('tunespeak-ember/routes/user-video', ['exports', 'tunespeak-ember/config/environment', 'tunespeak-ember/mixins/ts-route', 'tunespeak-ember/mixins/routes/shareable-meta', 'tunespeak-ember/mixins/promotion'], function (exports, _environment, _tsRoute, _shareableMeta, _promotion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRoute.default, _shareableMeta.default, _promotion.default, {
    ajax: Ember.inject.service(),
    model: function model(params) {
      var votedUrl = _environment.default.APP.API_ROOT + '/user_videos/' + params.id + '/vote';
      this.set('applicationLayout', 'whitelabel');
      return Ember.RSVP.hash({
        userVideo: this.store.findRecord('user-video', params.id),
        voted: this.get('session.isAuthenticated') ? this.get('ajax').request(votedUrl).catch(function () {
          return null;
        }) : null
      });
    },
    afterModel: function afterModel(hash, transition) {
      var _this = this;

      var promotionId = transition.queryParams.p;
      this.setShareableMetaTags(hash.userVideo);
      var gif = hash.userVideo.get('gifUrl');
      if (gif) {
        var mp4 = gif.replace('.gif', '.mp4');
        this.get('meta')['property']['og:video'] = mp4.replace('https://', 'http://');
        this.get('meta')['property']['og:video:secure_url'] = mp4;
        this.get('meta')['property']['og:video:type'] = 'video/mp4';
        this.get('meta')['property']['og:video:width'] = '640';
        this.get('meta')['property']['og:video:height'] = '480';
      }

      if (promotionId) {
        this.get('store').findRecord('promotion', promotionId).then(function (promotion) {
          var campaign = hash.userVideo.get('contestPreview');
          if (_this.get('session.currentUser.id') !== promotion.get('promoterId')) {
            _this.get('session').setLastPromotion(promotionId);
            _this.get('session').setPromotionFor(campaign.get('groupId') || campaign.get('id'), promotionId);
            _this.trackPromotionClick(promotionId);
          }
        });
      }
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash.userVideo);
      controller.set('voted', hash.voted);
      controller.set('isVoting', false);
      if (this.get('session.isAuthenticated')) {
        this.fetchUserContest(hash.userVideo.get('contestPreview.id')).then(function (userContest) {
          controller.set('userContest', userContest);
          controller.checkShouldVote();
        });
      }
    },
    fetchUserContest: function fetchUserContest(contestId) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var userContest = _this2.store.peekAll('user-contest').findBy('contestId', contestId);
        if (userContest) {
          resolve(userContest);
        } else {
          _this2.store.queryRecord('user-contest', { contest_id: contestId }).then(function (uc) {
            resolve(uc);
          }).catch(function () {
            resolve(null);
          });
        }
      });
    }
  });
});