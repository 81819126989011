define('tunespeak-ember/components/share/fb-messenger-share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    embeddable: Ember.inject.service(),
    isEmbedded: false, // from parent
    sharing: false,
    isMobile: Ember.computed(function () {
      return isMobile.any;
    }),
    actions: {
      sendFacebook: function sendFacebook() {
        var _this = this;

        this.get("googleTracking")("facebook-messenger-share", {
          category: "share",
          label: this.get("contest.title")
        });

        if (this.get('isEmbedded')) {
          this.set('sharing', true);
          this.get('embeddable').goFullScreen();
        }

        FB.ui({
          display: 'popup',
          method: 'send',
          link: this.get('shareLink')
        }, function () {
          if (_this.get('isEmbedded')) {
            _this.get('embeddable').exitFullScreen();
            _this.set('sharing', false);
          }
        });
      }
    }
  });
});