define('tunespeak-ember/components/redemption-question/tel', ['exports', 'tunespeak-ember/components/redemption-question/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    defaultCountry: 'us',
    onlyCountries: null,
    isValidNumber: true,
    value: Ember.computed.oneWay('model.answer'),

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        onlyCountries: this.get('onlyCountries') || ['us', 'ca', 'gb', 'fr', 'de', 'es', 'mx', 'ar', 'au']
      });
    },
    validate: function validate() {
      this._super();
      if (this.get('errors.length')) {
        return;
      }

      if (!this.get('isValidNumber')) {
        this.get('errors').pushObject('Invalid phone number.');
      }
    }
  });
});