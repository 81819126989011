define('tunespeak-ember/models/campaign', ['exports', 'ember-data', 'tunespeak-ember/mixins/models/shareable', 'tunespeak-ember/helpers/ts-oxford-array'], function (exports, _emberData, _shareable, _tsOxfordArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _emberData.default.Model.extend(_shareable.default, {
    title: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    numAvailable: _emberData.default.attr('number'),
    terms: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    subtitle: _emberData.default.attr('string'),
    groupId: _emberData.default.attr('string'),
    group: _emberData.default.belongsTo('contest-group', { async: true }),
    show: _emberData.default.belongsTo('show', { async: false }),
    startsAt: _emberData.default.attr('date'),
    endsAt: _emberData.default.attr('date'),
    leaderboard: _emberData.default.attr(),
    url: _emberData.default.attr('string'),
    thumbnail: _emberData.default.attr('string'),
    photo: _emberData.default.attr('string'),
    backgroundPhoto: _emberData.default.attr('string'),
    headerPhoto: _emberData.default.attr('string'),
    headerBgPhoto: _emberData.default.attr('string'),
    grandPrizePackage: _emberData.default.attr(),
    prizePackages: _emberData.default.attr(),
    bandId: _emberData.default.attr('string'),
    canceled: _emberData.default.attr('boolean'),
    ended: _emberData.default.attr('boolean'),
    started: _emberData.default.attr('boolean'),
    finalized: _emberData.default.attr('boolean'),
    template: _emberData.default.attr('string'),
    layout: _emberData.default.attr('string'),
    contestActions: _emberData.default.hasMany('contest-action', { async: false }),
    contestBands: _emberData.default.hasMany('contest-band', { async: false }),
    demo: _emberData.default.attr('boolean'),
    themeCss: _emberData.default.attr('string'),
    presaleUrl: _emberData.default.attr('string'),
    presaleStartsAt: _emberData.default.attr('date'),
    presaleEndsAt: _emberData.default.attr('date'),
    onSaleAt: _emberData.default.attr('date'),
    hasRules: _emberData.default.attr('boolean'),
    hasAds: _emberData.default.attr('boolean'),
    tmVerifiedFan: _emberData.default.attr('boolean'),
    embeddedUrl: _emberData.default.attr('string'),
    htmlSnippets: _emberData.default.hasMany('html-snippet', { async: false }),
    enrollmentConsentIds: _emberData.default.attr(),
    groupName: _emberData.default.attr('string'),
    rulesPageUrl: _emberData.default.attr('string'),
    rulesWebUrl: _emberData.default.attr('string'),
    maxIndirectFactor: _emberData.default.attr('number'),
    geoNearDistance: _emberData.default.attr('number'),
    recordLabelName: _emberData.default.attr('string'),
    recordLabelPrivacyPolicy: _emberData.default.attr('string'),
    recordLabelParent: _emberData.default.attr('string'),
    recordLabelAeWidgetId: _emberData.default.attr('string'),
    aeSegmentId: _emberData.default.attr('string'),
    requirePhone: _emberData.default.attr('boolean'),
    inVenueId: _emberData.default.attr('string'),
    allowedCountries: _emberData.default.attr(),
    warnings: _emberData.default.attr(),
    enrollmentsCount: _emberData.default.attr('number'),
    directEnrollmentPoints: _emberData.default.attr('number'),
    indirectEnrollmentPoints: _emberData.default.attr('number'),
    brand: _emberData.default.attr('string'),
    customUrl: _emberData.default.attr('string'),
    requireVerifiedEmail: _emberData.default.attr('boolean'),
    useAeService: _emberData.default.attr('boolean'),
    enrollmentLock: _emberData.default.belongsTo('lock', { async: false }),
    revealLocks: _emberData.default.hasMany('lock', { async: false }),

    isSony: Ember.computed.equal('recordLabelParent', 'sme'),
    isUniversal: Ember.computed.equal('recordLabelParent', 'umg'),
    isWarner: Ember.computed.equal('recordLabelParent', 'wmg'),
    isAeg: Ember.computed.equal('recordLabelParent', 'aeg'),

    inUK: Ember.computed('allowedCountries', function () {
      return this.get('allowedCountries').includes('GB');
    }),
    inUS: Ember.computed('allowedCountries', function () {
      return this.get('allowedCountries').includes('US');
    }),
    inCA: Ember.computed('allowedCountries', function () {
      return this.get('allowedCountries').includes('CA');
    }),

    creditsName: Ember.computed('type', function () {
      return this.get('type') === 'Raffle' ? 'entries' : 'points';
    }),
    creditName: Ember.computed('type', function () {
      return this.get('type') === 'Raffle' ? 'entry' : 'point';
    }),

    bandNames: Ember.computed('contestBands', function () {
      var names = this.get('contestBands').mapBy('name');
      return (0, _tsOxfordArray.tsOxfordArray)(names);
    }),

    creditableActions: Ember.computed('contestActions.@each.pointValue', function () {
      return this.get('contestActions').filter(function (ca) {
        return ca.get('pointValue') > 0;
      });
    }),

    minIndirectFactor: Ember.computed('contestActions.@each.indirectFactor', function () {
      return Math.min.apply(Math, _toConsumableArray(this.get('creditableActions').mapBy('indirectFactor').compact()));
    }),

    unifiedIndirectFactor: Ember.computed('minIndirectFactor', 'maxIndirectFactor', function () {
      return this.get('minIndirectFactor') === this.get('maxIndirectFactor');
    }),

    enterVerb: Ember.computed('template', function () {
      switch (this.get('template')) {
        case 'presale':
          return 'get in line';
        case 'deluxe-with-presale':
          return 'register';
        default:
          return 'enter';
      }
    }),

    totalPoints: _emberData.default.attr('number')
  });
});