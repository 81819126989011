define('tunespeak-ember/helpers/ts-clock-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tsClockString = tsClockString;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function padLeft(integer) {
    return integer < 10 ? '0' + integer : integer;
  }

  function tsClockString(_ref) /*, hash*/{
    var _ref2 = _slicedToArray(_ref, 2),
        time = _ref2[0],
        unit = _ref2[1];

    var ms = unit !== 'ms' ? time * 1000 : time,
        duration = moment.duration(ms, 'milliseconds'),
        min = Math.floor(duration.asMinutes()),
        sec = duration.seconds();
    return min + ':' + padLeft(sec);
  }

  exports.default = Ember.Helper.helper(tsClockString);
});