define('tunespeak-ember/routes/conversation', ['exports', 'tunespeak-ember/mixins/ts-route-requires-login', 'tunespeak-ember/mixins/ts-route', 'tunespeak-ember/mixins/routes/ts-route-requires-activation'], function (exports, _tsRouteRequiresLogin, _tsRoute, _tsRouteRequiresActivation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRouteRequiresLogin.default, _tsRoute.default, _tsRouteRequiresActivation.default, {
    model: function model(params) {
      return this.get('store').findRecord('conversation', params.id);
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.send('reloadMessages');
    },

    // This route needs to have 100% of the viewport, remove on leaving
    activate: function activate() {
      var _this = this;

      Ember.$('html').addClass('full-height');

      var convo = this.modelFor('conversation');

      convo.on('didAddMessage', function (message) {
        if (!message.senderIsSameAs(_this.get('session.currentUser'))) {
          _this.controller.send('markAsRead');
        }
      });
    },

    deactivate: function deactivate() {
      Ember.$('html').removeClass('full-height');
      this.modelFor('conversation').off('didAddMessage');
    }
  });
});