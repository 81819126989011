define('tunespeak-ember/utils/jw-player', ['exports', '@tunespeak/tunespeak-elements/utils/jw-player'], function (exports, _jwPlayer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _jwPlayer.default;
    }
  });
});