define('tunespeak-ember/components/spotify-search', ['exports', 'ember-ajax/errors'], function (exports, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['spotify-search'],
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    spotify: Ember.inject.service(),

    search: '',
    previousSearch: '',
    results: null,
    noResults: false,
    modalOpen: false,
    needsReauth: false,
    loading: false, //Fetching from Spotify or API

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        'results': []
      });
    },


    //Pagination
    total: 0,
    hasMore: Ember.computed('results.length', 'total', function () {
      return this.get('results.length') < this.get('total');
    }),
    isLoadingMore: false,
    nextUrl: null, //pagination for search results
    nextAnswersUrl: null, //pagination for answers
    hasMoreAnswers: true, //set by response of answers

    _handleError: function _handleError(error) {
      if ((0, _errors.isUnauthorizedError)(error)) {
        this.set('needsReauth', true);
        return;
      }
      throw error;
    },


    actions: {
      openModal: function openModal() {
        this.set('modalOpen', true);
      },
      closeModal: function closeModal() {
        this.set('modalOpen', false);
      },
      clearInput: function clearInput() {
        this.setProperties({
          'search': '',
          'previousSearch': '',
          'total': 0,
          'results': [],
          'noResults': false
        });
      },
      search: function search() {
        var _this = this;

        var search = this.get('search');
        if (search.trim().toLowerCase() === this.get('previousSearch')) {
          return;
        }

        this.set('loading', true);
        this.get('spotify').fetch('https://api.spotify.com/v1/search', {
          data: {
            q: search,
            market: 'US',
            type: 'track'
          }
        }).then(function (response) {
          _this.setProperties({
            'nextUrl': response.tracks.next,
            'total': response.tracks.total,
            'previousSearch': search
          });
          _this.get('results').clear().pushObjects(response.tracks.items);
          _this.set('noResults', !response.tracks.items.length);
        }).catch(function (error) {
          _this._handleError(error);
        }).finally(function () {
          _this.set('loading', false);
        });
      },
      fetchNextSearch: function fetchNextSearch() {
        var _this2 = this;

        if (!this.get('isLoadingMore')) {
          this.set('isLoadingMore', true);
          this.get('spotify').fetch(this.get('nextUrl')).then(function (response) {
            _this2.setProperties({
              'nextUrl': response.tracks.next,
              'total': response.tracks.total
            });
            _this2.get('results').pushObjects(response.tracks.items);
          }).catch(function (error) {
            _this2._handleError(error);
          }).finally(function () {
            _this2.set('isLoadingMore', false);
          });
        }
      },
      authenticateSpotify: function authenticateSpotify() {
        var _this3 = this;

        this.get('session').requireService('spotify', true).then(function () {
          _this3.set('needsReauth', false);
          _this3.send('search');
        }).catch();
      }
    }
  });
});