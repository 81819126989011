define("tunespeak-ember/routes/redemption-contact", ["exports", "tunespeak-ember/mixins/ts-route"], function (exports, _tsRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRoute.default, {
    model: function model(params, transition) {
      var _this = this;

      return this.store.findRecord("redemption", params.id, {
        adapterOptions: {
          token: transition.queryParams.token
        }
      }).catch(function (error) {
        if (error.code === 403) {
          transition.abort();
          if (_this.get("session.currentUser")) {
            window.location = "/404";
          } else {
            _this.transitionTo("/login?redirect=" + _this.get("session").encodeRouteInfo());
          }
        }
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties({
        onlyCountries: this.get("onlyCountries") || ["us", "ca"],
        preferredCountries: this.get("preferredCountries") || []
      });
    }
  });
});