define('tunespeak-ember/routes/404', ['exports', '@tunespeak/tunespeak-elements/routes/404'], function (exports, _) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _.default;
    }
  });
});