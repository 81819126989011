define('tunespeak-ember/components/photo-thumbnail', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['photo-thumbnail'],
    index: null,
    userAction: null,
    photo: null,
    userPhotoLook: Ember.computed('userAction.remainingContent', 'photo.id', function () {
      if (this.get('userAction.remainingContent')) {
        return this.get('userAction.remainingContent').findBy('id', this.get('photo.id'));
      }
      return null;
    }),
    viewed: Ember.computed.lte('userPhotoLook.remaining', 0),
    actions: {
      showPhotoViewer: function showPhotoViewer() {
        this.get('showPhotoViewer')(this.get('index'));
      }
    }
  });
});