define('tunespeak-ember/controllers/inbox', ['exports', 'tunespeak-ember/mixins/ts-inline-search'], function (exports, _tsInlineSearch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_tsInlineSearch.default, {
    backgroundImage: '/assets/images/crowd-bg.png',
    sortProperties: Ember.A(['latestMessageSentAt:desc']),
    items: Ember.computed.sort('model', 'sortProperties'),
    inlineSearchCollection: Ember.computed.alias('items'),

    getInlineSearchCriteria: function getInlineSearchCriteria(item) {
      return item.get('participantNames');
    }
  });
});