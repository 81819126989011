define('tunespeak-ember/helpers/ts-format-date', ['exports', '@tunespeak/tunespeak-elements/helpers/ts-format-date'], function (exports, _tsFormatDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tsFormatDate.default;
    }
  });
  Object.defineProperty(exports, 'tsFormatDate', {
    enumerable: true,
    get: function () {
      return _tsFormatDate.tsFormatDate;
    }
  });
});