define('tunespeak-ember/routes/setlist', ['exports', 'tunespeak-ember/mixins/ts-route', 'tunespeak-ember/mixins/routes/full-screen', 'tunespeak-ember/mixins/routes/shareable-meta', 'tunespeak-ember/mixins/routes/inline-navbar'], function (exports, _tsRoute, _fullScreen, _shareableMeta, _inlineNavbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  WebFont.load({
    google: {
      families: ['Permanent Marker']
    }
  });

  exports.default = Ember.Route.extend(_tsRoute.default, _fullScreen.default, _shareableMeta.default, _inlineNavbar.default, {
    model: function model(params) {
      return this.store.findRecord('setlist', params.id);
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      var promotionId = transition.queryParams.p;
      var preview = model.get('contestGroupPreview') || model.get('contestPreview');
      if (promotionId) {
        this.get('store').findRecord('promotion', promotionId).then(function (promotion) {
          if (_this.get('session.currentUser.id') !== promotion.get('promoterId')) {
            _this.get('session').setLastPromotion(promotionId);
            _this.get('session').setPromotionFor(model.get('contestPreview.groupId') || model.get('contestPreview.id'), promotionId);
            _this.trackPromotionClick(promotionId);
          }
        }).catch(function () {});
      }
      this.setShareableMetaTags(preview);
      this.addMetaInfo(model, preview);
    },
    addMetaInfo: function addMetaInfo(model, preview) {
      var title = model.get('ogTitle');
      var description = model.get('ogDescription');
      //if no photo, use default
      if (!preview.get('shareableSocialPhoto')) {
        var photo = 'https://s3.amazonaws.com/tunespeak-cms/images/default-setlist-share-image.jpg';
        this.get('meta')['property']['og:image'] = photo;
        this.get('meta')['name']['twitter:image:src'] = photo;
      }
      this.get('meta')['property']['og:title'] = title;
      this.get('meta')['name']['twitter:title'] = title;
      this.get('meta')['property']['og:description'] = description;
      this.get('meta')['name']['twitter:description'] = description;
    }
  });
});