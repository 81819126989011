define('tunespeak-ember/routes/error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    headData: Ember.inject.service(),
    set404MetaTags: function set404MetaTags() {
      this.get('headData').set('headTags', [{
        type: 'meta',
        tagId: 'prerender-status-code',
        attrs: {
          name: 'prerender-status-code',
          content: '404'
        }
      }]);
    },
    setupController: function setupController(controller, error) {
      this._super.apply(this, arguments);
      if (error && error.errors && error.errors[0]) {
        if (error.errors[0].status === '404') {
          this.set404MetaTags();
        }
      }
    }
  });
});