define('tunespeak-ember/controllers/invenue/campaign', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    enrollment: Ember.inject.service(),
    flash: Ember.inject.service("flashMessages"),
    invenue: Ember.computed.alias("model.parent.inVenue"),
    campaign: Ember.computed.alias("model.campaign"),
    userContest: Ember.computed.alias("model.userContest"),
    userProfile: Ember.computed.alias("session.currentUser.profile"),
    showPhoneInput: false,
    actions: {
      googleTracking: function googleTracking(eventName, options) {
        options.artist = this.get("campaign.bandNames");
        options.contest = this.get("campaign.id");
        if (!options.label) options.label = this.get("campaign.title");
        gtag("event", eventName, options);
      },
      enroll: function enroll() {
        var _this = this;

        if (!this.get("enrolled")) {
          if (
          /*this.get('campaign.requirePhone') && */!this.get("userProfile.phone")) {
            this.set("showPhoneInput", true);
            return;
          }

          this.set("isEnrolling", true);

          this.send("googleTracking", "enrollment", {
            label: this.get("campaign.title"),
            category: "in-venue"
          });

          var contestId = this.get("campaign.id");
          // set id in storage to try to auto enroll after login completes
          this.get("storage").set("autoEnroll", contestId);
          // add 'enroll=true' to redirectURL for 2nd auto enroll condition
          var search = window.location.search.indexOf("?") === -1 ? "?enroll=true" : window.location.search + '&enroll=true';
          var options = {
            id: contestId,
            enrollmentConsentIds: this.get("campaign.enrollmentConsentIds"),
            redirectURL: '/campaign/' + contestId + search
          };

          this.get("enrollment").enroll(options).then(function (userContest) {
            _this.send("enrollmentFinished", userContest);
          }).catch(function () {
            // don't throw error
          }).finally(function () {
            _this.set("isEnrolling", false);
          });
        }
      },
      enrollmentFinished: function enrollmentFinished(userContest) {
        // remove auto enroll id
        this.get("storage").set("autoEnroll", null);

        if (!this.get("userContest")) {
          this.set("userContest", userContest);
          this.send("googleTracking", "enrollment-complete", {
            label: this.get("campaign.title"),
            category: "in-venue"
          });
        }
      },
      onPhoneSave: function onPhoneSave() {
        this.set("showPhoneInput", false);
        this.send("enroll");
      },
      onPhoneSaveError: function onPhoneSaveError(error) {
        var message = "An error occurred";
        if (error instanceof _emberData.default.InvalidError) {
          message = "Please Enter a valid phone number";
        }
        this.get("flash").danger(message, {
          timeout: 5000,
          extendedTimeout: 500,
          destroyOnClick: false
        });
      }
    }
  });
});