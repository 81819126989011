define('tunespeak-ember/mixins/components/translate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    allowedCountries: null,
    attributeBindings: ['excludeTranslation:bablic-exclude'],
    excludeTranslation: Ember.computed('allowedCountries', function () {
      var countries = this.get('allowedCountries') || [];
      return !(countries.includes('FR') || countries.includes('DE') || countries.includes('ES'));
    })
  });
});