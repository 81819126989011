define('tunespeak-ember/components/redemption/affidavit-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sentence: Ember.computed('errors.[]', function () {
      var errors = this.get('errors');
      var last = errors.pop();
      var ret = [];
      if (errors.length) {
        ret.push(errors.mapBy('message').join(', '));
      }
      if (last) {
        ret.push(last.message);
      }
      return this.get('name') + ' ' + ret.join(' and ');
    })
  });
});