define('tunespeak-ember/mixins/promotion', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    cookie: Ember.inject.service(),
    trackPromotionClick: function trackPromotionClick(promotionId) {
      var _this = this;

      if (!this.get('storage').getRawCookie('tsc_' + promotionId)) {
        this.get('cookie.whenAccepted').then(function () {
          _this.get('store').createRecord('click', {
            promotionId: promotionId,
            refererUrl: document.referrer
          }).save();
        });
      }
    }
  });
});