define('tunespeak-ember/torii-providers/q', ['exports', '@tunespeak/tunespeak-elements/torii-providers/q'], function (exports, _q) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _q.default;
    }
  });
});