define('tunespeak-ember/routes/redemption/index', ['exports', 'tunespeak-ember/mixins/ts-route'], function (exports, _tsRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRoute.default, {
    alwaysResetScroll: true,
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setDefaults();
    }
  });
});