define('tunespeak-ember/components/apple-album-filter', ['exports', 'tunespeak-ember/components/album-filter'], function (exports, _albumFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _albumFilter.default.extend({
    matchingItems: Ember.computed('filter', 'albums.[]', function () {
      var filter = (this.get('filter') || '').toLowerCase().trim();
      return this.get('albums').map(function (album) {
        var tracks = album.relationships.tracks.data;
        var albumHasTracks = false;
        tracks.forEach(function (track) {
          if (track.attributes.name.toLowerCase().includes(filter)) {
            Ember.set(track, 'in_filter', true);
            albumHasTracks = true;
          } else {
            Ember.set(track, 'in_filter', false);
          }
        });
        Ember.setProperties(album, {
          in_filter: albumHasTracks,
          items: tracks
        });
        return album;
      });
    })
  });
});