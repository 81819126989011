define('tunespeak-ember/components/share-links', ['exports', 'tunespeak-ember/components/contest-actions/share'], function (exports, _share) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _share.default.extend({
    tweetText: null,
    smsBody: null,
    emailBody: null
  });
});