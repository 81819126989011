define('tunespeak-ember/components/redemption/affidavit-doc', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function escapeHtml(html) {
    var text = document.createTextNode(html);
    var div = document.createElement('div');
    div.appendChild(text);
    return div.innerHTML;
  }

  function replaceOrUnderline(string, key, value) {
    value = value ? value.trim() : null;
    var sub = value ? '**' + escapeHtml(value) + '**' : '**_(fill out below)_**';
    return string.replace('{{' + key + '}}', sub);
  }

  exports.default = Ember.Component.extend({
    tagName: 'p',
    classNames: ['affidavit-doc'],

    body: Ember.computed('template', 'age', 'state', function () {
      var t = this.get('template');
      t = replaceOrUnderline(t, 'age', this.get('age'), 2);
      t = replaceOrUnderline(t, 'state', this.get('state'), 8);
      return t;
    })
  });
});