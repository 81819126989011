define('tunespeak-ember/services/enrollment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    ticketmaster: Ember.inject.service(),
    requirePhone: Ember.inject.service(),
    requireConsents: Ember.inject.service(),
    enrollmentWarnings: Ember.inject.service(),
    embeddable: Ember.inject.service(),
    router: Ember.inject.service(),
    htmlSnippet: Ember.inject.service(),

    _enterUserInContest: function _enterUserInContest(_ref) {
      var _this = this;

      var id = _ref.id,
          presaleId = _ref.presaleId,
          presaleTextEnabled = _ref.presaleTextEnabled;

      var data = {
        contestId: id,
        promotionId: this.get('session').getLastPromotion()
      };
      if (presaleId || presaleTextEnabled) {
        var presaleReg = {};
        if (presaleId) {
          presaleReg.presale_id = presaleId;
        }
        if (presaleTextEnabled) {
          var profile = this.get('session.currentUser.profile');
          presaleReg.unverified_phone = profile.get('phone');
          presaleReg.unverified_country_code = profile.get('countryCode');
        }
        data.presaleRegistration = presaleReg;
      }

      var newUserContest = this.get('store').createRecord('user-contest', data);
      return newUserContest.save().then(function (userContest) {
        if (_this.get('embeddable.isEmbedded')) {
          _this.get('embeddable').triggerEvent('enroll', id);
        }
        _this.get('htmlSnippet').trigger('enroll', {
          user_id: _this.get('session.currentUser.id'),
          user_contest_id: userContest.id
        });
        return userContest;
      });
    },


    _switchUserToContest: function _switchUserToContest(userContest) {
      userContest.set('current', true);
      return userContest.save();
    },

    enroll: function enroll() {
      var _this2 = this;

      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var createUserContest = function createUserContest() {
        return _this2.get('store').queryRecord('user-contest', { contest_id: options.id }).catch(function () {
          return _this2.get('enrollmentWarnings').showWarnings(options).then(function () {
            return _this2.get('requireConsents').requireConsents(options).then(function () {
              if (!options.requireVerifiedEmail && _this2.get('session.currentUser.visitorId') || _this2.get('session.currentUser.profile.canEnroll')) {
                return _this2._enterUserInContest(options);
              } else {
                var route = '/login?redirect=' + _this2.get('session').encodeRouteInfo(options.redirectURL);
                if (options.brand) {
                  route += '&brand=' + options.brand;
                }
                _this2.get('router').transitionTo(route);
              }
            });
          });
        });
      };

      return this.get('session').requireLogin(options).then(function () {
        if (options.requirePhone) {
          var campaign = _this2.get('store').peekRecord('campaign', options.id) || _this2.get('store').peekRecord('contest-preview', options.id);
          return _this2.get('requirePhone').requirePhone({ onlyCountries: campaign.get('allowedCountries') }).then(function (textEnabled) {
            if (textEnabled) {
              options.presaleTextEnabled = true;
            }
            return createUserContest();
          });
        }
        return createUserContest();
      });
    },
    enrollInPresale: function enrollInPresale() {
      var _this3 = this;

      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return this.get('session').requireLogin(options).then(function () {
        var campaign = _this3.get('store').peekRecord('campaign', options.id) || _this3.get('store').peekRecord('contest-preview', options.id);
        return _this3.get('requirePhone').requirePhone({ allowSkip: false, onlyCountries: campaign.get('allowedCountries') }).then(function () {
          return _this3.get('store').queryRecord('user-contest', { contest_id: options.id }).then(function (userContest) {
            return _this3._switchUserToContest(userContest);
          }).catch(function () {
            return _this3.get('requireConsents').requireConsents(options).then(function () {
              if (_this3.get('session.currentUser.profile.canEnroll')) {
                return _this3._enterUserInContest(options);
              } else {
                _this3.get('router').transitionTo('/login?redirect=' + _this3.get('session').encodeRouteInfo(options.redirectURL));
              }
            });
          });
        });
      });
    }
  });
});