define('tunespeak-ember/routes/winners', ['exports', 'tunespeak-ember/mixins/ts-route', 'tunespeak-ember/mixins/no-robots'], function (exports, _tsRoute, _noRobots) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRoute.default, _noRobots.default, {
    model: function model(params) {
      var adapter = Ember.getOwner(this).lookup('adapter:campaign');
      return adapter.ajax(adapter.urlForWinners(params.campaign_id), 'GET').then(function (model) {
        model.contest_preview.contest_id = params.campaign_id;
        return model;
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('backgroundImage', '/assets/images/crowd-bg.png');
    }
  });
});