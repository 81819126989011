define('tunespeak-ember/torii-providers/google', ['exports', '@tunespeak/tunespeak-elements/torii-providers/google'], function (exports, _google) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _google.default;
    }
  });
});