define('tunespeak-ember/serializers/redemption', ['exports', 'active-model-adapter', 'ember-data'], function (exports, _activeModelAdapter, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      questions: { embedded: 'always' },
      affidavit: { embedded: 'always' },
      skilledQuestion: { embedded: 'always' }
    },

    serialize: function serialize(snapshot /*, options*/) {
      var json = this._super.apply(this, arguments);

      if (!snapshot.record.submitQuestions) {
        delete json.questions;
      }
      if (!snapshot.record.submitAffidavit) {
        delete json.affidavit;
      }

      return json;
    },


    // FROM: https://github.com/emberjs/data/issues/4273
    // Could possibly be put in application serializer if we need more nested errors
    extractErrors: function extractErrors(store, typeClass, payload, id) {
      var _this = this;

      payload = this._super.apply(this, arguments);

      var record = store.peekRecord(typeClass.modelName, id);
      var errorKeys = Object.keys(payload);

      typeClass.eachRelationship(function (name, descriptor) {
        var key = _this.keyForAttribute(name, 'deserialize');
        if (descriptor.kind === 'belongsTo') {
          var relatedModel = record.get(name);
          var errorRegex = new RegExp('^relationships/' + key + '/attributes/(.*)');

          errorKeys.forEach(function (errorKey) {
            var match = errorKey.match(errorRegex);
            if (match) {
              relatedModel.get('errors').add(Ember.String.camelize(match[1]), payload[match[0]]);
              relatedModel.set('isError', true);
              delete payload[match[0]];
            }
          });
        } else if (descriptor.kind === 'hasMany') {
          var _errorRegex = new RegExp('^relationships/' + key + '/(.*)/attributes/(.*)');

          errorKeys.forEach(function (errorKey) {
            var match = errorKey.match(_errorRegex);
            if (match) {
              var _relatedModel = store.peekRecord(descriptor.type, match[1]);
              if (_relatedModel) {
                _relatedModel.get('errors').add(Ember.String.camelize(match[2]), payload[match[0]]);
                _relatedModel.set('isError', true);
                delete payload[match[0]];
              }
            }
          });
        }
      });

      return payload;
    }
  });
});