define('tunespeak-ember/components/redemption-question/checkbox', ['exports', 'tunespeak-ember/components/redemption-question/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('checked', this.get('model.answer') || false);
    },

    checked: Ember.computed.alias('model.answer')
  });
});