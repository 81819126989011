define('tunespeak-ember/services/enrollment-warnings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var deferred = void 0;

  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    showModal: false,
    warnings: null,
    contestId: null,

    cancel: function cancel() {
      this.set('showModal', false);
      this.set('warnings', null);
      deferred.reject();
    },
    done: function done() {
      this.set('showModal', false);
      this.set('warnings', null);
      deferred.resolve();
    },
    showWarnings: function showWarnings() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      deferred = new Ember.RSVP.defer();
      if (options.enrollmentWarnings && options.enrollmentWarnings.length) {
        this.setProperties({
          warnings: options.enrollmentWarnings,
          contestId: options.id,
          showModal: true
        });
      } else {
        deferred.resolve();
      }
      return deferred.promise;
    }
  });
});