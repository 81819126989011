define('tunespeak-ember/utils/geolocation', ['exports', 'tunespeak-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getGeoLocation = getGeoLocation;
  function getGeoLocation() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 };

    return new Ember.RSVP.Promise(function (resolve, reject) {
      if (!navigator.geolocation) {
        reject({
          description: 'Geolocation is not supported in your browser'
        });
      }

      // geolocation API not available on insecure domains
      if (_environment.default.environment === 'development') {
        var pos = {
          coords: {
            accuracy: 709,
            altitude: null,
            altitudeAccuracy: null,
            heading: null,
            latitude: 38.621184,
            longitude: -90.19637759999999,
            speed: null
          }
        };
        resolve(pos);
      } else {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
      }
    });
  }
});