define('tunespeak-ember/components/user-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['user-dropdown'],
    isShowing: false,
    actions: {
      toggle: function toggle() {
        this.toggleProperty('isShowing');
      },
      logout: function logout() {
        this.sendAction('logout');
      }
    }
  });
});