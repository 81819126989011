define('tunespeak-ember/components/contest-actions/mailing-list-entry', ['exports', 'tunespeak-ember/mixins/components/campaign-action'], function (exports, _campaignAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignAction.default, {
    classNames: ['ts-list-item', 'campaign-action-item', 'mailing-list-entry'],
    session: Ember.inject.service(),
    localization: Ember.inject.service(),
    appreciationEngine: Ember.inject.service(),
    cookie: Ember.inject.service(),
    isModalOpen: false,
    ownerName: Ember.computed.alias('model.content.owner_name'),
    profile: Ember.computed.alias('session.currentUser.profile'),
    isUniversal: Ember.computed.equal('model.content.record_label_parent', 'umg'),
    isSony: Ember.computed.equal('model.content.record_label_parent', 'sme'),
    isWarner: Ember.computed.equal('model.content.record_label_parent', 'wmg'),
    isLocaleEN: Ember.computed.equal('localization.locale', 'en'),
    isLocaleFR: Ember.computed.equal('localization.locale', 'fr'),
    isLocaleDE: Ember.computed.equal('localization.locale', 'de'),
    isLocaleES: Ember.computed.equal('localization.locale', 'es'),

    countryAlpha2: null,
    umgPrivacyPolicy: Ember.computed('countryAlpha2', 'model.content.umg_form.privacy_policies', function () {
      var privacy_policies = this.get('model.content.umg_form.privacy_policies');
      var countryAlpha2 = this.get('countryAlpha2');
      if (countryAlpha2) {
        var selected = privacy_policies[countryAlpha2.toLowerCase()] && privacy_policies[countryAlpha2.toLowerCase()].html;
        return selected ? selected : this.get('model.content.umg_form.privacy_policies.default.html');
      }
      return this.get('model.content.umg_form.privacy_policies.default.html');
    }),
    joinDisabled: Ember.computed('countryAlpha2', 'model.content.umg_form.privacy_policies', function () {
      return this.get('model.content.umg_form.privacy_policies') && !this.get('countryAlpha2');
    }),

    _join: function _join(aeJWT) {
      var _this = this;

      if (this.get('working') || this.get('userAction.completed')) return;
      this.set('isModalOpen', false);
      this.set('working', true);
      this.get('store').createRecord('mailing-list-entry', {
        contestId: this.get('contest.id'),
        contestActionId: this.get('model.id'),
        promotionId: this.get('promotionId'),
        followableId: this.get('model.content.owner_id'),
        followableType: this.get('model.content.owner_type'),
        countryAlpha2: this.get('isUniversal') ? this.get('countryAlpha2') : null,
        aeJWT: aeJWT
      }).save().then(function () {
        _this.set('userAction.completed', true);
      }).finally(function () {
        _this.set('working', false);
      });
    },


    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('isModalOpen');
      },
      join: function join() {
        var _this2 = this;

        if (this.get('useAeService') && this.get('isSony')) {
          this.get('appreciationEngine').requireService('email', this.get('contest.id')).then(function (aeJWT) {
            _this2._join(aeJWT);
          });
        } else {
          this._join();
        }
      },
      handleChange: function handleChange(e) {
        this.set('countryAlpha2', e.target.value);
      }
    }
  });
});