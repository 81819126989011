define('tunespeak-ember/utils/cookie-intent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.handleExtTrackingRefCookie = handleExtTrackingRefCookie;
  exports.handleFollowOrSaveCookie = handleFollowOrSaveCookie;
  exports.handleFacebookLikeCookie = handleFacebookLikeCookie;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function handleExtTrackingRefCookie(cookie, controller) {
    if (cookie && cookie.intent === 'etr' && controller.get('contest.id') === cookie.contest_id) {
      controller.send('confirmSpotifyInstructions', true);
    }
  }

  function _sendCookieAction(cookie, component) {
    if (cookie.intent === 'follow') {
      component.send('follow');
    }
    if (cookie.intent === 'save') {
      component.send('save');
    }
    if (cookie.intent === 'add-to-playlist') {
      component.send.apply(component, ['addToPlaylist'].concat(_toConsumableArray(cookie.params)));
    }
    if (cookie.intent === 'save-setlist') {
      component.send.apply(component, ['saveSetlist'].concat(_toConsumableArray(cookie.params)));
    }
  }

  function handleFollowOrSaveCookie(cookie, actionId, component) {
    // if was trying to follow and is correct component
    if (cookie && cookie.action_id === actionId) {
      // if ae wait for aejs to load, then check if service exists before refiring
      if (component.get('useAeService')) {
        component.get('appreciationEngine').loaded().then(function () {
          if (component.get('appreciationEngine').getService(cookie.service).ID) {
            _sendCookieAction(cookie, component);
          } else {
            component.get('session.removeAuthCookie') && component.get('session').removeAuthCookie();
          }
        });
        // if not ae check service exists on session, before re-firing
      } else {
        if (component.get('session.currentUser.' + cookie.service) || component.get('session.currentUser.accounts').findBy('type', 'UserAccount::' + Ember.String.capitalize(cookie.service))) {
          _sendCookieAction(cookie, component);
        } else {
          component.get('session.removeAuthCookie') && component.get('session').removeAuthCookie();
        }
      }
    }
  }

  function handleFacebookLikeCookie(cookie, actionId, component) {
    //if was trying to like and is correct component
    if (cookie && cookie.action_id === actionId) {
      component.send('like');
    }
  }
});