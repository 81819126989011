define('tunespeak-ember/components/ts-conversation-list-item', ['exports', '@tunespeak/tunespeak-elements/components/ts-conversation-list-item'], function (exports, _tsConversationListItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tsConversationListItem.default;
    }
  });
});