define('tunespeak-ember/adapters/poll', ['exports', 'tunespeak-ember/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var host = this.host;
      var _snapshot$adapterOpti = snapshot.adapterOptions,
          contestId = _snapshot$adapterOpti.contestId,
          contestActionId = _snapshot$adapterOpti.contestActionId;

      return host + '/polls/' + id + '?contest_id=' + contestId + '&contest_action_id=' + contestActionId;
    }
  });
});