define('tunespeak-ember/models/user-contest', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    contestId: _emberData.default.attr('string'),
    promotionId: _emberData.default.attr('string'),
    redemptionId: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    points: _emberData.default.attr('number'),
    indirectPoints: _emberData.default.attr('number'),
    disqualified: _emberData.default.attr('boolean'),
    shortUrl: _emberData.default.attr('string'),
    actions: _emberData.default.hasMany('user-contest-action', { async: false }),
    extTrackingRef: _emberData.default.belongsTo('ext-tracking-ref', { async: false }),
    current: _emberData.default.attr('boolean'),
    presaleGroup: _emberData.default.attr('string'),
    presaleCode: _emberData.default.attr('string'),
    presaleCodeVisibleAt: _emberData.default.attr('date'),
    presaleSoldOut: _emberData.default.attr('boolean'),
    showTimeZone: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    winner: _emberData.default.attr('boolean'), //only for leaderboard
    place: _emberData.default.attr('number'),
    contestPreview: _emberData.default.belongsTo('contest-preview', { async: false }),
    isRaffle: Ember.computed.equal('type', 'UserRaffle'),
    isPresale: Ember.computed.equal('type', 'UserPresale'),
    presaleRegistration: _emberData.default.attr(),
    inVenueId: _emberData.default.attr(),
    enrollmentLock: _emberData.default.belongsTo('lock', { async: false }),
    enrollmentLockState: _emberData.default.attr('string'),

    // WARNING: ONLY works if contestPreview loaded
    isWinner: Ember.computed('contestPreview.ended', 'winner', 'place', 'isRaffle', function () {
      if (this.get('isRaffle')) {
        return !!this.get('place');
      } else {
        return this.get('winner') && this.get('contestPreview.ended');
      }
    }),
    isInLineForActivePresale: Ember.computed('contestPreview.endsAt', 'status', 'isPresale', function () {
      return this.get('isPresale') && this.get('status') === 'current' && moment().isBefore(moment(this.get('contestPreview.endsAt')).add(7, 'days'));
    })
  });
});