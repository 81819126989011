define('tunespeak-ember/components/contest-actions/soundcloud-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['soundcloud-group'],
    mediaManager: Ember.inject.service(),
    firstAction: Ember.computed.alias('contestActions.firstObject'),
    contestActions: Ember.computed.alias('model.contestActions'),
    soundcloudActionsDailyPointLimit: Ember.computed('contestActions', function () {
      return this.get('contestActions').reduce(function (acc, i) {
        if (i.get('preRelease')) {
          return acc;
        }
        return acc + i.get('totalDirectLimit') * i.get('pointValue');
      }, 0);
    }),
    actions: {
      playFirst: function playFirst() {
        var _this = this;

        var player = this.get('mediaManager.players').find(function (el) {
          return el.get('playerId') === 'player-' + _this.get('firstAction.id');
        });
        if (player) {
          this.get('mediaManager').play(player);
        }
      }
    }
  });
});