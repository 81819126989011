define('tunespeak-ember/mixins/components/campaign-action', ['exports', 'ember-data', 'tunespeak-ember/mixins/components/translate'], function (exports, _emberData, _translate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_translate.default, {
    model: null,
    contest: null,
    userContest: null,
    promotionId: null,
    justEarnedCredit: false,
    show401Modal: false,
    session: Ember.inject.service(),
    appreciationEngine: Ember.inject.service(),
    storage: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    useAeService: Ember.computed.alias('contest.useAeService'),
    bandName: Ember.computed.alias('model.content.band_name'),
    bandId: Ember.computed.alias('model.content.band_id'),
    photo: Ember.computed.alias('model.content.photo'),
    title: Ember.computed.alias('model.content.title'),
    itemId: Ember.computed.alias('model.content.id'),
    previewItemId: Ember.computed.alias('model.previewContent.id'),
    previewTitle: Ember.computed.alias('model.previewContent.title'),
    type: Ember.computed.alias('model.content.type'),
    complete: Ember.computed.alias('userAction.completed'),
    allowedCountries: Ember.computed.alias('contest.allowedCountries'),
    userAction: Ember.computed('userContest.actions', function () {
      if (this.get('userContest.actions')) {
        return this.get('userContest.actions').findBy('id', this.get('model.id'));
      } else {
        return null;
      }
    }),

    creditService: Ember.inject.service('credit'),
    init: function init() {
      this.get('creditService').on('didReceiveCredit', this, this.creditHandler);
      this._super.apply(this, arguments);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('creditService').off('didReceiveCredit', this, this.creditHandler);
    },
    creditHandler: function creditHandler(credit) {
      this.send('didReceiveCredit', credit);
    },
    handleSaveError: function handleSaveError(error) {
      if (error instanceof _emberData.default.UnauthorizedError) {
        this.set('show401Modal', true);
      }
    },
    saveAndRemoveAuthCookie: function saveAndRemoveAuthCookie(options) {
      var _this = this;

      options.record.save().then(function () {
        Ember.run.next(function () {
          _this.set('userAction.completed', true);
          if (_this.get('completedCallback')) _this.completedCallback();
        });
        _this.get('session').removeAuthCookie();
      }).catch(function (error) {
        _this.get('session').removeAuthCookie();
        _this.handleSaveError(error, options);
      }).finally(function () {
        _this.set('working', false);
      });
    },
    useAppreciationEngine: function useAppreciationEngine(options) {
      var _this2 = this;

      this.get('appreciationEngine').requireService(options.service, this.get('contest.id')).then(function (aeJWT) {
        if (aeJWT) {
          options.record.set('aeJWT', aeJWT); // Set JWT token on action models
        }
        _this2.saveAndRemoveAuthCookie(options);
      }).catch(function () {
        _this2.set('working', false);
        _this2.get('session').removeAuthCookie();
      });
    },
    useService: function useService(options) {
      var _this3 = this;

      // applemusic doesn't use torii login, skip it
      if (options.service === 'applemusic') {
        this.saveAndRemoveAuthCookie(options);
      } else {
        this.get('session').requireService(options.service).then(function () {
          _this3.saveAndRemoveAuthCookie(options);
        }).catch(function () {
          _this3.set('working', false);
        });
      }
    },


    actions: {
      didReceiveCredit: function didReceiveCredit(credit) {
        var _this4 = this;

        var creditContestActionId = credit.get('contestActionId'),
            contestActionId = this.get('model.id'),
            previewContestActionId = this.get('model.previewId');

        if (creditContestActionId && contestActionId && [contestActionId, previewContestActionId].includes(creditContestActionId)) {
          this.send('didEarnCredit', credit);
          this.set('justEarnedCredit', true);
          Ember.run.later(function () {
            try {
              _this4.set('justEarnedCredit', false);
            } catch (e) {
              //action might be gone
            }
          }, 1000);
        }
      },
      didEarnCredit: function didEarnCredit() /*credit*/{
        // Implement if desired if child class
      },
      saveRecord: function saveRecord(options) {
        if (this.get('working')) {
          return;
        }

        this.set('working', true);
        var sonyServicesAE = ['spotify', 'applemusic', 'twitter', 'youtube'];
        var useSonyAE = this.get('useAeService') && sonyServicesAE.includes(options.service);

        if (this.get('session.isEmbeddedWebView') || useSonyAE) {
          this.get('session').createAuthCookie({
            intent: options.intent,
            action_id: options.actionId,
            params: options.params,
            service: options.service
          });
        }

        if (useSonyAE) {
          this.useAppreciationEngine(options);
        } else {
          this.useService(options);
        }
      },
      close401Modal: function close401Modal() {
        this.set('show401Modal', false);
      }
    }
  });
});