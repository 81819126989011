define('tunespeak-ember/components/redemption-question/select', ['exports', 'tunespeak-ember/components/redemption-question/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    value: Ember.computed.oneWay('model.answer'),
    actions: {
      selectOption: function selectOption(value) {
        this.set('model.answer', value);
      }
    }
  });
});