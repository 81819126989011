define('tunespeak-ember/components/consent-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    requireConsents: Ember.inject.service(),
    store: Ember.inject.service(),
    consents: null,
    activeConsent: Ember.computed('consents.[]', function () {
      return this.get('consents.lastObject');
    }),

    init: function init() {
      this._super();
      this.get('store').unloadAll('user-consent');
      this.set('consents', this.get('requireConsents.consents'));
    },


    actions: {
      closeModal: function closeModal() {
        this.get('requireConsents').cancel();
      },
      acknowledgeConsent: function acknowledgeConsent(consented) {
        var _this = this;

        this.get('store').createRecord('user-consent', {
          consented: !!consented,
          consentId: this.get('activeConsent.id')
        }).save().then(function () {
          var consents = _this.get('consents');
          if (consents.length > 1) {
            consents.popObject();
          } else {
            _this.get('requireConsents').done();
          }
        });
      }
    }
  });
});