define("tunespeak-ember/controllers/update-password", ["exports", "tunespeak-ember/controllers/reset-password"], function (exports, _resetPassword) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resetPassword.default.extend({
    actions: {
      updatePassword: function updatePassword() {
        var _this = this;

        if (this.get("working")) {
          return;
        }
        this.set("error", null);
        var password = this.password,
            rePassword = this.rePassword;

        if (password !== rePassword) {
          return this.set("error", "Passwords do not match");
        }
        this.validatePasswordStrength(password).then(function () {
          _this.set("working", true);
          var profile = _this.get("session.currentUser.profile");
          profile.set("password", password);
          profile.save().then(function () {
            _this.set("success", true);
          }).catch(function (error) {
            if (error && error.payload && error.payload.message) {
              _this.set("error", error.payload.message);
            }
          }).finally(function () {
            _this.set("working", false);
          });
        });
      }
    }
  });
});