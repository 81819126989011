define('tunespeak-ember/components/contest-actions/spotify-album-play', ['exports', 'tunespeak-ember/components/contest-actions/spotify-song-play'], function (exports, _spotifySongPlay) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _spotifySongPlay.default.extend({
    layoutName: 'components/contest-actions/spotify-song-play',
    isAlbum: true,
    spotifyUri: Ember.computed('spotifyId', function () {
      var id = this.get('spotifyId');
      if (id.includes('playlist:')) {
        return id;
      }
      return 'album:' + this.get('spotifyId');
    })
  });
});