define('tunespeak-ember/services/credit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    socket: Ember.inject.service('ts-socket'),
    store: Ember.inject.service(),
    startListeningForCredits: function startListeningForCredits() {
      var _this = this;

      this.get('socket').on('didReceiveCredit', function (attrs) {
        var store = _this.get('store');
        // push credit into store
        store.pushPayload({ credit: attrs });
        var credit = store.peekRecord('credit', attrs.id);

        if (credit) {
          // update user contest with total points'
          var userContest = store.peekAll('user-contest').findBy('contestId', credit.get('contestId'));
          if (userContest) {
            // get max of current and new total points in case notifications received out of order
            var directPoints = credit.get('totalPoints'),
                indirectPoints = credit.get('totalIndirectPoints'),
                ucDirectPoints = userContest.get('points'),
                ucIndirectPoints = userContest.get('indirectPoints');

            if (ucDirectPoints) {
              directPoints = Math.max(directPoints, ucDirectPoints);
            }
            if (ucIndirectPoints) {
              indirectPoints = Math.max(indirectPoints, ucIndirectPoints);
            }

            userContest.set('points', directPoints);
            userContest.set('indirectPoints', indirectPoints);
            userContest.set('place', credit.get('place'));
          }

          // update contest with total entries
          var contest = store.peekRecord('campaign', credit.get('contestId'));
          if (contest) {
            var contestTotalPoints = Math.max(credit.get('contestTotalPoints'), contest.get('totalPoints'));
            contest.set('totalPoints', contestTotalPoints);
          }

          // trigger event for any component, controller, route, etc. listening
          _this.trigger('didReceiveCredit', credit);
        }
      });
    }
  });
});