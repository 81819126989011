define('tunespeak-ember/components/ts-prompt', ['exports', '@tunespeak/tunespeak-elements/components/ts-prompt'], function (exports, _tsPrompt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tsPrompt.default;
    }
  });
});