define('tunespeak-ember/components/campaign/deluxe-with-livestream-template', ['exports', 'tunespeak-ember/mixins/components/campaign-template'], function (exports, _campaignTemplate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignTemplate.default, {
    livestreamAction: Ember.computed('model.contestActions', function () {
      return this.get('model.contestActions').findBy('template', 'live-stream-check-in');
    }),
    groupedContestActionsExceptLivestream: Ember.computed('groupedContestActions.@each.template', function () {
      return this.get('groupedContestActions').rejectBy('template', 'live-stream-check-in');
    })
  });
});