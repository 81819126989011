define('tunespeak-ember/components/img-retry', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'img',
    srcToTry: null,
    src: null,
    attributeBindings: ['src', 'width', 'height', 'alt'],
    interval: 3000, //ms
    placeholder: null,
    loaded: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('src', this.get('placeholder'));
      if (this.get('srcToTry')) {
        this.send('tryToLoad');
      }
    },

    actions: {
      tryToLoad: function tryToLoad() {
        var _this = this;

        if (this.get('loaded')) return;
        var img = new Image();
        img.onload = function () {
          _this.send('loaded');
        };
        img.src = this.get('srcToTry');
        Ember.run.later(function () {
          _this.send('tryToLoad');
        }, this.get('interval'));
      },
      loaded: function loaded() {
        this.set('src', this.get('srcToTry'));
        this.set('loaded', true);
        this.get('didLoad')();
      }
    },
    didLoad: function didLoad() {
      // optional callback
    }
  });
});