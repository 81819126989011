define('tunespeak-ember/routes/tour', ['exports', 'tunespeak-ember/mixins/ts-route', 'tunespeak-ember/mixins/promotion', 'tunespeak-ember/mixins/routes/shareable-meta', 'tunespeak-ember/mixins/routes/ts-route-requires-activation', 'tunespeak-ember/config/environment'], function (exports, _tsRoute, _promotion, _shareableMeta, _tsRouteRequiresActivation, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRoute.default, _promotion.default, _shareableMeta.default, _tsRouteRequiresActivation.default, {
    headTagsService: Ember.inject.service('head-tags'),
    model: function model(params) {
      var userContests = null;
      if (this.get('session.isAuthenticated')) {
        userContests = this.store.query('user-contest', { group_id: params.id }).catch(function () {
          return null;
        });
      }
      return Ember.RSVP.hash({
        contestGroup: this.store.findRecord('contest-group', params.id),
        userContests: userContests
      });
    },
    afterModel: function afterModel(hash, transition) {
      var _this = this;

      this.redirectToEmbeddedUrlIfExists(transition, hash.contestGroup);
      if (hash.contestGroup.get('layout')) {
        this.set('applicationLayout', hash.contestGroup.get('layout'));
      }

      var promotionId = transition.queryParams.p;
      var emPromotionId = transition.queryParams['em-promotion-id'];
      var promotionScope = hash.contestGroup.id;

      // If there is an embedded promotion id and no other promotion id, use default as promo id.
      if (!promotionId && emPromotionId && !this.get('session').getPromotionFor(promotionScope)) {
        promotionId = emPromotionId;
      }

      if (promotionId) {
        this.get('store').findRecord('promotion', promotionId).then(function (promotion) {
          if (_this.get('session.currentUser.id') !== promotion.get('promoterId')) {
            _this.get('session').setLastPromotion(promotionId);
            _this.get('session').setPromotionFor(promotionScope, promotionId);
            _this.trackPromotionClick(promotionId);
          }
          _this.setShareableMetaTags(hash.contestGroup, promotion);
        }).catch(function () {
          _this.setShareableMetaTags(hash.contestGroup);
        }).finally(function () {
          _this.get('headTagsService').collectHeadTags();
        });
      } else {
        this.setShareableMetaTags(hash.contestGroup);
      }

      if (hash.contestGroup.get('layout') === 'whitelabel') {
        Ember.run.scheduleOnce('afterRender', function () {
          var navbar = Ember.$('.whitelabel-navbar');
          Ember.$(window).bind('touchmove.whitelabel-navbar scroll.whitelabel-navbar', function () {
            Ember.run(function () {
              var scrollPosition = Ember.$(window).scrollTop();
              navbar.toggleClass('scrolled', scrollPosition >= 1);
            });
          });
        });
      }

      // For Theming Tool
      window.parent.postMessage("rendered", _environment.default.APP.REACT_ROOT);
    },
    setupController: function setupController(controller, hash) {
      this._super(controller, hash.contestGroup);
      controller.set('userContests', hash.userContests);
    },
    loadUserContests: function loadUserContests() {
      var _this2 = this;

      this.store.query('user-contest', { group_id: this.controller.get('model.id') }).then(function (response) {
        _this2.controller.set('userContests', response);
      }).catch(function () {
        return;
      });
    },
    activate: function activate() {
      this.get('session').on('userAuthenticated', this, this.loadUserContests);
    },
    deactivate: function deactivate() {
      this.get('session').off('userAuthenticated', this, this.loadUserContests);
      Ember.$(window).unbind('touchmove.whitelabel-navbar scroll.whitelabel-navbar');
    }
  });
});