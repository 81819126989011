define('tunespeak-ember/components/contest-actions/soundcloud-follow', ['exports', 'tunespeak-ember/mixins/components/campaign-action', 'tunespeak-ember/utils/cookie-intent'], function (exports, _campaignAction, _cookieIntent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignAction.default, {
    classNames: ['ts-list-item', 'campaign-action-item', 'spotify-action-item'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      //check for cookies with follow intent matching this action's id
      (0, _cookieIntent.handleFollowOrSaveCookie)(this.get('storage').get('authCookie'), this.get('model.id'), this);
    },
    actions: {
      follow: function follow() {
        var options = {};
        options.record = this.get('store').createRecord('soundcloud-follow', {
          contestId: this.get('contest.id'),
          followableId: this.get('bandId'),
          followableType: this.get('model.content.type'),
          promotionId: this.get('promotionId'),
          contestActionId: this.get('model.id')
        });
        options.intent = 'follow';
        options.service = 'soundcloud';
        options.actionId = this.get('model.id');

        this.send('saveRecord', options);

        this.get('googleTracking')('soundcloud-follow', {
          category: 'campaign-action',
          label: this.get('title')
        });
      },
      loginToSoundcloud: function loginToSoundcloud() {
        var _this = this;

        this.set('isLoggingIn', true);
        this.get('session').loginService('soundcloud').then(function () {
          _this.toggleProperty('show401Modal');
          _this.send('follow');
        }).finally(function () {
          _this.set('isLoggingIn', false);
        });
      }
    }
  });
});