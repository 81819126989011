define('tunespeak-ember/components/contest-actions/youtube-video-group', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ts-list-item', 'campaign-action-item', 'youtube-video-group'],
    credit: Ember.inject.service(),
    store: Ember.inject.service(),
    activeVideo: null,
    setupComplete: false, // true when player has been initialized
    sidebarExpanded: false,
    baseColor: 'rgb(0,0,0)',
    contestActions: Ember.computed.alias('model.contestActions'),
    userContestActions: Ember.computed.map('contestActions', function (ca) {
      return this.get('userContest.actions').findBy('id', ca.id);
    }),
    totalPoints: Ember.computed(function () {
      return this.get('contestActions').reduce(function (acc, ca) {
        return acc + ca.get('pointValue') * ca.get('directLimit');
      }, 0);
    }),
    totalPlaysRemaining: Ember.computed('userContestActions.@each.remaining', function () {
      return this.get('userContestActions').reduce(function (acc, uca) {
        return acc + uca.get('remaining');
      }, 0);
    }),
    videoEarnedCredit: function videoEarnedCredit(credit) {
      var _this = this;

      if (credit.get('points')) {
        var id = credit.get('contestActionId');
        // if this user-action is in this group then reload
        if (this.get('userContestActions').findBy('id', id)) {
          this.get('store').findRecord('user-contest-action', id, {
            reload: true,
            adapterOptions: { contestId: this.get('contest.id') }
          }).then(function () {
            _this.set('justEarnedCredit', true);
            Ember.run.later(function () {
              _this.set('justEarnedCredit', false);
            }, 1000);
          }).catch(function (error) {
            if (error instanceof _emberData.default.UnauthorizedError) {
              _this.get('session').open().then(function () {
                _this.videoEarnedCredit(credit);
              });
            }
          });
        }
      }
    },
    darkenColor: function darkenColor(rgb) {
      var percentage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5;

      var parsed = rgb.slice(4, rgb.length - 1).split(',');
      var darkenAmount = (100 - percentage) / 100;
      var r = Math.round(parsed[0] * darkenAmount);
      var g = Math.round(parsed[1] * darkenAmount);
      var b = Math.round(parsed[2] * darkenAmount);
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);
      this.get('credit').on('didReceiveCredit', this, this.videoEarnedCredit);
      var baseColor = Ember.$('#' + this.elementId + ' .previews').css('background-color');
      this.set('baseColor', baseColor);
      Ember.run.scheduleOnce('afterRender', function () {
        var darkenedColor = _this2.darkenColor(baseColor, _this2.get('contestActions.length') * 5.5);
        Ember.$('.previews').css('background-color', darkenedColor);
      });
      this.set('activeVideo', this.get('contestActions.firstObject'));
    },
    willDestroy: function willDestroy() {
      this.get('credit').off('didReceiveCredit', this, this.videoEarnedCredit);
      this._super.apply(this, arguments);
    },

    actions: {
      toggleSidebarExpanded: function toggleSidebarExpanded() {
        this.toggleProperty('sidebarExpanded');
      },
      switchActiveVideo: function switchActiveVideo(id) {
        if (this.get('setupComplete') && this.get('activeVideo.id') !== id) {
          this.set('activeVideo', this.get('contestActions').findBy('id', id));
          if (isMobile.any) {
            this.set('sidebarExpanded', false);
          }
        }
      },
      setupDidFinish: function setupDidFinish() {
        this.set('setupComplete', true);
      },
      playerDidFinish: function playerDidFinish(id) {
        var action = this.get('contestActions').findBy('id', id);
        var actionIndex = this.get('contestActions').indexOf(action);
        var count = this.get('contestActions.length');
        // set active video to next in list or first if last
        if (actionIndex + 1 < count) {
          this.set('activeVideo', this.get('contestActions').objectAt(actionIndex + 1));
        } else {
          this.set('activeVideo', this.get('contestActions.firstObject'));
        }
      }
    }
  });
});