define('tunespeak-ember/services/ticketmaster', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    storage: Ember.inject.service(),
    ajax: Ember.inject.service(),
    user: Ember.computed.alias('session.currentUser'),
    showMergeAccountModal: false,
    showEmailConflictModal: false,
    showLogoutModal: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('session').registerBeforeClose(function () {
        return new Ember.RSVP.Promise(function (resolve /*, reject */) {
          if (_this.get('storage').get('tmLogin')) {
            _this.logout().then(resolve).catch(resolve); //logout even if TM error
          } else {
            resolve();
          }
        });
      });
    },
    fetch: function fetch() {
      return loadSdk().then(function () {
        return TMIdentity.getLoginStatus().then(function (response) {
          return response;
        });
      });
    },
    login: function login() {
      var _this2 = this;

      return loadSdk().then(function () {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _this2.fetch().then(function (response) {
            if (response.status === 'connected') {
              resolve(response);
            } else {
              TMIdentity.login().then(function (response) {
                _this2.get('storage').set('tmLogin', true);
                resolve(response);
              }).catch(function (error) {
                reject({ message: error.status });
              });
            }
          });
        });
      });
    },
    logout: function logout() {
      this.get('storage').remove('tmLogin');
      return loadSdk().then(function () {
        return TMIdentity.logout();
      });
    },
    require: function require() {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (_this3.get('user')) {
          if (_this3.get('user.ticketmaster')) {
            resolve();
          } else {
            _this3.set('showMergeAccountModal', true);
            mergeAccountDef = new Ember.RSVP.defer();
            mergeAccountDef.promise.then(function () {
              _this3.login().then(function (authResponse) {
                _this3.connectUser(authResponse).then(resolve).catch(reject);
              }).catch(reject);
            }).catch(reject);
            return mergeAccountDef.promise;
          }
        } else {
          _this3.login().then(function (authResponse) {
            _this3.createSession(authResponse).then(resolve).catch(function (e) {
              var error = e.errors[0];
              if (error && error.detail && error.detail.code === 6004) {
                _this3.set('showEmailConflictModal', true);
                emailConflictDef = new Ember.RSVP.defer();
                emailConflictDef.promise.then(function () {
                  _this3.get('session').requireLogin().then(function () {
                    _this3.require().then(resolve).catch(reject); //recurse
                  }).catch(reject);
                }).catch(reject);
                return emailConflictDef.promise;
              } else {
                reject(e);
              }
            });
          }).catch(reject);
        }
      });
    },
    connectUser: function connectUser(authResponse) {
      var _this4 = this;

      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return this.get('ajax').post(config.APP.API_ROOT + '/user_ticketmasters', {
        data: {
          user_ticketmaster: {
            access_token: authResponse.tmToken,
            refresh_token: authResponse.tmRToken
          }
        }
      }).then(function () {
        return _this4.get('session.currentUser').reload();
      }).catch(function (e) {
        if (e && e.errors && e.errors[0]) {
          var error = e.errors[0];
          if (error.detail.code === 422 && error.detail.message === "Ext Already connected to Tunespeak") {
            _this4.set('showLogoutModal', true);
          }
        }
        throw e;
      });
    },
    createSession: function createSession(authResponse) {
      return this.get('session').createSessionAync({
        session: {
          ticketmaster: {
            expires_in: authResponse.expires,
            access_token: authResponse.tmToken,
            refresh_token: authResponse.tmRToken,
            ext_id: authResponse.tmUserId
          }
        }
      });
    },
    mergeAccount: function mergeAccount() {
      this.set('showMergeAccountModal', false);
      if (mergeAccountDef) {
        mergeAccountDef.resolve();
      }
    },
    dontMergeAccount: function dontMergeAccount() {
      this.set('showMergeAccountModal', false);
      if (mergeAccountDef) {
        mergeAccountDef.reject();
      }
    },
    resolveEmailConflict: function resolveEmailConflict() {
      this.set('showEmailConflictModal', false);
      if (emailConflictDef) {
        emailConflictDef.resolve();
      }
    },
    dontResolveEmailConflict: function dontResolveEmailConflict() {
      this.set('showEmailConflictModal', false);
      if (emailConflictDef) {
        emailConflictDef.reject();
      }
    }
  });


  var loadSdkPromise, mergeAccountDef, emailConflictDef;
  function loadSdk() {
    if (!loadSdkPromise) {
      loadSdkPromise = new Ember.RSVP.Promise(function (resolve, reject) {
        var embed = document.createElement('script');
        embed.id = 'tmIdentityScript';
        embed.setAttribute('src', 'https://identity.ticketmaster.com/static/sdk.js');
        var path = '/assets/images/ticketmaster-blue.svg';
        embed.onload = function () {
          TMIdentity.init({
            serverUrl: 'https://identity.ticketmaster.com',
            sdkDebug: false,
            style: {
              logo: window.location.origin + '/' + path
            }
          }).then(resolve).catch(reject);
        };
        document.head.appendChild(embed);
      });
    }
    return loadSdkPromise;
  }
});