define('tunespeak-ember/mixins/router/track-page-view', ['exports', '@tunespeak/tunespeak-elements/mixins/router/track-page-view'], function (exports, _trackPageView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _trackPageView.default;
    }
  });
});