define('tunespeak-ember/models/photo', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    band: _emberData.default.belongsTo('band', { async: false }),
    photoAlbum: _emberData.default.belongsTo('photoAlbum', { async: false }),
    caption: _emberData.default.attr('string'),
    thumbnail: _emberData.default.attr('string'),
    videoUrl: _emberData.default.attr('string'),
    link: _emberData.default.attr('string'),
    full: _emberData.default.attr('string'),
    publishedAt: _emberData.default.attr('string'),
    verb: 'Look at'
  });
});