define('tunespeak-ember/components/contest-actions/donation', ['exports', 'tunespeak-ember/mixins/components/campaign-action', 'tunespeak-ember/config/environment', 'tunespeak-ember/utils/validate-email'], function (exports, _campaignAction, _environment, _validateEmail) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEFAULT_AMOUNT = 10;
  var STATES = {
    AMOUNT: 1,
    FORM: 2,
    VERIFY: 3
  };
  var DEFAULT_STATE = STATES.AMOUNT;

  exports.default = Ember.Component.extend(_campaignAction.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.set('errors', {});
    },

    isIE: Ember.computed(function () {
      return navigator.appName == 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/));
    }),
    classNames: ['ts-list-item', 'campaign-action-item', 'donation'],
    pandaLoaded: false,
    pandaId: Ember.computed('elementId', function () {
      return 'panda-' + this.elementId;
    }),
    showDonationModal: false,
    working: false,

    firstName: '',
    lastName: '',
    creditCardNumber: '',
    expirationDate: '',
    formattedExpirationDate: Ember.computed('expirationDate', function () {
      var date = this.get('expirationDate');
      return date.substr(0, 3) + '20' + date.substr(3, 2);
    }),
    cvv: '',
    receiptEmail: Ember.computed.oneWay('session.currentUser.profile.email'),
    emailInputValid: Ember.computed('receiptEmail', function () {
      return this.get('receiptEmail') ? (0, _validateEmail.default)(this.get('receiptEmail').trim()) : false;
    }),

    state: DEFAULT_STATE,
    isAmount: Ember.computed.equal('state', STATES.AMOUNT),
    isForm: Ember.computed.equal('state', STATES.CARD),
    isVerify: Ember.computed.equal('state', STATES.VERIFY),

    selectedAmount: DEFAULT_AMOUNT,
    customAmount: DEFAULT_AMOUNT,
    selected10: Ember.computed.equal('selectedAmount', 10),
    selected25: Ember.computed.equal('selectedAmount', 25),
    selected50: Ember.computed.equal('selectedAmount', 50),
    selected100: Ember.computed.equal('selectedAmount', 100),
    selected500: Ember.computed.equal('selectedAmount', 500),
    selectedCustom: false,

    amount: Ember.computed('selectedCustom', 'selectedAmount', 'customAmount', function () {
      var val = this.get('selectedCustom') ? this.get('customAmount') : this.get('selectedAmount');
      if (val) {
        val = Math.round(parseFloat(val) * 100) / 100;
      }
      return val;
    }),
    formattedAmount: Ember.computed('amount', function () {
      return '$' + this.get('amount').toFixed(2);
    }),
    formCompleted: Ember.computed('firstName', 'lastName', 'creditCardNumber.length', 'expirationDate', 'cvv.length', 'emailInputValid', 'amount', function () {
      return this.get('firstName') && this.get('lastName') && this.get('creditCardNumber.length') === 16 && this.get('expirationDate') && this.get('cvv.length') >= 3 && this.get('emailInputValid') && this.get('amount') && this.get('amount') >= 5;
    }),

    //Card Errors
    errors: null,
    dateError: Ember.computed.or('errors.month', 'errors.year'),
    cardError: Ember.computed.or('errors.number', 'errors.card_type'),

    actions: {
      loadPanda: function loadPanda() {
        var _this = this;

        this.set('pandaLoaded', false);
        // need to load script every time :vomit-emoji:
        Ember.$.getScript("//d2t45z63lq9zlh.cloudfront.net/panda-v0.0.5.min.js").then(function () {
          Ember.run(function () {
            _this.set('pandaLoaded', true);

            window.Panda.init(_environment.default.pandaPay.publishableKey, _this.get('pandaId'));

            window.Panda.on('success', function (cardToken) {
              _this.set('working', false);
              _this.set('cardToken', cardToken);
              _this.send('goToVerify');
            });

            window.Panda.on('error', function (errors) {
              _this.set('working', false);
              _this.set('errors', {});
              errors.forEach(function (err) {
                var key = 'errors.' + err.attribute;
                _this.set(key, true);
              });
            });
          });
        });
      },
      selectAmount: function selectAmount(amount) {
        this.setProperties({
          selectedCustom: false,
          selectedAmount: amount
        });
      },
      selectCustomAmount: function selectCustomAmount() {
        this.setProperties({
          selectedAmount: null,
          selectedCustom: true
        });
      },
      showDonationModal: function showDonationModal() {
        this.setProperties({
          pandaLoaded: false,
          showDonationModal: true
        });
      },
      hideDonationModal: function hideDonationModal() {
        this.setProperties({
          cardToken: null,
          state: DEFAULT_STATE,
          selectedAmount: DEFAULT_AMOUNT,
          customAmount: DEFAULT_AMOUNT,
          selectedCustom: false,
          errors: {},
          working: false,
          showDonationModal: false
        });
      },
      goToAmount: function goToAmount() {
        this.set('state', STATES.AMOUNT);
      },
      goToForm: function goToForm() {
        var _this2 = this;

        this.set('state', STATES.FORM);
        Ember.run.scheduleOnce('afterRender', function () {
          _this2.send('loadPanda');
        });
      },
      goToVerify: function goToVerify() {
        this.set('state', STATES.VERIFY);
      },
      cancelDonation: function cancelDonation() {
        this.setProperties({
          errors: null,
          cardToken: null
        });
        this.send('goToAmount');
      },
      submitPanda: function submitPanda() {
        this.set('working', true);
      },
      confirmDonation: function confirmDonation() {
        var _this3 = this;

        this.setProperties({
          working: true,
          errors: {}
        });

        var donation = this.get('store').createRecord('donation', {
          cardToken: this.get('cardToken'),
          promotionId: this.get('promotionId'),
          contestId: this.get('contest.id'),
          amount: this.get('amount') * 100,
          causeId: this.get('model.content.id'),
          receiptEmail: this.get('receiptEmail'),
          contestActionId: this.get('model.id')
        });

        donation.save().then(function () {
          _this3.send('hideDonationModal');
          _this3.set('userAction.remaining', 0);
        }).catch(function (e) {
          if (e && e.errors && e.errors.length) {
            _this3.set('errors', e.errors.mapBy('detail'));
          } else {
            _this3.set('errors', ['Unknown error.']);
          }
        }).finally(function () {
          _this3.set('working', false);
        });
      }
    }
  });
});