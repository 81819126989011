define('tunespeak-ember/routes/presale-faq', ['exports', 'tunespeak-ember/mixins/ts-route'], function (exports, _tsRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRoute.default, {
    model: function model(params) {
      return this.store.findRecord('campaign', params.id);
    },
    afterModel: function afterModel(model, transition) {
      this.redirectToEmbeddedUrlIfExists(transition, model);
      if (model.get('layout')) {
        this.set('applicationLayout', model.get('layout'));
      }
    }
  });
});