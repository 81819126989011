define('tunespeak-ember/templates/500', ['exports', '@tunespeak/tunespeak-elements/templates/500'], function (exports, _) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _.default;
    }
  });
});