define('tunespeak-ember/controllers/redemption', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['token'],
    token: null,
    annoyBeforeLeaving: function annoyBeforeLeaving() {
      return this.get('model.available');
    }
  });
});