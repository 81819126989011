define('tunespeak-ember/components/campaign-ad-item-cta', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CTA_VERB = {
    'apple': 'Listen',
    'soundcloud': 'Listen',
    'spotify': 'Listen',
    'deezer': 'Listen',
    'youtube': 'Listen',
    'tidal': 'Listen',
    'itunes': 'Download',
    'itunes-preorder': 'Pre-Order',
    'google': 'Download',
    'google-preorder': 'Pre-Order',
    'google-stream': 'Listen',
    'amazon': 'Download',
    'amazon-preorder': 'Pre-Order',
    'amazon-stream': 'Listen'
  };

  exports.default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['campaign-ad-item-cta'],
    attributeBindings: ['href', 'target'],
    target: '_blank',
    href: Ember.computed('model.url', 'cta', function () {
      return this.get('model.url') + '&cta=' + this.get('cta');
    }),
    verb: Ember.computed('cta', function () {
      return CTA_VERB[this.get('cta')];
    }),
    isApple: Ember.computed.equal('cta', 'apple'),
    isDeezer: Ember.computed.equal('cta', 'deezer'),
    isSpotify: Ember.computed.equal('cta', 'spotify'),
    isSoundcloud: Ember.computed.equal('cta', 'soundcloud'),
    isYoutube: Ember.computed.equal('cta', 'youtube'),
    isTidal: Ember.computed.equal('cta', 'tidal'),
    isAmazon: Ember.computed('cta', function () {
      return this.get('cta').indexOf('amazon') === 0;
    }),
    isGoogle: Ember.computed('cta', function () {
      return this.get('cta').indexOf('google') === 0;
    }),
    isItunes: Ember.computed('cta', function () {
      return this.get('cta').indexOf('itunes') === 0;
    }),
    isListenCta: Ember.computed.equal('verb', 'Listen'),
    isBuyCta: Ember.computed.not('isListenCta')
  });
});