define('tunespeak-ember/services/media-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    players: null,
    init: function init() {
      this.set('players', []);
    },

    add: function add(player) {
      this.get('players').pushObject(player);
    },
    play: function play(player) {
      this.pauseAll();
      player.play();
    },
    pause: function pause(player) {
      player.pause();
    },
    pauseAll: function pauseAll() {
      this.get('players').forEach(function (item) {
        item.pause();
      });
    },
    pauseAllExcept: function pauseAllExcept(player) {
      this.get('players').forEach(function (item) {
        if (player.playerId !== item.playerId) {
          item.pause();
        }
      });
    },
    destroy: function destroy(player) {
      this.get('players').removeObject(player);
      if (player) {
        player.destroy();
      }
    }
  });
});