define('tunespeak-ember/adapters/redemption', ['exports', 'tunespeak-ember/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord(id, _modelName, snapshot) {
      return this.get('host') + '/redemptions/' + id + '?token=' + snapshot.adapterOptions.token;
    }
  });
});