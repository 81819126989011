define('tunespeak-ember/components/contest-actions/follow-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ICONS = {
    'spotify-band-follow': 'icon-spotify',
    'twitter-follow': 'icon-twitter',
    'facebook-like': 'icon-facebook',
    'youtube-subscription': 'icon-youtube',
    'mailing-list-entry': 'icon-mail'
  };

  exports.default = Ember.Component.extend({
    classNames: ['ts-list-item', 'campaign-action-item'],
    modalParam: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.get('modalParam') === this.get('model.label')) {
        Ember.run.scheduleOnce('afterRender', function () {
          _this.set('isModalOpen', true);
        });
      }
      this._super.apply(this, arguments);
    },

    contestActions: Ember.computed.alias('model.contestActions'),
    isModalOpen: false,
    photo: Ember.computed.alias('contestActions.firstObject.content.photo'),
    names: Ember.computed(function () {
      return this.get('contestActions').mapBy('content.title').uniq().join(', ');
    }),
    icons: Ember.computed(function () {
      return this.get('contestActions').mapBy('template').uniq().map(function (template) {
        return ICONS[template];
      });
    }),
    pointValue: Ember.computed(function () {
      return this.get('contestActions').reduce(function (acc, contestAction) {
        return acc + contestAction.get('pointValue');
      }, 0);
    }),
    userContestActions: Ember.computed.map('contestActions', function (ca) {
      return this.get('userContest.actions').findBy('id', ca.id);
    }),
    completed: Ember.computed('userContestActions.@each.completed', function () {
      return this.get('userContestActions').isEvery('completed', true);
    }),
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('isModalOpen');
        this.set('modalParam', this.get('isModalOpen') ? this.get('model.label') : null);
      }
    }
  });
});