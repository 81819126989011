define('tunespeak-ember/components/contest-actions/live-stream-check-in', ['exports', 'tunespeak-ember/mixins/components/campaign-action'], function (exports, _campaignAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // DEMO Contests with timer always being reset
  var DEMO_IDS = ['5eebc3bb50d7407b1c2d971b', '5eecf60af6f96452089a40d7', '5ee7bfda3ec98410ceb004c7'];

  exports.default = Ember.Component.extend(_campaignAction.default, {
    classNames: ['ts-list-item'],
    isChatOpen: true,
    isTheatreMode: false,
    startsAt: Ember.computed('model.content.starts_at', 'contest.id', function () {
      // demo hacks
      if (DEMO_IDS.includes(this.get('contest.id'))) {
        return moment().add(10, 'seconds');
      } else {
        return this.get('model.content.starts_at');
      }
    }),
    started: Ember.computed('startsAt', function () {
      return moment(this.get('startsAt')).isBefore(moment());
    }),
    notStarted: Ember.computed.not('started'),
    showCountdown: Ember.computed('startsAt', function () {
      return moment(this.get('startsAt')).diff(moment(), 'hours') < 24;
    }),
    ended: Ember.computed('model.content.ends_at', function () {
      return moment(this.get('model.content.ends_at')).isBefore(moment());
    }),
    checkInDisabled: Ember.computed.or('notStarted', 'ended'),
    chatDisabled: Ember.computed('checkInDisabled', 'model.content.source', function () {
      return this.get('checkInDisabled') || !this.get('model.content.chat_embed_url') ||
      // Facebook has not chat
      this.get('model.content.source') === 'facebook' ||
      // Youtube chat disabled on mobile devices
      this.get('model.content.source') == 'youtube' && isMobile.any;
    }),
    actions: {
      toggleChat: function toggleChat() {
        this.toggleProperty('isChatOpen');
      },
      toggleTheatreMode: function toggleTheatreMode() {
        if (this.get('isTheatreMode')) {
          Ember.$('html').removeClass('ts-modal-ios11');
        } else {
          Ember.$('html').addClass('ts-modal-ios11');
        }
        this.toggleProperty('isTheatreMode');
      },
      checkIn: function checkIn() {
        var _this = this;

        if (!this.get('userAction.completed')) {
          this.set('userAction.completed', true);
          var checkIn = this.get('store').createRecord('live-stream-check-in', {
            promotionId: this.get('promotionId'),
            contestId: this.get('contest.id'),
            contestActionId: this.get('model.id')
          });
          checkIn.save().then(function () {
            _this.get('googleTracking')('livestream-check-in', {
              category: 'campaign-action',
              label: _this.get('title')
            });
          });
        }
      },
      countdownComplete: function countdownComplete() {
        this.notifyPropertyChange('started');
      }
    }
  });
});