define('tunespeak-ember/adapters/setlist', ['exports', 'tunespeak-ember/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForCreateSpotifyPlaylist: function urlForCreateSpotifyPlaylist(id) {
      return this.buildURL('setlist', id) + '/playlist';
    }
  });
});