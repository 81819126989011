define('tunespeak-ember/components/video-preview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['video-preview'],
    attributeBindings: ['style'],
    style: Ember.computed('color', 'index', function () {
      var darkened = this.darkenColor(this.get('color'), this.get('index') * 5.5);
      return 'background-color: ' + darkened;
    }),
    color: null,
    index: 0,
    darkenColor: null,
    userAction: Ember.computed('userContest.actions', function () {
      if (this.get('userContest.actions')) {
        return this.get('userContest.actions').findBy('id', this.get('model.id'));
      } else {
        return null;
      }
    }),
    click: function click() {
      this.get('onClick')(this.get('model.id'));
    }
  });
});