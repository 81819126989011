define('tunespeak-ember/components/ts-stepper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['ts-stepper'],
    classNameBindings: ['isVertical:vertical'],
    data: null,
    steps: 3,
    currentStep: 0,
    points: 100,
    orientation: 'vertical',
    isVertical: Ember.computed.equal('orientation', 'vertical'),
    icon: 'icon-entry',
    stepper: Ember.computed('currentStep', 'steps', 'data', function () {
      var data = this.get('data');
      var currentStep = this.get('currentStep');
      var steps = this.get('steps');
      var ret = new Ember.A();
      for (var i = 0; i < steps; i++) {
        var stepData = data.objectAt(i);
        var tmp = {
          complete: currentStep > i,
          active: currentStep === i
        };
        if (stepData) {
          Ember.merge(tmp, stepData);
        }
        ret.pushObject(tmp);
      }
      return ret;
    })
  });
});