define('tunespeak-ember/mixins/routes/markdown', ['exports', 'tunespeak-ember/mixins/ts-route', 'tunespeak-ember/mixins/routes/full-screen'], function (exports, _tsRoute, _fullScreen) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_tsRoute.default, _fullScreen.default, {
    templateName: 'markdown',
    file: null,
    model: function model() {
      var file = this.get('file');
      if (file) {
        return Ember.$.ajax({
          url: 'https://s3.amazonaws.com/tunespeak-cms/' + file,
          xhrFields: {
            withCredentials: false
          }
        }).then(function (response, status, xhr) {
          return {
            title: xhr.getResponseHeader('x-amz-meta-title'),
            markdown: response
          };
        });
      }
    }
  });
});