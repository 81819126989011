define('tunespeak-ember/components/contest-actions/photo-look', ['exports', 'tunespeak-ember/utils/jw-player'], function (exports, _jwPlayer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['photo-look'],
    store: Ember.inject.service(),
    model: null,
    index: null,
    currentSlide: null,
    isActive: Ember.computed('index', 'currentSlide', function () {
      return this.get('index') === this.get('currentSlide');
    }),
    playerId: Ember.computed('elementId', function () {
      return 'jw-' + this.elementId;
    }),
    userPhotoLook: Ember.computed('userAction.remainingContent', 'photo.id', function () {
      if (this.get('userAction.remainingContent')) {
        return this.get('userAction.remainingContent').findBy('id', this.get('photo.id'));
      }
      return null;
    }),
    viewed: Ember.computed.lte('userPhotoLook.remaining', 0),
    activeObserver: Ember.observer('isActive', function () {
      if (this.get('isActive')) {
        this.send('look');
      } else {
        if (this.get('player') && this.get('player.isPlaying')) {
          this.get('player').pause();
        }
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.activeObserver();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('player')) {
        this.get('player').destroy();
      }
    },

    actions: {
      createVideoPlayer: function createVideoPlayer() {
        if (this.get('player')) {
          return;
        }
        this.set('player', new _jwPlayer.default({
          playerId: this.get('playerId'),
          width: '100%',
          height: '100%',
          file: this.get('photo.video_url'),
          image: this.get('photo.full'),
          polling: false
        }));
      },
      look: function look() {
        var look = this.get('store').createRecord('look', {
          photoId: this.get('photo.id'),
          promotionId: this.get('promotionId'),
          contestId: this.get('contest.id'),
          contestActionId: this.get('model.id')
        });
        look.save();
      },
      trackInstagramClick: function trackInstagramClick() {
        this.get('googleTracking')('instagram-link-click', {
          category: 'campaign-action',
          label: this.get('photo.link')
        });
      }
    }
  });
});