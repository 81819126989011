define('tunespeak-ember/components/contest-actions/spotify-song-add', ['exports', 'tunespeak-ember/mixins/components/spotify-action', 'ember-ajax/errors', 'tunespeak-ember/utils/cookie-intent'], function (exports, _spotifyAction, _errors, _cookieIntent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_spotifyAction.default, {
    spotify: Ember.inject.service(),
    classNames: ['ts-list-item', 'campaign-action-item'],
    modalOpen: false,
    showCreatePlaylistInput: false,
    newPlaylistName: '',
    needsReauth: false,
    playlists: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('playlists', []);
      (0, _cookieIntent.handleFollowOrSaveCookie)(this.get('storage').get('authCookie'), this.get('model.id'), this);
    },


    playlistNameIvalid: Ember.computed('newPlaylistName', function () {
      var name = this.get('newPlaylistName').trim();
      return name.length === 0 || name.length > 100;
    }),

    myPlaylists: Ember.computed('playlists.[]', function () {
      var uri = this.get('session.currentUser.spotify.uri') || 'spotify:user:' + this.get('appreciationEngine').getService('spotify').UserID;
      return this.get('playlists').filterBy('owner.uri', uri);
    }),
    userHasPermissions: Ember.computed('session.currentUser.spotify.scopes', function () {
      var _this = this;

      return ['playlist-modify-private', 'playlist-read-collaborative', 'playlist-read-private'].every(function (el) {
        return _this.get('session.currentUser.spotify.scopes').includes(el);
      });
    }),

    //Pagination
    total: 0,
    hasMore: Ember.computed('playlists.length', 'total', function () {
      return this.get('playlists.length') < this.get('total');
    }),
    isLoadingMore: false,
    nextUrl: null,

    handleError: function handleError(error) {
      if ((0, _errors.isUnauthorizedError)(error)) {
        this.set('needsReauth', true);
      }
    },
    createAdd: function createAdd(playlistId, playlistName) {
      return this.get('store').createRecord('song-add', {
        extPlaylistId: playlistId,
        extPlaylistName: playlistName,
        songId: this.get('model.content.id'),
        contestId: this.get('contest.id'),
        promotionId: this.get('promotionId'),
        contestActionId: this.get('model.id')
      });
    },
    _fetchPlaylists: function _fetchPlaylists() {
      var _this2 = this;

      this.set('isLoadingMore', true);
      var options = {};
      if (this.get('useAeService')) {
        options = {
          headers: {
            'Authorization': 'Bearer ' + this.get('appreciationEngine').getService('spotify').Token
          }
        };
      }
      this.get('spotify').fetch(this.get('nextUrl') || 'https://api.spotify.com/v1/me/playlists?limit=50', options).then(function (data) {
        var prev = _this2.get('myPlaylists.length');
        _this2.get('playlists').pushObjects(data.items);
        _this2.setProperties({
          'nextUrl': data.next,
          'total': data.total
        });
        if (_this2.get('myPlaylists.length') === prev && _this2.get('hasMore')) {
          _this2.send('fetchPlaylists');
        }
      }).catch(function (error) {
        _this2.handleError(error);
      }).finally(function () {
        _this2.set('isLoadingMore', false);
      });
    },


    actions: {
      openModal: function openModal() {
        this.set('needsReauth', false);
        this.set('playlists', []);
        this.set('modalOpen', true);
        this.send('fetchPlaylists');
      },
      closeModal: function closeModal() {
        this.set('modalOpen', false);
        this.set('nextUrl', null);
      },
      fetchPlaylists: function fetchPlaylists() {
        var _this3 = this;

        if (this.get('useAeService')) {
          this.get('appreciationEngine').requireService('spotify', this.get('contest.id')).then(function (aeJWT) {
            _this3._fetchPlaylists();
          });
          return;
        } else if (!this.get('session.currentUser.spotify') || !this.get('userHasPermissions')) {
          this.set('needsReauth', true);
          return;
        }

        this._fetchPlaylists();
      },
      loadMore: function loadMore() {
        if (!this.get('isLoadingMore')) {
          this.send('fetchPlaylists');
        }
      },
      createPlaylist: function createPlaylist() {
        var _this4 = this;

        this.set('isLoadingMore', true);
        var options = {
          data: { name: this.get('newPlaylistName') }
        };
        if (this.get('useAeService')) {
          options.headers = {
            'Authorization': 'Bearer ' + this.get('appreciationEngine').getService('spotify').Token
          };
        }
        var userId = this.get('useAeService') ? this.get('appreciationEngine').getService('spotify').UserID : this.get('session.currentUser.spotify.ext_id');
        this.get('spotify').post('https://api.spotify.com/v1/users/' + userId + '/playlists', options).then(function () {
          // New Playlist will be first in list, refetch first 50 & set to preserve correct pagination
          _this4.get('spotify').fetch('https://api.spotify.com/v1/me/playlists?limit=50', options).then(function (data) {
            _this4.set('playlists', data.items);
            _this4.setProperties({
              'nextUrl': data.next,
              'total': data.total,
              'showCreatePlaylistInput': false
            });
          });
        }).catch(function (error) {
          _this4.handleError(error);
        }).finally(function () {
          _this4.set('isLoadingMore', false);
        });
      },
      addToPlaylist: function addToPlaylist(playlistId, playlistName) {
        this.send('closeModal');
        var options = {
          record: this.createAdd(playlistId, playlistName),
          intent: 'add-to-playlist',
          params: [].concat(Array.prototype.slice.call(arguments)),
          service: 'spotify',
          actionId: this.get('model.id')
        };
        this.send('saveRecord', options);
        this.get("googleTracking")('spotify-' + this.get('type') + '-add', {
          category: "campaign-action",
          label: this.get("title")
        });
      },
      authenticateSpotify: function authenticateSpotify() {
        var _this5 = this;

        if (this.get('useAeService')) {
          this.get('appreciationEngine').requireService('spotify', this.get('contest.id'), true).then(function () {
            _this5.set('needsReauth', false);
            _this5.send('fetchPlaylists');
          }).catch(function () {});
        } else {
          this.get('session').requireService('spotify', true).then(function () {
            _this5.set('needsReauth', false);
            _this5.send('fetchPlaylists');
          }).catch();
        }
      }
    }
  });
});