define('tunespeak-ember/models/user-post', ['exports', 'ember-data', 'tunespeak-ember/models/promotion'], function (exports, _emberData, _promotion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _promotion.default.extend({
    tumblrTags: _emberData.default.attr('string'),
    promotionId: _emberData.default.attr('string')
  });
});