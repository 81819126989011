define('tunespeak-ember/components/ts-tab-pane', ['exports', '@tunespeak/tunespeak-elements/components/ts-tab-pane'], function (exports, _tsTabPane) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tsTabPane.default;
    }
  });
});