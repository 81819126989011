define('tunespeak-ember/models/lock', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    requirements: _emberData.default.hasMany('requirement', { async: false }),
    requirementCaIds: Ember.computed('requirements', function () {
      return this.get('requirements').reduce(function (acc, curr) {
        return acc.concat(curr.get('contestActionIds'));
      }, []);
    })
  });
});