define('tunespeak-ember/serializers/user-contest', ['exports', 'ember-data', 'tunespeak-ember/serializers/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      actions: { embedded: 'always' },
      extTrackingRef: { embedded: 'always' },
      contestPreview: { embedded: 'always' },
      enrollmentLock: { embedded: 'always' }
    }
  });
});