define('tunespeak-ember/routes/application', ['exports', 'tunespeak-ember/config/environment', 'ember-cli-meta-tags/mixins/route-meta'], function (exports, _environment, _routeMeta) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeMeta.default, {
    ticketmaster: Ember.inject.service(),
    creditService: Ember.inject.service('credit'),
    beforeModel: function beforeModel() {
      // Attempt to fetch the session when the before first route is loaded
      return this.get('session').fetch().catch(function () {
        return true;
      });
    },

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('session').on('userAuthenticated', function () {
        _this.get('ts-socket').connect();
        _this.get('creditService').startListeningForCredits();

        // this.store.queryRecord('user-notification', {}).then((record) => {
        //   this.set('session.currentUser.notifications', record);
        // });
      });

      this.get('session').on('userUnauthenticated', function () {
        ['conversation', 'message', 'user-band', 'user-notification', 'user-profile', 'user-contest', 'user-contest-action', 'user-post'].forEach(function (modelType) {
          _this.store.unloadAll(modelType);
        });
        window.location.reload();
      });
    },


    setDefaultMetaTags: function setDefaultMetaTags() {
      var title = 'Tunespeak - Win for being a fan.',
          description = 'Millions of fans rewarded with tickets, experiences, and merchandise.',
          photo = 'https://tunespeak-cms.s3.amazonaws.com/images/tunespeak-share-image.jpg';

      var meta = {
        property: {
          'fb:app_id': _environment.default.facebook.appId,
          'og:description': description,
          'og:title': title,
          'og:image': photo,
          'og:image:width': 1200,
          'og:image:height': 630
        },
        name: {
          'twitter:title': title,
          'twitter:image:src': photo,
          'twitter:site': '@tunespeak',
          'twitter:card': 'summary_large_image',
          'twitter:domain': location.protocol + '//' + location.host,
          'twitter:description': description
        }
      };

      this.set('meta', meta);
    },

    setupController: function setupController() {
      this._super.apply(this, arguments);
      this.setDefaultMetaTags();
    },


    actions: {
      login: function login() {
        if (!this.get('session.isWorking')) {
          this.get('session').open();
        }
      },
      logout: function logout() {
        if (!this.get('session.isWorking')) {
          this.get('session').close();
        }
      },
      toggle: function toggle(property, scope) {
        scope.toggleProperty(property.toString());
      }
    }
  });
});