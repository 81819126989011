define('tunespeak-ember/helpers/ts-capitalize', ['exports', '@tunespeak/tunespeak-elements/helpers/ts-capitalize'], function (exports, _tsCapitalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tsCapitalize.default;
    }
  });
  Object.defineProperty(exports, 'tsCapitalize', {
    enumerable: true,
    get: function () {
      return _tsCapitalize.tsCapitalize;
    }
  });
});