define('tunespeak-ember/routes/redemption/release', ['exports', 'tunespeak-ember/mixins/ts-route', 'tunespeak-ember/mixins/routes/redemption/affidavit'], function (exports, _tsRoute, _affidavit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRoute.default, _affidavit.default, {
    alwaysResetScroll: true,
    afterModel: function afterModel(model) {
      if (!model.get('complete') || !model.get('affidavit')) {
        this.transitionTo('redemption.index');
      }
    }
  });
});