define('tunespeak-ember/models/contest-preview', ['exports', 'ember-data', 'tunespeak-ember/mixins/models/shareable'], function (exports, _emberData, _shareable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_shareable.default, {
    showLocation: _emberData.default.attr('string'),
    showVenue: _emberData.default.attr('string'),
    showtitle: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    showStartsAt: _emberData.default.attr('date'),
    endsAt: _emberData.default.attr('date'),
    startsAt: _emberData.default.attr('date'),
    canceled: _emberData.default.attr('boolean'),
    started: _emberData.default.attr('boolean'),
    groupId: _emberData.default.attr('string'),
    ended: _emberData.default.attr('boolean'),
    hasRules: _emberData.default.attr('boolean'),
    thumbnail: _emberData.default.attr('string'),
    bandNames: _emberData.default.attr('string'),
    template: _emberData.default.attr('string'),
    shareableTitle: Ember.computed.alias('title'),
    enrollmentConsentIds: _emberData.default.attr(),
    rulesPageUrl: _emberData.default.attr('string'),
    brand: _emberData.default.attr('string'),
    warnings: _emberData.default.attr(),
    allowedCountries: _emberData.default.attr(),
    requireVerifiedEmail: _emberData.default.attr('boolean'),
    hasEnrollmentLock: _emberData.default.attr('boolean')
  });
});