define('tunespeak-ember/components/band-notifications-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ts-list-item'],
    notificationsEnabled: Ember.computed.alias('userBand.notificationsEnabled'),
    actions: {
      toggle: function toggle(propertyName, value) {
        var _this = this;

        Ember.run.next(function () {
          _this.userBand.set(propertyName, value);
          _this.userBand.save().catch(function () {
            _this.userBand.rollbackAttributes();
          });
          return value;
        });
      }
    }
  });
});