define('tunespeak-ember/controllers/application', ['exports', 'tunespeak-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var styleListener = function styleListener(event) {
    if (event.origin === _environment.default.APP.REACT_ROOT || event.origin === _environment.default.APP.WWW_ROOT) {
      if (event.data.includes && event.data.includes(":")) {
        var _event$data$split = event.data.split(":"),
            _event$data$split2 = _slicedToArray(_event$data$split, 2),
            property = _event$data$split2[0],
            value = _event$data$split2[1];

        document.documentElement.style.setProperty(property, value);
      } else {
        var style = getComputedStyle(document.body);
        var message = event.data + ":" + style.getPropertyValue(event.data);
        window.parent.postMessage(message, _environment.default.APP.REACT_ROOT);
      }
    }
  };

  exports.default = Ember.Controller.extend({
    queryParams: [{ 'emShowImage': 'em-show-image' }, { 'emShowTitle': 'em-show-title' }, { 'emShowFooter': 'em-show-footer' }],
    emShowImage: 1,
    emShowTitle: 1,
    emShowFooter: 1,

    requirePhone: Ember.inject.service(),
    cookie: Ember.inject.service(),
    requireConsents: Ember.inject.service(),
    enrollmentWarnings: Ember.inject.service(),
    appreciationEngine: Ember.inject.service(),

    // Set to true to place a modal mask over the application
    // this.controllerFor('application').set('showApplicationOverlay', true);
    showApplicationOverlay: false,

    // Set to true to show the application menu slide out
    // this.controllerFor('application').set('showApplicationMenu', true);
    showApplicationMenu: false,

    currentYear: Ember.computed(function () {
      return new Date().getFullYear();
    }),

    showCookieNotification: Ember.computed.alias('cookie.isPending'),

    // Resets the state of the header
    // Call this before all modifications of the header
    resetApplicationState: function resetApplicationState() {
      // Overlay
      this.set('showApplicationOverlay', false);

      // Menu
      this.set('showApplicationMenu', false);
    },

    init: function init() {
      this._super.apply(this, arguments);
      window.addEventListener("message", styleListener);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      window.removeEventListener("message", styleListener);
    },


    // Sets/removes the background image as you go from page to page
    observesBackgroundImage: Ember.observer('backgroundImage', function () {
      var _this = this;

      if (this.get('backgroundImage')) {
        Ember.run.next(function () {
          Ember.$('html').css('background-image', 'url(' + _this.get('backgroundImage') + ')');
        });
      } else {
        Ember.$('html').css('background-image', 'none');
      }
    }),
    actions: {
      showApplicationMenu: function showApplicationMenu() {
        this.set('showApplicationMenu', true);
      }
    }
  });
});