define('tunespeak-ember/utils/validate-email', ['exports', '@tunespeak/tunespeak-elements/utils/validate-email'], function (exports, _validateEmail) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _validateEmail.default;
    }
  });
});