define('tunespeak-ember/models/campaign-ad', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TYPE_CLASS = {
    'Campaign::LinkAd': 'link-ad',
    'Campaign::ItemAd': 'item-ad',
    'Campaign::NearbyShowAd': 'nearby-show-ad'
  };

  var LISTEN_CTAS = ['apple', 'soundcloud', 'spotify', 'deezer', 'tidal', 'youtube', 'google-stream', 'amazon-stream'];
  var BUY_CTAS = ['itunes', 'itunes-preorder', 'google', 'google-preorder', 'amazon', 'amazon-preorder'];

  exports.default = _emberData.default.Model.extend({
    cta: _emberData.default.attr('string'),
    ctas: _emberData.default.attr(),
    ctaLabel: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    headline: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    priority: _emberData.default.attr('number'),
    thumbnail: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    show: _emberData.default.attr(),
    backgroundPhoto: _emberData.default.attr('string'),
    photo: _emberData.default.attr('string'),

    typeClass: Ember.computed('type', function () {
      return TYPE_CLASS[this.get('type')];
    }),

    // item-ads only
    listenCtas: Ember.computed('ctas.[]', function () {
      return this.get('ctas').filter(function (cta) {
        return LISTEN_CTAS.includes(cta);
      });
    }),
    buyCtas: Ember.computed('ctas.[]', function () {
      return this.get('ctas').filter(function (cta) {
        return BUY_CTAS.includes(cta);
      });
    })
  });
});