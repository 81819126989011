define('tunespeak-ember/components/contest-actions/purchase-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['purchase-container', 'campaign-action-item', 'ts-list-item'],
    contestActions: Ember.computed.alias('model.contestActions'),
    prevArrow: '<button class="btn slick-prev"><i class="icon-chevron-left"></i></button>',
    nextArrow: '<button class="btn slick-next"><i class="icon-chevron-right"></i></button>',
    slidesToShow: Ember.computed('contestActions.length', function () {
      return Math.min(this.get('contestActions.length'), 3);
    }),
    responsiveSettings: Ember.computed('slidesToShow', function () {
      var slides = Math.min(this.get('slidesToShow'), 2);
      return [{
        breakpoint: 700,
        settings: {
          slidesToShow: slides,
          slidesToScroll: slides
        }
      }, {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }];
    })
  });
});