define('tunespeak-ember/components/basic-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['basic-dropdown'],
    isShowing: null,

    handleOutsideClick: function handleOutsideClick(event) {
      var $element = this.$();
      var $target = Ember.$(event.target);

      if (!$target.closest($element).length) {
        this.set('isShowing', false);
      }
    },

    dropdownShowingObserver: Ember.observer('isShowing', function () {
      var _this = this;

      if (this.get('isShowing')) {
        Ember.run.next(function () {
          var clickHandler = _this.get('handleOutsideClick').bind(_this);
          Ember.$(document).on('click.' + _this.elementId, clickHandler);
        });
      } else {
        Ember.$(document).off('click.' + this.elementId);
      }
    }),

    willDestroyElement: function willDestroyElement() {
      Ember.$(document).off('click.' + this.elementId);
    }
  });
});