define('tunespeak-ember/controllers/tour', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    storage: Ember.inject.service(),
    application: Ember.inject.controller(),
    emShowImage: Ember.computed.alias('application.emShowImage'),
    emShowTitle: Ember.computed.alias('application.emShowTitle'),
    isEmbedded: Ember.computed.equal('model.layout', 'embed'),

    loginService: Ember.computed('model.tmVerifiedFan', function () {
      return this.get('model.tmVerifiedFan') ? 'ticketmaster' : null;
    }),
    backgroundImage: Ember.computed.alias('model.backgroundPhoto'),
    enrollment: Ember.inject.service('enrollment'),
    userContests: null, //set by route
    scrollTo: Ember.inject.service('ts-scroll-to'),

    isPresale: Ember.computed.equal('model.campaignTemplate', 'presale'),
    isDeluxeWithPresale: Ember.computed.equal('model.campaignTemplate', 'deluxe-with-presale'),

    controller: Ember.computed(function () {
      return this;
    }),

    recordLabelName: Ember.computed('model.{recordLabelName,recordLabelParent}', function () {
      if (this.get('model.recordLabelParent') === 'sme') {
        return 'Sony Music Entertainment';
      }
      return this.get('model.recordLabelName');
    }),

    tourTemplate: Ember.computed('model.campaignTemplate', function () {
      var template = this.get('model.campaignTemplate');
      if (template === 'spotify') {
        template = 'basic';
      }
      return 'partials/tour/' + template;
    }),

    currentUserContest: Ember.computed('userContests.@each.current', function () {
      if (this.get('userContests') && this.get('isPresale')) {
        return this.get('userContests').findBy('current');
      }
    }),
    currentContest: Ember.computed('currentUserContest', 'model', function () {
      return this.get('model.contestPreviews').findBy('id', this.get('currentUserContest.contestId'));
    }),
    notCurrentContests: Ember.computed('model', 'currentContest', function () {
      return this.get('model.contestPreviews').without(this.get('currentContest'));
    }),
    activeContests: Ember.computed.filterBy('notCurrentContests', 'ended', false),
    endedContests: Ember.computed.filterBy('notCurrentContests', 'ended', true),
    activeSort: Ember.A(['showStartsAt:asc']),
    endedSort: Ember.A(['showStartsAt:desc']),
    sortedActiveContests: Ember.computed.sort('activeContests', 'activeSort'),
    sortedEndedContests: Ember.computed.sort('endedContests', 'endedSort'),

    contestRules: 'Loading Rules...',
    legacyContestRules: false,
    rulesModalOpen: false,
    rulesModalContestId: null,
    showDemoToolbar: false,

    // data for html snippets to access if need be
    snippetData: Ember.computed('model.title', 'session.currentUser.id', 'userContest.id', function () {
      return {
        contest_title: this.get('model.title'),
        user_id: this.get('session.currentUser.id')
      };
    }),

    setSnippetData: function setSnippetData(contestId) {
      var contestPreview = this.get('model.contestPreviews').findBy('id', contestId);
      this.set('snippetData', {
        contest_title: contestPreview.get('title'),
        user_id: this.get('session.currentUser.id'),
        contest_show_date: contestPreview.get('showStartsAt'),
        contest_show_location: contestPreview.get('showLocation'),
        contest_group_id: contestPreview.get('groupId')
      });
    },


    actions: {
      enroll: function enroll(_ref) {
        var _this = this;

        var contestId = _ref.contestId,
            title = _ref.title,
            enrollmentConsentIds = _ref.enrollmentConsentIds,
            enrollmentWarnings = _ref.enrollmentWarnings,
            brand = _ref.brand,
            requireVerifiedEmail = _ref.requireVerifiedEmail;

        gtag('event', 'enrollment', {
          artist: this.get('model.bandNames'),
          contest: contestId,
          contest_group: this.get('model.id'),
          label: title
        });
        this.setSnippetData(contestId);
        this.get('storage').set('autoEnroll', contestId);
        var search = window.location.search.indexOf('?') === -1 ? '?enroll=true' : window.location.search + '&enroll=true';
        var options = {
          redirectURL: '/campaign/' + contestId + search,
          id: contestId,
          enrollmentConsentIds: enrollmentConsentIds,
          enrollmentWarnings: enrollmentWarnings,
          requirePhone: this.get('isDeluxeWithPresale'),
          requireVerifiedEmail: requireVerifiedEmail
        };

        if (brand) {
          options.brand = brand;
        }
        // TODO: this is temp hack - make a real Theme model
        else if (this.get('model.campaignTemplate') === 'deluxe-with-presale') {
            options.brand = 't.' + this.get('model.id');
          }

        if (this.get('isPresale')) {
          this.get('enrollment').enrollInPresale(options).then(function (userContest) {
            if (userContest) {
              _this.send('enrollmentFinished', contestId, title);
            }
          }).catch(function () {});
        } else {
          this.get('enrollment').enroll(options).then(function (userContest) {
            if (userContest) {
              _this.send('enrollmentFinished', contestId, title);
            }
          }).catch(function () {});
        }
      },
      enrollmentFinished: function enrollmentFinished(contestId, title) {
        gtag('event', 'enrollment-complete', {
          artist: this.get('model.bandNames'),
          contest: contestId,
          contest_group: this.get('model.id'),
          label: title
        });
        this.get('storage').remove('autoEnroll');
        this.transitionToRoute('campaign', contestId, {
          queryParams: { ucLoaded: true }
        });
      },
      openRulesModal: function openRulesModal(contestId, rulesPageUrl) {
        var _this2 = this;

        this.setProperties({
          rulesModalOpen: true,
          rulesModalContestId: contestId
        });

        return this.get('ajax').request(rulesPageUrl, {
          dataType: 'text',
          xhrFields: {
            withCredentials: false
          }
        }).then(function (response) {
          _this2.set('contestRules', {
            body: response
          });
        }).catch(function () {
          _this2.set('legacyContestRules', true);
        });
      },

      closeRulesModal: function closeRulesModal() {
        this.set('rulesModalOpen', false);
      },
      showApplicationMenu: function showApplicationMenu() {
        this.set('showApplicationMenu', true);
      },
      togglePresaleDescriptionModal: function togglePresaleDescriptionModal() {
        this.toggleProperty('presaleDescriptionModalOpen');
      },
      toggleDemoToolbar: function toggleDemoToolbar() {
        this.toggleProperty('showDemoToolbar');
      },
      setDemoState: function setDemoState(state) {
        this.get('notCurrentContests').forEach(function (contest) {
          contest.setProperties({
            ended: false,
            started: false,
            canceled: false
          });
          switch (state) {
            case 'NOT STARTED':
              return;
            case 'STARTED':
              contest.set('started', true);
              return;
            case 'ENDED':
              contest.set('ended', true);
              return;
            case 'CANCELED':
              contest.set('canceled', true);
              return;
            default:
              return;
          }
        });
      }
    }
  });
});