define('tunespeak-ember/components/contest-actions/twitter-follow', ['exports', 'tunespeak-ember/mixins/components/campaign-action', 'tunespeak-ember/utils/cookie-intent'], function (exports, _campaignAction, _cookieIntent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignAction.default, {
    classNames: ['ts-list-item', 'campaign-action-item', 'spotify-action-item'],
    twitterUsername: Ember.computed.alias('model.content.twitter_username'),
    twitterLink: Ember.computed('twitterUsername', function () {
      return 'https://twitter.com/' + this.get('twitterUsername');
    }),
    photo: Ember.computed.or('model.content.twitter_photo', 'model.content.photo'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      //check for cookies with follow intent matching this action's id
      (0, _cookieIntent.handleFollowOrSaveCookie)(this.get('storage').get('authCookie'), this.get('model.id'), this);
    },
    actions: {
      follow: function follow() {
        var options = {};
        options.record = this.get('store').createRecord('twitter-follow', {
          contestId: this.get('contest.id'),
          followableId: this.get('bandId'),
          followableType: 'Band',
          promotionId: this.get('promotionId'),
          contestActionId: this.get('model.id')
        });
        options.intent = 'follow';
        options.service = 'twitter';
        options.actionId = this.get('model.id');

        this.send('saveRecord', options);

        this.get("googleTracking")("twitter-follow", {
          category: "campaign-action",
          label: this.get("title")
        });
      },
      loginToTwitterFollow: function loginToTwitterFollow() {
        var _this = this;

        this.set('isLoggingIn', true);
        if (this.get('useAeService')) {
          this.get('appreciationEngine').requireService('twitter', this.get('contest.id'), true).then(function () {
            _this.toggleProperty('show401Modal');
            _this.send('follow');
          }).finally(function () {
            _this.set('isLoggingIn', false);
          });
        } else {
          this.get('session').loginService('twitter').then(function () {
            _this.toggleProperty('show401Modal');
            _this.send('follow');
          }).finally(function () {
            _this.set('isLoggingIn', false);
          });
        }
      }
    }
  });
});