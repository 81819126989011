define('tunespeak-ember/models/show', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    band: _emberData.default.belongsTo('band', { async: false }),
    title: _emberData.default.attr('string'),
    startsAt: _emberData.default.attr('date'),
    location: _emberData.default.attr('string'),
    venue: _emberData.default.attr('string'),
    photo: _emberData.default.attr('string'),
    timeZone: _emberData.default.attr('string'),

    month: Ember.computed('startsAt', function () {
      return moment(this.get('startsAt')).format('MMM');
    }),

    isToday: Ember.computed('startsAt', function () {
      return moment().startOf('day').isSame(moment(this.get('startsAt')).startOf('day'));
    }),

    grouping: Ember.computed('startsAt', function () {
      if (this.get('isToday')) {
        return 'Today';
      } else {
        return this.get('month');
      }
    })
  });
});