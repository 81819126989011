define('tunespeak-ember/controllers/redemption/skilled-question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var COUNTDOWN_TIMER = 60;

  exports.default = Ember.Controller.extend({
    started: false,
    endsAt: null,
    response: null,
    expired: false,
    actions: {
      start: function start() {
        this.set('started', true);
        this.set('endsAt', moment().add(COUNTDOWN_TIMER, 'seconds'));
      },
      countdownComplete: function countdownComplete() {
        var _this = this;

        Ember.run.next(function () {
          _this.set('expired', true);
        });
      },
      submit: function submit() {
        var _this2 = this;

        var response = this.response,
            model = this.model,
            redemption = this.redemption;

        if (response) {
          model.set('response', response);
          model.save().then(function (skilledQuestion) {
            redemption.set('skilledQuestion', skilledQuestion);
            if (skilledQuestion.get('isCorrect')) {
              if (redemption.get('affidavit')) {
                _this2.transitionToRoute('redemption.release');
              } else {
                redemption.save();
              }
            }
          });
        }
      }
    }
  });
});