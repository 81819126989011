define('tunespeak-ember/helpers/ts-time-left', ['exports', '@tunespeak/tunespeak-elements/helpers/ts-time-left'], function (exports, _tsTimeLeft) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tsTimeLeft.default;
    }
  });
  Object.defineProperty(exports, 'tsTimeLeft', {
    enumerable: true,
    get: function () {
      return _tsTimeLeft.tsTimeLeft;
    }
  });
});