define('tunespeak-ember/models/credit', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    actionId: _emberData.default.attr('string'),
    bandId: _emberData.default.attr('string'),
    contestId: _emberData.default.attr('string'),
    contestActionId: _emberData.default.attr('string'),
    userId: _emberData.default.attr('string'),
    direct: _emberData.default.attr('boolean'),
    points: _emberData.default.attr('number'),
    place: _emberData.default.attr('number'),
    text: _emberData.default.attr('string'),
    thumbnail: _emberData.default.attr('string'),
    totalPoints: _emberData.default.attr('number'),
    totalIndirectPoints: _emberData.default.attr('number'),
    contestTotalPoints: _emberData.default.attr('number'),
    isDirect: Ember.computed.alias('direct'),
    isInDirect: Ember.computed.not('direct')
  });
});