define('tunespeak-ember/components/contest-actions/spotify-podcast-follow', ['exports', 'tunespeak-ember/mixins/components/spotify-action', 'tunespeak-ember/utils/cookie-intent'], function (exports, _spotifyAction, _cookieIntent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_spotifyAction.default, {
    classNames: ['ts-list-item', 'campaign-action-item', 'spotify-action-item'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      //check for cookies with follow intent matching this action's id
      (0, _cookieIntent.handleFollowOrSaveCookie)(this.get('storage').get('authCookie'), this.get('model.id'), this);
    },
    spotifyUrl: Ember.computed('model.content.spotify_id', function () {
      return 'https://open.spotify.com/show/' + this.get('model.content.spotify_id');
    }),
    actions: {
      follow: function follow() {
        var options = {};
        options.record = this.get('store').createRecord('spotify-podcast-follow', {
          contestId: this.get('contest.id'),
          promotionId: this.get('promotionId'),
          contestActionId: this.get('model.id')
        });
        options.intent = 'follow';
        options.service = 'spotify';
        options.actionId = this.get('model.id');

        this.send('saveRecord', options);

        this.get("googleTracking")("spotify-podcast-follow", {
          category: "campaign-action",
          label: this.get("title")
        });
      }
    }
  });
});