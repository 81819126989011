define('tunespeak-ember/components/require-phone-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    requirePhone: Ember.inject.service(),
    phone: Ember.computed.reads('session.currentUser.profile.phone'),
    onlyCountries: Ember.computed.alias('requirePhone.onlyCountries'),
    preferredCountries: null,
    selectedCountryData: null,
    isValidNumber: Ember.computed.reads('phone'),
    allowSkip: Ember.computed.alias('requirePhone.allowSkip'),
    defaultCountry: Ember.computed('session.currentUser.profile.countryCode', function () {
      return this.get('session.currentUser.profile.countryCode') || 'US';
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        onlyCountries: this.get('onlyCountries') || ['us', 'ca'],
        preferredCountries: this.get('preferredCountries') || []
      });
    },


    actions: {
      closeModal: function closeModal() {
        this.get('requirePhone').cancel();
      },
      savePhone: function savePhone() {
        if (this.get('isValidNumber')) {
          var countryCode = this.get('defaultCountry');
          if (this.get('selectedCountryData')) {
            countryCode = this.get('selectedCountryData.iso2').toUpperCase();
          }
          this.get('requirePhone').updatePhone({
            unverifiedPhone: this.get('phone').replace(/\D/g, ''),
            unverifiedCountryCode: countryCode
          });
        }
      },
      skip: function skip() {
        this.get('requirePhone').skip();
      }
    }
  });
});