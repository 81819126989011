define('tunespeak-ember/components/contest-actions/texting-list-entry', ['exports', 'tunespeak-ember/mixins/components/campaign-action', 'tunespeak-ember/config/environment'], function (exports, _campaignAction, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignAction.default, {
    classNames: ['ts-list-item', 'campaign-action-item', 'texting-list-entry'],
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    storage: Ember.inject.service(),
    cookie: Ember.inject.service(),
    isModalOpen: false,
    ownerName: Ember.computed.alias('model.content.owner_name'),
    profile: Ember.computed.alias('session.currentUser.profile'),
    phone: Ember.computed.oneWay('session.currentUser.profile.phone'),
    defaultCountry: 'us',
    onlyCountries: null,
    preferredCountries: null,
    selectedCountryData: null,
    token: null,
    error: null,

    isUniversal: Ember.computed.equal('model.content.record_label_parent', 'umg'),
    countryAlpha2: null,
    umgPrivacyPolicy: Ember.computed('countryAlpha2', 'model.content.umg_form.privacy_policies', function () {
      var privacy_policies = this.get('model.content.umg_form.privacy_policies');
      var countryAlpha2 = this.get('countryAlpha2');
      if (countryAlpha2) {
        var selected = privacy_policies[countryAlpha2.toLowerCase()] && privacy_policies[countryAlpha2.toLowerCase()].html;
        return selected ? selected : this.get('model.content.umg_form.privacy_policies.default.html');
      }
      return this.get('model.content.umg_form.privacy_policies.default.html');
    }),
    joinDisabled: Ember.computed('countryAlpha2', 'model.content.umg_form.privacy_policies', 'isValidNumber', function () {
      return !this.get('isValidNumber') || this.get('model.content.umg_form.privacy_policies') && !this.get('countryAlpha2');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        onlyCountries: this.get('contest.allowedCountries') || ['us', 'ca'],
        preferredCountries: this.get('preferredCountries') || []
      });
    },


    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('isModalOpen');
        if (this.get('isModalOpen')) {
          this.set('error', null);
          Ember.run.next(function () {
            try {
              Ember.$('.texting-list-entry-modal .intl-tel-input input').focus();
            } catch (e) {}
          });
        }
      },
      back: function back() {
        this.set('verify', false);
      },
      resendCode: function resendCode() {
        var _this = this;

        this.set('isResending', true);
        this.get('ajax').post(_environment.default.APP.API_ROOT + '/texting_list_subscriptions/' + this.get('textingListSubscriptionId') + '/resend_verification');
        Ember.run.later(function () {
          if (!_this.get('isDestroyed') || !_this.get('isDestroying')) {
            _this.set('isResending', false);
          }
        }, 5000);
      },
      join: function join() {
        var _this2 = this;

        if (!this.get('isValidNumber')) return;
        if (this.get('userAction.completed') || this.get('working')) return;
        this.set('working', true);
        var phone = this.get('phone').replace(/\D/g, '');
        var cc = this.get('selectedCountryData.iso2').toUpperCase();
        var options = {
          headers: {},
          data: {
            texting_list_subscription: {
              contest_id: this.get('contest.id'),
              contest_action_id: this.get('model.id'),
              promotion_id: this.get('promotionId'),
              country_alpha2: this.get('isUniversal') ? this.get('countryAlpha2') : null
            }
          }
        };
        if (this.get('model.content.requires_verification')) {
          options.data.texting_list_subscription.phone_verification_attributes = {
            phone: phone,
            country_code: cc
          };
          var authToken = this.get('storage').get('authToken');
          if (authToken) {
            options.headers['x-auth-token'] = authToken;
          }
        } else {
          options.data.texting_list_subscription.unverified_country_code = cc;
          options.data.texting_list_subscription.unverified_phone = phone;
        }
        this.get('ajax').post(_environment.default.APP.API_ROOT + '/texting_list_subscriptions', options).then(function (subscription) {
          _this2.set('working', false);
          if (subscription && subscription.id) {
            _this2.set('textingListSubscriptionId', subscription.id);
            _this2.set('verify', true);
          } else {
            _this2.set('isModalOpen', false);
            _this2.set('userAction.completed', true);
          }
        });
      },
      handleChange: function handleChange(e) {
        this.set('countryAlpha2', e.target.value);
      },
      verify: function verify() {
        var _this3 = this;

        var token = this.token,
            textingListSubscriptionId = this.textingListSubscriptionId;

        this.set('error', null);
        if (token && token.length === 4) {
          this.set('isVerifying', true);
          this.get('ajax').post(_environment.default.APP.API_ROOT + '/texting_list_subscriptions/' + textingListSubscriptionId + '/verify', {
            data: {
              token: token
            }
          }).then(function () {
            _this3.set('isModalOpen', false);
            _this3.set('userAction.completed', true);
          }).catch(function (error) {
            _this3.set('isVerifying', false);
            _this3.set('error', error.payload.message);
          });
        }
      }
    }
  });
});