define('tunespeak-ember/mixins/components/spotify-song-preview-player', ['exports', 'tunespeak-ember/utils/audio'], function (exports, _audio) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_audio.default, {
    mediaManager: Ember.inject.service(),
    store: Ember.inject.service(),
    autostart: false,
    volume: 15,
    player: null,
    trackUrl: null,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('trackUrl')) {
        var player = new _audio.default({
          trackUrl: this.get('trackUrl'),
          autostart: this.get('autostart'),
          volume: this.get('volume')
        });
        this.set('player', player);

        //register player with manager
        this.get('mediaManager').add(player);
      }
    },

    bufferedStyle: Ember.computed('player.buffered', function () {
      return Ember.String.htmlSafe('width: ' + this.get('player.buffered') + '%');
    }),

    progressStyle: Ember.computed('player.progress', function () {
      return Ember.String.htmlSafe('width: ' + Math.round(this.get('player.progress')) + '%');
    }),

    timeLeft: Ember.computed('player.progress', function () {
      //TODO: don't hardcode 30s duration, get from player or song metadata
      return 30 - Math.floor(this.get('player.position'));
    }),

    playerFinishedObserver: Ember.observer('player.isFinished', function () {
      if (this.get('player.isFinished')) {
        this.send('previewComplete');
      }
    }),

    willDestroy: function willDestroy() {
      if (this.get('player')) {
        this.get('mediaManager').destroy(this.get('player'));
      }
    },

    actions: {
      pause: function pause() {
        this.get('mediaManager').pause(this.get('player'));
      },
      play: function play() {
        this.get('mediaManager').play(this.get('player'));
      },
      togglePlay: function togglePlay() {
        if (this.get('trackUrl')) {
          if (this.get('player.isPlaying') || this.get('player.isBuffering')) {
            this.send('pause');
          } else {
            this.send('play');
          }
        }
      },
      previewComplete: function previewComplete() {
        throw Error("Action `previewComplete` not implemented.");
      }
    }
  });
});