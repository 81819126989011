define('tunespeak-ember/components/share/sms-share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    text: null,
    smsBody: Ember.computed('text', 'model.content.sms_body', 'shareLink', function () {
      var smsBody = this.get('text') || this.get('model.content.sms_body');
      if (isMobile.android.device) smsBody = smsBody.replace(/&/g, "and");
      return encodeURIComponent(smsBody + '\nhttp://' + this.get('shareLink'));
    }),
    click: function click() {
      this.get("googleTracking")("sms-share", {
        category: "share",
        label: this.get("contest.title")
      });
    }
  });
});