define('tunespeak-ember/components/album-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['album-filter'],
    filter: '',
    albums: null,

    matchingItems: Ember.computed('filter', 'albums.[]', function () {
      var filter = (this.get('filter') || '').toLowerCase().trim();
      return this.get('albums').map(function (album) {
        var tracks = album.tracks.items;
        var albumHasTracks = false;
        tracks.forEach(function (track) {
          if (track.name.toLowerCase().includes(filter)) {
            Ember.set(track, 'in_filter', true);
            albumHasTracks = true;
          } else {
            Ember.set(track, 'in_filter', false);
          }
        });
        Ember.setProperties(album, {
          in_filter: albumHasTracks,
          items: tracks
        });
        return album;
      });
    }),

    matchingItemsEmpty: Ember.computed('matchingItems', function () {
      return this.get('matchingItems').every(function (album) {
        return !album.in_filter;
      });
    }),

    actions: {
      clearInput: function clearInput() {
        this.set('filter', '');
      }
    }
  });
});