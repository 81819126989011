define('tunespeak-ember/controllers/email-verified', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['success'],
    verified: Ember.computed.equal('success', 'true')
  });
});