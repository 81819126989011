define('tunespeak-ember/serializers/poll-response', ['exports', 'tunespeak-ember/serializers/application', 'tunespeak-ember/mixins/serializers/no-id'], function (exports, _application, _noId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_noId.default, {
    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, modelName, payload) {
      store.pushPayload(payload);
      return this._super.apply(this, arguments);
    }
  });
});