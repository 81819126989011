define('tunespeak-ember/controllers/redemption/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    scrollTo: Ember.inject.service('ts-scroll-to'),
    hideFooter: true,
    questions: null,
    activeIndex: 0,
    currentNumber: Ember.computed('activeIndex', function () {
      return this.get('activeIndex') + 1;
    }),
    complete: Ember.computed('questions.@each.isValid', function () {
      return this.get('questions').isEvery('isValid', true);
    }),
    incomplete: Ember.computed.not('complete'),
    questionCount: Ember.computed.alias('questions.length'),
    isInVenue: Ember.computed.not('model.questions.length'),

    onFirstQuestion: Ember.computed.equal('activeIndex', 0),
    onLastQuestion: Ember.computed('activeIndex', function () {
      return this.get('questionCount') === this.get('activeIndex') + 1;
    }),

    scrollQuestionIntoView: Ember.observer('activeIndex', function () {
      var question = Ember.$('.prize-redemption .questions .redemption-question:eq(' + this.get('activeIndex') + ')');
      this.get('scrollTo').scrollVertical(question, { offset: -60 });
    }),

    setDefaults: function setDefaults() {
      this.setProperties({
        questions: [],
        showMustAttendWarning: false
      });
    },


    actions: {
      addQuestion: function addQuestion(question) {
        this.get('questions').pushObject(question);
      },
      removeQuestion: function removeQuestion(question) {
        this.get('questions').removeObject(question);
      },
      next: function next() {
        if (!this.get('incomplete')) {
          this.transitionToRoute('redemption.review');
        }
      },

      onFocusQuestion: function onFocusQuestion(newIndex) {
        this.set('activeIndex', newIndex);
      },
      onChange: function onChange(question) {
        this.notifyPropertyChange('complete');
        if (question.get('label') === 'able to attend' && question.get('answer') === 'no') {
          this.set('mustAttend', true);
          this.set('showMustAttendWarning', true);
        }
      },
      prevQuestion: function prevQuestion() {
        this.send('onFocusQuestion', this.get('activeIndex') - 1);
      },
      nextQuestion: function nextQuestion() {
        this.send('onFocusQuestion', this.get('activeIndex') + 1);
      },
      dismissMustAttend: function dismissMustAttend() {
        this.set('showMustAttendWarning', false);
      },
      submit: function submit() {
        var _this = this;

        var model = this.get('model');
        this.set('isSubmitting', true);
        model.setProperties({
          submitQuestions: false,
          submitAffidavit: false
        });
        model.save().then(function (redemption) {
          var canadianQuestion = redemption.get('questions').findBy('label', 'canadian');
          // if canadian answer skilled question
          if (canadianQuestion && canadianQuestion.get('answer') === 'yes') {
            _this.transitionToRoute('redemption.skilled-question');
          } else if (redemption.get('affidavit')) {
            _this.transitionToRoute('redemption.release');
          }
        }).finally(function () {
          _this.set('isSubmitting', false);
        });
      }
    }
  });
});