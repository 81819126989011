define('tunespeak-ember/components/campaign/legacy-template', ['exports', 'tunespeak-ember/mixins/components/campaign-template'], function (exports, _campaignTemplate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignTemplate.default, {
    description: Ember.computed('model.description', function () {
      var str = this.get('model.description') || '';
      return str.split(/\r\n?|\n/).join('<br />');
    })
  });
});