define('tunespeak-ember/components/contest-actions/share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    flash: Ember.inject.service('flashMessages'),
    session: Ember.inject.service(),
    classNames: ['ts-list-item', 'campaign-action-item', 'share'],
    classNameBindings: ['isShowingShareLinks:active'],
    isShowingShareLinks: false,
    contest: null,
    creditsName: Ember.computed.alias('contest.creditsName'),
    creditName: Ember.computed.alias('contest.creditName'),
    hasIndirectFactor: Ember.computed.gt('contest.maxIndirectFactor', 1),

    hasIndrectCrediting: Ember.computed.gt('contest.maxIndirectFactor', 0),

    referrals: [],
    hasReferrals: Ember.computed.gt('referrals.length', 0),
    presaleReferral: Ember.computed('hasReferrals', 'referrals', function () {
      return this.get('hasReferrals') && this.get('referrals.firstObject.template') === 'presale-referral';
    }),
    registrationReferral: Ember.computed('hasReferrals', 'referrals', function () {
      return this.get('hasReferrals') && this.get('referrals.firstObject.template') === 'registration-referral';
    }),
    encourageReferral: Ember.computed.gt('contest.indirectEnrollmentPoints', 15),

    isCopied: false,

    isMobile: Ember.computed(function () {
      return isMobile.any;
    }),

    hideP2PShareOptions: Ember.computed('contest.inUK', function () {
      return this.get('contest.inUK') && ['GB', 'UK'].includes(this.get('session.currentUser.cfCountry'));
    }),

    actions: {
      toggleShareRulesModal: function toggleShareRulesModal() {
        this.toggleProperty('isShareRulesModalOpen');
      },
      toggleShare: function toggleShare() {
        this.toggleProperty('isShowingShareLinks');
      },
      didCopyToClipboard: function didCopyToClipboard() {
        var _this = this;

        this.set('isCopied', true);
        this.get('googleTracking')('link-share', {
          category: 'campaign-share',
          label: this.get('title')
        });
        Ember.run.later(function () {
          _this.set('isCopied', false);
        }, 3000);
      },
      didNotCopyToClipboard: function didNotCopyToClipboard() {
        this.set('isCopied', false);
        this.get('flash').danger("Sorry, couldn't copy link to your clipboard.", {
          timeout: 3000,
          extendedTimeout: 500,
          destroyOnClick: false
        });
      }
    }
  });
});