define('tunespeak-ember/mixins/routes/full-screen', ['exports', '@tunespeak/tunespeak-elements/mixins/routes/full-screen'], function (exports, _fullScreen) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fullScreen.default;
    }
  });
});