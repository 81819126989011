define('tunespeak-ember/models/playlist-follow', ['exports', 'ember-data', 'tunespeak-ember/mixins/models/external-follow', 'tunespeak-ember/mixins/models/appreciation-engine-action'], function (exports, _emberData, _externalFollow, _appreciationEngineAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_externalFollow.default, _appreciationEngineAction.default, {
    playlistId: _emberData.default.attr('string'),
    source: _emberData.default.attr('string')
  });
});