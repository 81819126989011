define('tunespeak-ember/components/ts-carousel', ['exports', '@tunespeak/tunespeak-elements/components/ts-carousel'], function (exports, _tsCarousel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tsCarousel.default;
    }
  });
});