define('tunespeak-ember/components/contest-actions/spotify-song-play', ['exports', 'tunespeak-ember/mixins/components/spotify-action', 'ember-data'], function (exports, _spotifyAction, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_spotifyAction.default, {
    classNames: 'spotify-song-play',
    flash: Ember.inject.service('flashMessages'),
    store: Ember.inject.service(),
    isAlbum: false,
    showRemainingModal: false,
    height: Ember.computed('isAlbum', function () {
      return this.get('isAlbum') ? 380 : 80;
    }),
    spotifyUri: Ember.computed('spotifyId', function () {
      return 'track:' + this.get('spotifyId');
    }),
    emailVerified: Ember.computed.alias('session.currentUser.profile.emailVerified'),
    emailEnabled: Ember.computed.and('session.currentUser.profile.emailEnabled', 'session.currentUser.profile.emailCampaignReminder'),

    fetchUserContestAction: function fetchUserContestAction() {
      var _this = this;

      this.get('store').findRecord('user-contest-action', this.get('userAction.id'), {
        reload: true,
        adapterOptions: { contestId: this.get('contest.id') }
      }).then(function (record) {
        _this.set('userAction', record);
      }).catch(function (error) {
        if (error instanceof _emberData.default.UnauthorizedError) {
          _this.get('session').open().then(function () {
            _this.send('didEarnCredit');
          });
        }
      });
    },
    updateRemainingContent: function updateRemainingContent() {
      var _this2 = this;

      var uca = this.get('store').peekRecord('user-contest-action', this.get('userAction.id'));
      this.get('store').query('user-contest-item', {
        contest_id: this.get('contest.id'),
        contest_action_id: uca.get('id')
      }).then(function (activities) {
        uca.set('remainingContent', activities);
        uca.set('remaining', activities.reduce(function (acc, activity) {
          return acc + activity.get('remaining');
        }, 0));
      }).catch(function (error) {
        if (error instanceof _emberData.default.UnauthorizedError) {
          _this2.get('session').open();
        }
      });
    },


    actions: {
      didReceiveCredit: function didReceiveCredit(credit) {
        if (credit.get('points')) {
          this._super(credit);
        }
      },
      didEarnCredit: function didEarnCredit() {
        Ember.run.debounce(this, this.fetchUserContestAction, 1000);
      },
      toggleRemainingModal: function toggleRemainingModal() {
        if (!this.get('showRemainingModal')) {
          this.updateRemainingContent();
        }
        this.toggleProperty('showRemainingModal');
      },

      resendEmailVerification: function resendEmailVerification() {
        var _this3 = this;

        this.get('store').findRecord('user-profile', this.get('session.currentUser.id')).then(function (profile) {
          profile.resendEmailVerification().then(function () {
            _this3.set('didSendEmailVerification', true);
          }).catch(function (response) {
            var error = response.errors;
            _this3.get('flash').danger(error.message, {
              timeout: 5000,
              extendedTimeout: 500,
              destroyOnClick: false
            });
          });
        });
      }
    }
  });
});