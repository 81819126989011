define('tunespeak-ember/components/contest-actions/video-upload', ['exports', 'tunespeak-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['video-upload'],
    playerId: Ember.computed(function () {
      return 'player-' + this.elementId;
    }),
    ajax: Ember.inject.service(),
    ziggeo: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('ziggeo').load().then(function () {
        _this.setupPlayer();
      });
    },
    setupPlayer: function setupPlayer() {
      var _this2 = this;

      var el = document.getElementById(this.get('playerId'));
      if (!el) return;
      window.ZiggeoApi.V2.Locale.setLocale("en");
      var player = new window.ZiggeoApi.V2.Recorder({
        element: el,
        attrs: {
          'delete-old-streams': true,
          'early-rerecord': true,
          rerecordable: true,
          allowupload: true,
          localplayback: true,
          primaryrecord: true,
          responsive: true,
          theme: 'space',
          themecolor: 'black',
          timelimit: this.get('limit') || 60,
          flashincognitosupport: true,
          application: _environment.default.ziggeo.applicationToken,
          picksnapshots: true,
          faceoutline: false,
          recordings: 1,
          stretch: true,
          tags: [this.get('tags')],
          allowedextensions: ['mp4', 'mpg', 'avi', 'mov', 'mkv', 'ogv', 'webm', 'flv'],
          filesizelimit: this.get('fileSizeLimit') || 120000000
        }
      });
      ['uploaded', 'uploading', 'processed', 'processing', 'verified', 'upload_selected', 'rerecord'].forEach(function (event) {
        if (_this2.get(event)) {
          player.on(event, function () {
            _this2.get(event)(player);
          });
        }
      });
      player.activate();
    },
    upload_selected: function upload_selected() {
      // optional callback
    },
    uploaded: function uploaded() {
      // optional callback
    },
    uploading: function uploading() {
      // optional callback
    },
    processed: function processed() {
      // optional callback
    },
    processing: function processing() {
      // optional callback
    },
    verified: function verified() {
      // optional callback
    },
    rerecord: function rerecord() {
      // optional callback
    }
  });
});