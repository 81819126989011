define('tunespeak-ember/services/spotify', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    defaults: Ember.computed('session.currentUser.spotify.access_token', function () {
      return {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': 'Bearer ' + this.get('session.currentUser.spotify.access_token')
        },
        xhrFields: {
          withCredentials: false
        }
      };
    }),
    fetch: function fetch(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      options.method = 'GET';
      return this._spotifyRequest.apply(this, arguments);
    },
    post: function post(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      options.method = 'POST';
      return this._spotifyRequest.apply(this, arguments);
    },
    _spotifyRequest: function _spotifyRequest(url, options) {
      var merged = Object.assign({}, this.get('defaults'), options);
      return this.get('ajax').request(url, merged);
    }
  });
});