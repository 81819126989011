define('tunespeak-ember/components/campaigns/campaign-list-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend({
    classNames: ['campaign-list-item', 'ts-list-item'],
    contest: Ember.computed.alias('model.contestPreview'),
    isRaffle: Ember.computed.equal('contest.type', 'Raffle'),
    isLeaderboard: Ember.computed.equal('contest.type', 'LeaderboardContest'),
    isPresale: Ember.computed.equal('contest.type', 'PresaleContest'),
    isInline: Ember.computed.equal('model.status', 'current')
  });
});