define('tunespeak-ember/components/contest-actions/spotify-playlist-submission', ['exports', 'tunespeak-ember/components/contest-actions/setlist-submission', 'ember-data', 'tunespeak-ember/mixins/components/campaign-action', 'tunespeak-ember/utils/cookie-intent'], function (exports, _setlistSubmission, _emberData, _campaignAction, _cookieIntent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _setlistSubmission.default.extend(_campaignAction.default, {
    classNames: ['setlist-submission'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.get('userAction.completed')) {
        this.get('store').query('user-playlist', { "contest_action_id": this.get('model.id') }).then(function (setlists) {
          Ember.run.next(function () {
            _this.set('setlist', setlists.get('firstObject'));
          });
        });
      } else {
        var init = new Array(this.get('model.songsMin'));
        for (var i = 0; i < init.length; i++) {
          init[i] = {};
        }
        this.setProperties({
          'setlist': init,
          'albums': []
        });
      }
      (0, _cookieIntent.handleFollowOrSaveCookie)(this.get('storage').get('authCookie'), this.get('model.id'), this);
    },
    useAppreciationEngine: function useAppreciationEngine(options) {
      var _this2 = this;

      this.get('appreciationEngine').requireService(options.service).then(function (aeJWT) {
        if (aeJWT) {
          options.record.set('aeJWT', aeJWT);
        }
        options.record.save().then(function (userPlaylist) {
          _this2.setProperties({
            setlist: userPlaylist,
            'userAction.completed': true,
            setlistOpen: false
          });
          _this2.get('session').removeAuthCookie();
        }).catch(function (error) {
          if (error instanceof _emberData.default.UnauthorizedError) {
            Ember.run.next(function () {
              _this2.set('needsReauth', true);
            });
          }
          _this2.get('session').removeAuthCookie();
          _this2.handleSaveError(error, options);
        }).finally(function () {
          _this2.set('working', false);
        });
      }).catch(function () {
        _this2.set('working', false);
      });
    },
    useService: function useService(options) {
      var _this3 = this;

      this.get('session').requireService(options.service).then(function () {
        options.record.save().then(function (userPlaylist) {
          _this3.setProperties({
            setlist: userPlaylist,
            'userAction.completed': true,
            setlistOpen: false
          });
          _this3.get('session').removeAuthCookie();
        }).catch(function (error) {
          if (error instanceof _emberData.default.UnauthorizedError) {
            Ember.run.next(function () {
              _this3.set('needsReauth', true);
            });
          }
          _this3.get('session').removeAuthCookie();
          _this3.handleSaveError(error, options);
        }).finally(function () {
          _this3.set('working', false);
        });
      }).catch(function () {
        _this3.set('working', false);
      });
    },

    actions: {
      openModal: function openModal(index) {
        var _this4 = this;

        this.set('index', index);
        this.set('modalOpen', true);
        if (!this.get('albums.length')) {
          this.set('loading', true);
          this.get('store').findRecord('playlist-call', this.get('model.content.id')).then(function (model) {
            _this4.get('albums').pushObjects(model.get('spotifyAlbumRes.albums'));
          }).finally(function () {
            _this4.set('loading', false);
          });
        }
      },
      closeSpotifyModal: function closeSpotifyModal() {
        this.set('needsReauth', false);
      },
      saveSetlist: function saveSetlist(record) {
        if (!record) {
          record = {
            name: this.get('setlistName') || this.get('session.currentUser.firstName') + '\'s Playlist',
            userId: this.get('session.currentUser.id'),
            source: 'spotify',
            contestId: this.get('contest.id'),
            playlistCallId: this.get('model.content.id'),
            contestActionId: this.get('model.id'),
            promotionId: this.get('promotionId'),
            songs: this.get('setlist').filterBy('type', 'track').map(function (track) {
              return { spotify_id: track.id };
            })
          };
        }
        var options = {};
        options.record = this.get('store').createRecord('user-playlist', record);
        options.intent = 'save-setlist';
        options.service = 'spotify';
        options.actionId = this.get('model.id');
        options.params = [record];
        this.send('saveRecord', options);
      },
      authenticateSpotify: function authenticateSpotify() {
        var _this5 = this;

        if (this.get('useAeService')) {
          this.get('appreciationEngine').requireService('spotify', this.get('contest.id'), true).then(function () {
            _this5.set('needsReauth', false);
            var cookie = _this5.get('storage').get('authCookie');
            if (cookie.action_id === _this5.get('model.id')) {
              _this5.send.apply(_this5, ['saveSetlist'].concat(_toConsumableArray(cookie.params)));
            } else {
              _this5.send('saveSetlist');
            }
          }).catch();
        } else {
          this.get('session').requireService('spotify', true).then(function () {
            _this5.set('needsReauth', false);
            var cookie = _this5.get('storage').get('authCookie');
            if (cookie.action_id === _this5.get('model.id')) {
              _this5.send.apply(_this5, ['saveSetlist'].concat(_toConsumableArray(cookie.params)));
            } else {
              _this5.send('saveSetlist');
            }
          }).catch();
        }
      }
    }
  });
});