define('tunespeak-ember/models/band', ['exports', 'ember-data', 'tunespeak-ember/mixins/models/band'], function (exports, _emberData, _band) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_band.default, {
    albums: _emberData.default.hasMany('album', { async: true }),
    videos: _emberData.default.hasMany('video', { async: true }),
    photoAlbums: _emberData.default.hasMany('photoAlbum', { async: true })
  });
});