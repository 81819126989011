define('tunespeak-ember/components/spotify-login-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    appreciationEngine: Ember.inject.service(),
    isLoggingIn: false,
    contest: null,
    useAeService: Ember.computed.alias('contest.useAeService'),
    actions: {
      close: function close() {
        this.get('close')();
      },
      loginSpotify: function loginSpotify() {
        var _this = this;

        this.set('isLoggingIn', true);
        if (this.get('useAeService')) {
          this.get('appreciationEngine').requireService('spotify', this.get('contest.id'), true).then(function () {
            _this.send('close');
            if (_this.get('didLogin')) {
              _this.get('didLogin')();
            }
          }).finally(function () {
            _this.set('isLoggingIn', false);
          });
        } else {
          this.get('session').loginService('spotify').then(function () {
            _this.send('close');
            if (_this.get('didLogin')) {
              _this.get('didLogin')();
            }
          }).finally(function () {
            _this.set('isLoggingIn', false);
          });
        }
      }
    }
  });
});