define('tunespeak-ember/components/contest-actions/spotify-album-add', ['exports', 'tunespeak-ember/components/contest-actions/spotify-song-add'], function (exports, _spotifySongAdd) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _spotifySongAdd.default.extend({
    layoutName: 'components/contest-actions/spotify-song-add',
    createAdd: function createAdd(playlistId, playlistName) {
      return this.get('store').createRecord('album-add', {
        extPlaylistId: playlistId,
        extPlaylistName: playlistName,
        albumId: this.get('model.content.id'),
        contestId: this.get('contest.id'),
        promotionId: this.get('promotionId'),
        contestActionId: this.get('model.id')
      });
    }
  });
});