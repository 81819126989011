define('tunespeak-ember/components/contest-actions/call-to-action', ['exports', 'tunespeak-ember/mixins/components/campaign-action'], function (exports, _campaignAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignAction.default, {
    classNames: ['call-to-action'],
    modalParam: null,
    modalId: Ember.computed('model.id', function () {
      return 'cta' + this.get('model.id');
    }),
    canDoAgain: Ember.computed('userAction.completed', 'model.nextDirectResetAt', 'contest.endsAt', function () {
      var next = moment(this.get('model.nextDirectResetAt'));
      var contestEnd = moment(this.get('contest.endsAt'));
      return this.get('userAction.completed') && this.get('model.nextDirectResetAt') && next.isBefore(contestEnd);
    }),
    working: false,
    clicked: false,
    isModalOpen: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('modalParam') === this.get('modalId')) {
        Ember.run.scheduleOnce('afterRender', function () {
          _this.set('isModalOpen', true);
        });
      }
    },

    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('isModalOpen');
        if (this.get('isModalOpen')) {
          this.get('googleTracking')('opened-call-to-action-modal', {
            category: 'campaign-action',
            label: this.get('model.content.name') + ' - ' + this.get('model.content.url')
          });
        }
        this.set('modalParam', this.get('isModalOpen') ? this.get('modalId') : null);
      },
      clickLink: function clickLink() {
        this.set('clicked', true);
        this.get("googleTracking")("clicked-call-to-action-link", {
          category: "campaign-action",
          label: this.get("model.content.name") + ' - ' + this.get("model.content.url")
        });
      },
      confirm: function confirm() {
        var _this2 = this;

        this.send('toggleModal');
        this.set('working', true);
        this.get('store').createRecord('cta-response', {
          ctaPromptId: this.get('model.content.id'),
          promotionId: this.get('promotionId'),
          contestId: this.get('contest.id'),
          contestActionId: this.get('model.id')
        }).save().then(function () {
          _this2.set('userAction.completed', true);
          if (_this2.get('model.verifyResponses')) {
            _this2.set('userAction.verifying', true);
          }
          _this2.get('googleTracking')('complete-call-to-action', {
            category: 'campaign-action',
            label: _this2.get('model.content.name') + ' - ' + _this2.get('model.content.url')
          });
        }).finally(function () {
          _this2.set('working', false);
        });
      }
    }
  });
});