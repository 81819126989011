define('tunespeak-ember/components/share/facebook-share', ['exports', 'tunespeak-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    embeddable: Ember.inject.service(),
    isMobile: Ember.computed(function () {
      return isMobile.any;
    }),
    isEmbedded: false, // pass in from parent
    actions: {
      shareFacebook: function shareFacebook() {
        var _this = this;

        this.get("googleTracking")("facebook-share", {
          category: "share",
          label: this.get("contest.title")
        });

        if (this.get('isMobile') || this.get('isEmbedded')) {
          var params = {
            app_id: _environment.default.facebook.appId,
            redirect_uri: window.location.href,
            href: this.get('shareLink'),
            display: 'page'
          };
          params = Ember.$.param(params);
          window.top.location = 'https://www.facebook.com/dialog/share?' + params;
        } else {
          // if (this.get('isEmbedded')) {
          //   this.set('sharing', true);
          //   this.get('embeddable').goFullScreen();
          // }
          return FB.ui({
            method: 'share',
            mobile_iframe: true,
            href: this.get('shareLink')
          }, function () {
            if (_this.get('isEmbedded')) {
              _this.get('embeddable').exitFullScreen();
              _this.set('sharing', false);
            }
          });
        }
      }
    }
  });
});