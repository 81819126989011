define('tunespeak-ember/components/poll-checkbox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isActive: false,
    onSelect: null,
    click: function click() {
      this.toggleProperty('isActive');
      var onSelect = this.onSelect,
          id = this.id,
          isActive = this.isActive;

      onSelect(id, isActive);
      return false;
    },

    actions: {
      togglePhotoModal: function togglePhotoModal(photo) {
        this.get('togglePhotoModal')(photo);
      }
    }
  });
});