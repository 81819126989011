define('tunespeak-ember/components/legal-footer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    contestRules: '',
    legacyContestRules: false,
    rulesModalOpen: false,

    isPresale: Ember.computed.equal('model.type', 'PresaleContest'),
    isSweepstakes: Ember.computed.not('isPresale'),

    entryMethodText: Ember.computed('model.type', function () {
      if (this.get('isPresale')) {
        return 'getting in line';
      }
      return 'entering';
    }),

    recordLabelName: Ember.computed('model.{recordLabelName,recordLabelParent}', function () {
      if (this.get('model.recordLabelParent') === 'sme') {
        return 'Sony Music Entertainment';
      }
      return this.get('model.recordLabelName');
    }),

    sendDataTo: 'the artist(s) and/or their record label',

    actions: {
      fetchRules: function fetchRules() {
        var _this = this;

        this.set('rulesModalOpen', true);
        this.get('ajax').request(this.get('model.rulesPageUrl'), {
          dataType: 'text',
          xhrFields: {
            withCredentials: false
          }
        }).then(function (response) {
          _this.set('contestRules', {
            body: response
          });
        }).catch(function () {
          _this.set('legacyContestRules', true);
        });
      },
      closeRulesModal: function closeRulesModal() {
        this.set('rulesModalOpen', false);
      }
    }
  });
});