define('tunespeak-ember/models/user-consent', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TRUNCATE = 80;

  exports.default = _emberData.default.Model.extend({
    consented: _emberData.default.attr('boolean'),
    consentedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    consent: _emberData.default.attr(),
    consentId: _emberData.default.attr(),
    shouldShowExpand: Ember.computed('consent.description.length', function () {
      return this.get('consent.description.length') > TRUNCATE;
    }),
    truncatedDescription: Ember.computed('consent.description', function () {
      return this.get('consent.description').substr(0, TRUNCATE);
    }),
    descriptionExpanded: false
  });
});