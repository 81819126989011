define('tunespeak-ember/components/contest-actions/typeform-response', ['exports', 'tunespeak-ember/mixins/components/campaign-action', '@typeform/embed'], function (exports, _campaignAction, _embed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignAction.default, {
    classNames: ['ts-list-item', 'campaign-action-item', 'typeform-response'],
    btnLabel: Ember.computed('model.content.btn_label', function () {
      return this.get('model.content.btn_label') || 'START';
    }),

    actions: {
      completeTypeform: function completeTypeform(responseId) {
        var _this = this;

        if (!this.get('userAction.completed')) {
          this.set('userAction.completed', true);

          var typeformResponse = this.get('store').createRecord('typeform-response', {
            promotionId: this.get('promotionId'),
            contestId: this.get('contest.id'),
            contestActionId: this.get('model.id'),
            extId: responseId
          });
          typeformResponse.save().then(function () {
            _this.get("googleTracking")("typeform-response", {
              category: "campaign-action",
              label: _this.get("title")
            });
          });
        }
      },
      openTypeform: function openTypeform() {
        var _this2 = this;

        var _get = this.get('model.content'),
            ext_url = _get.ext_url,
            popup_mode = _get.popup_mode;

        _embed.makePopup(ext_url, {
          mode: popup_mode || 'popup',
          open: 'load',
          autoClose: 3,
          hideScrollbars: true,
          onSubmit: function onSubmit(event) {
            _this2.send('completeTypeform', event.response_id);
          }
        });
      }
    }
  });
});