define('tunespeak-ember/components/contest-actions/spotify-question-answer', ['exports', 'tunespeak-ember/mixins/components/spotify-action', 'tunespeak-ember/models/spotify-answer'], function (exports, _spotifyAction, _spotifyAnswer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_spotifyAction.default, {
    classNames: ['spotify-question-answer'],
    ajax: Ember.inject.service(),
    scopeAnswers: Ember.computed.alias('model.content.scope_answers'),

    answers: null,
    albums: null,
    modalOpen: false,
    loading: false, //Fetching from Spotify or API

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        'answers': [],
        'albums': []
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('userAction.completed')) {
        this.send('fetchAnswers');
      }
    },


    //Pagination
    isLoadingMore: false,
    nextAnswersUrl: null, //pagination for answers
    hasMoreAnswers: true, //set by response of answers

    actions: {
      openModal: function openModal() {
        var _this = this;

        this.set('modalOpen', true);
        if (!this.get('albums.length') && this.get('scopeAnswers')) {
          this.set('loading', true);
          this.get('store').findRecord('spotify-question', this.get('model.content.id')).then(function (model) {
            _this.get('albums').pushObjects(model.get('spotifyAlbumRes.albums'));
          }).finally(function () {
            _this.set('loading', false);
          });
        }
      },
      closeModal: function closeModal() {
        this.set('modalOpen', false);
      },
      selectAnswer: function selectAnswer(song) {
        var _this2 = this;

        this.send('closeModal');
        this.set('working', true);
        var options = {
          questionId: this.get('model.content.id'),
          contestId: this.get('contest.id'),
          spotifyId: song.id,
          promotionId: this.get('promotionId'),
          contestActionId: this.get('model.id')
        };

        if (!this.get('scopeAnswers')) {
          options.songTitle = song.name;
          options.thumbnail = song.album.images[1].url;
          options.previewUrl = song.preview_url;
          options.artistName = song.artists.mapBy('name').join();
          options.albumName = song.album.name;
        }

        this.get('store').createRecord('spotify-answer', options).save().then(function () {
          _this2.set('userAction.completed', true);
          _this2.send('fetchAnswers');
          _this2.get("googleTracking")("spotify-question-answer", {
            category: "campaign-action",
            label: _this2.get("model.content.text")
          });
        }).finally(function () {
          _this2.set('working', false);
        });
      },
      fetchAnswers: function fetchAnswers() {
        var _this3 = this;

        if (!this.get('model.showResponses')) return;

        this.get('store').query('spotify-answer', {
          contest_id: this.get('contest.id'),
          question_id: this.get('model.content.id')
        }).then(function (response) {
          _this3.set('nextAnswersUrl', response.meta.scroll.next_url);
          _this3.set('answers', response.toArray());
        });
      },
      fetchMoreAnswers: function fetchMoreAnswers() {
        var _this4 = this;

        if (!this.get('model.showResponses')) return;
        if (!this.get('isLoadingMore')) {
          this.set('isLoadingMore', true);
          this.get('ajax').request(this.get('nextAnswersUrl')).then(function (response) {
            var answers = response.spotify_answers.toArray();
            if (answers.length) {
              var ApplicationSerializer = Ember.getOwner(_this4).lookup('serializer:application');
              var serializedAnswers = answers.map(function (answer) {
                return ApplicationSerializer.normalize(_spotifyAnswer.default, answer).data.attributes;
              });
              _this4.get('answers').pushObjects(serializedAnswers);
              _this4.set('nextAnswersUrl', response.meta.scroll.next_url);
            } else {
              _this4.set('hasMoreAnswers', false);
            }
          }).finally(function () {
            _this4.set('isLoadingMore', false);
          });
        }
      }
    }
  });
});