define('tunespeak-ember/components/contest-actions/video-view', ['exports', 'tunespeak-ember/mixins/components/video-action', 'tunespeak-ember/utils/jw-player'], function (exports, _videoAction, _jwPlayer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_videoAction.default, {
    classNames: ['video-view'],
    containerClass: 'video-view-container',
    setupPlayer: function setupPlayer() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var player = new _jwPlayer.default({
          playerId: _this.get('playerId'),
          autostart: false,
          height: '100%',
          width: '100%',
          file: _this.get('model.content.url'),
          controls: false,
          onReady: function onReady() {
            _this.set('player', player);
            _this.get('mediaManager').add(player);
            resolve();
          }
        });
      });
    },

    actions: {
      toggleFullscreen: function toggleFullscreen() {
        // iOS only supports requestFullscreen on html5 video element
        if (isMobile.apple.device) {
          if (this.get('isFullscreen')) {
            this.get('fullscreenService').exitFullscreen(this);
          } else {
            Ember.$('#' + this.elementId + ' .jw-video')[0].webkitEnterFullScreen();
          }
        } else {
          // requestFullscreen on the container div with our custom controls
          this._super();
        }
      }
    }
  });
});