define('tunespeak-ember/routes/inbox', ['exports', 'tunespeak-ember/mixins/ts-route-requires-login', 'tunespeak-ember/mixins/ts-route', 'tunespeak-ember/mixins/no-robots', 'tunespeak-ember/mixins/routes/ts-route-requires-activation'], function (exports, _tsRouteRequiresLogin, _tsRoute, _noRobots, _tsRouteRequiresActivation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRouteRequiresLogin.default, _tsRoute.default, _tsRouteRequiresActivation.default, _noRobots.default, {
    model: function model() {
      return this.store.findAll('conversation');
    }
  });
});