define('tunespeak-ember/models/reward', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    themeCss: _emberData.default.attr('string'),
    photo: _emberData.default.attr('string'),
    exhausted: _emberData.default.attr('boolean'),
    exhaustedMessage: _emberData.default.attr('string'),
    endsAt: _emberData.default.attr('date'),
    startsAt: _emberData.default.attr('date'),
    quantity: _emberData.default.attr('number'),
    prizeName: _emberData.default.attr('string'),
    backgroundPhoto: _emberData.default.attr('string'),
    variantLabel: _emberData.default.attr('string'),
    variants: _emberData.default.hasMany('reward-variant'),
    singleVariant: Ember.computed.equal('variants.length', 1),
    questions: _emberData.default.hasMany('reward-question'),

    ended: Ember.computed('endsAt', function () {
      return moment(this.get('endsAt')).isBefore(moment());
    })
  });
});