define('tunespeak-ember/components/campaign-ads/item-ad', ['exports', 'tunespeak-ember/mixins/components/campaign-ad'], function (exports, _campaignAd) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignAd.default, {
    href: Ember.computed('ad.url', function () {
      if (this.get('ad.ctas').length > 1) {
        return location.origin + '/campaign-ads/' + this.get('ad.id');
      }
      return this.get('ad.url') + '&cta=' + this.get('cta');
    }),
    classNames: ['campaign-fad', 'item-fad'],

    cta: Ember.computed.alias('ad.ctas.firstObject'),
    ctaLabel: Ember.computed.alias('ad.description')
  });
});