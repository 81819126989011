define('tunespeak-ember/models/spotify-answer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    questionId: _emberData.default.attr('string'),
    promotionId: _emberData.default.attr('string'),
    contestId: _emberData.default.attr('string'),
    spotifyId: _emberData.default.attr('string'),
    songTitle: _emberData.default.attr('string'),
    thumbnail: _emberData.default.attr('string'),
    previewUrl: _emberData.default.attr('string'),
    artistName: _emberData.default.attr('string'),
    albumName: _emberData.default.attr('string'),
    votes: _emberData.default.attr('number'),
    contestActionId: _emberData.default.attr('string')
  });
});