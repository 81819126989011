define('tunespeak-ember/components/contest-actions/apple-song-add', ['exports', 'ember-ajax/errors', 'tunespeak-ember/mixins/components/spotify-action', 'tunespeak-ember/config/environment'], function (exports, _errors, _spotifyAction, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_spotifyAction.default, {
    classNames: ['ts-list-item', 'campaign-action-item', 'apple-song-add'],
    apple: Ember.inject.service('apple-music'),
    playlists: null,
    showCreatePlaylistInput: false,
    newPlaylistName: '',
    playlistNameInvalid: Ember.computed('newPlaylistName', function () {
      var name = this.get('newPlaylistName').trim();
      return name.length === 0 || name.length > 100;
    }),
    itemsToAdd: Ember.computed('model.content.itunes_id', function () {
      return [{
        id: this.get('model.content.itunes_id'),
        type: 'songs'
      }];
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('apple').load();
      this.set('playlists', []);
    },
    handleError: function handleError(error) {
      this.set('working', false);
      if ((0, _errors.isUnauthorizedError)(error)) {
        this.set('needsReauth', true);
      }
    },
    fetchPlaylists: function fetchPlaylists() {
      var _this = this;

      this.set('isLoadingMore', true);
      if (this.get('useAeService')) {
        var musicUserToken = this.get('appreciationEngine').getAppleMusicToken() || this.get('appreciationEngine').getService('applemusic').Token;
        this.get('ajax').request(_environment.default.APP.API_ROOT + '/apple_playlists?music_user_token=' + encodeURIComponent(musicUserToken)).then(function (res) {
          _this.set('playlists', Ember.A(res.data || []).filter(function (el) {
            return el.attributes.canEdit;
          }));
        }).catch(function () {
          _this.set('needsReauth', true);
        }).finally(function () {
          _this.set('isLoadingMore', false);
        });
      } else {
        this.get('apple').fetchPlaylists().then(function (res) {
          _this.set('playlists', Ember.A(res.data).filter(function (el) {
            return el.attributes.canEdit;
          }));
        }).catch(function () {
          _this.set('needsReauth', true);
        }).finally(function () {
          _this.set('isLoadingMore', false);
        });
      }
    },
    _createPlaylist: function _createPlaylist() {
      var _this2 = this;

      if (this.get('useAeService')) {
        var musicUserToken = this.get('appreciationEngine').getAppleMusicToken() || this.get('appreciationEngine').getService('applemusic').Token;
        var body = {
          contentType: 'application/json; charset=utf-8',
          data: {
            apple_playlist: {
              music_user_token: musicUserToken,
              name: this.get('newPlaylistName'),
              songs: this.get('itemsToAdd')
            }
          }
        };
        this.get('ajax').post(_environment.default.APP.API_ROOT + '/apple_playlists', body).then(function (res) {
          _this2.createAdd(res.data[0].id, _this2.get('newPlaylistName')).save().then(function () {
            _this2.completeAction();
          }).finally(function () {
            _this2.set('working', false);
          });
        }).catch(function (error) {
          _this2.handleError(error);
        });
      } else {
        this.get('apple').createPlaylist(this.get('newPlaylistName'), this.get('itemsToAdd')).then(function (res) {
          _this2.createAdd(res.data[0].id, _this2.get('newPlaylistName')).save().then(function () {
            _this2.completeAction();
          }).finally(function () {
            _this2.set('working', false);
          });
        }).catch(function (error) {
          _this2.handleError(error);
        });
      }
    },
    createAdd: function createAdd(playlistId, playlistName) {
      return this.get('store').createRecord('apple-song-add', {
        extPlaylistId: playlistId,
        extPlaylistName: playlistName,
        songId: this.get('model.content.id'),
        contestId: this.get('contest.id'),
        promotionId: this.get('promotionId'),
        contestActionId: this.get('model.id')
      });
    },
    completeAction: function completeAction() {
      this.set('userAction.completed', true);
      this.get('googleTracking')('apple-' + this.get('type') + '-add', {
        category: 'campaign-action',
        label: this.get('model.title')
      });
    },


    actions: {
      openModal: function openModal() {
        if (this.get('session.isEmbeddedWebView')) {
          this.send('toggleWebViewModal');
          return;
        }
        var musicUserToken = this.get('appreciationEngine').getAppleMusicToken() || this.get('appreciationEngine').getService('applemusic').Token;
        if (!this.get('useAeService') && this.get('apple.kit.isAuthorized') || this.get('useAeService') && !!musicUserToken) {
          this.fetchPlaylists();
          this.set('modalOpen', true);
        } else {
          this.send('authenticateApple');
        }
      },
      closeModal: function closeModal() {
        this.set('modalOpen', false);
      },
      addToPlaylist: function addToPlaylist(playlistId, playlistName) {
        var _this3 = this;

        this.send('closeModal');
        if (this.get('useAeService')) {
          var options = {
            record: this.createAdd(playlistId, playlistName),
            intent: 'add-to-playlist',
            params: [].concat(Array.prototype.slice.call(arguments)),
            service: 'applemusic',
            actionId: this.get('model.id')
          };
          this.send('saveRecord', options);
        } else {
          this.set('working', true);
          this.get('apple').addToPlaylist(playlistId, this.get('itemsToAdd')).then(function () {
            _this3.createAdd(playlistId, playlistName).save().then(function () {
              _this3.completeAction();
            }).finally(function () {
              _this3.set('working', false);
            });
          }).catch(function (error) {
            _this3.handleError(error);
          });
        }
      },
      createPlaylist: function createPlaylist() {
        this.send('closeModal');
        this.set('working', true);
        this._createPlaylist();
      },
      authenticateApple: function authenticateApple() {
        var _this4 = this;

        if (this.get('useAeService')) {
          this.get('appreciationEngine').requireService('applemusic', this.get('contest.id'), true).then(function () {
            _this4.fetchPlaylists();
            _this4.setProperties({
              modalOpen: true,
              needsReauth: false
            });
          });
        } else {
          this.get('apple').authorize().then(function () {
            _this4.fetchPlaylists();
            _this4.setProperties({
              modalOpen: true,
              needsReauth: false
            });
          });
        }
      },
      toggleWebViewModal: function toggleWebViewModal() {
        this.toggleProperty('showWebViewModal');
      }
    }
  });
});