define('tunespeak-ember/controllers/setlist', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    hideFooter: true,
    campaign: Ember.computed.or('model.contestGroupPreview', 'model.contestPreview'),
    campaignRoute: Ember.computed('campaign.type', function () {
      return this.get('campaign.type') === 'ContestGroup' ? 'tour' : 'campaign';
    })
  });
});