define('tunespeak-ember/serializers/contest-group', ['exports', 'ember-data', 'tunespeak-ember/serializers/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      contestPreviews: { embedded: 'always' },
      contestBands: { embedded: 'always' },
      htmlSnippets: { embedded: 'always' }
    }
  });
});