define('tunespeak-ember/models/reward-question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    label: _emberData.default.attr('string'),
    placeholder: _emberData.default.attr('string'),
    variantId: _emberData.default.attr('string'),
    answerOptions: _emberData.default.attr(),
    inputType: _emberData.default.attr('string'),
    autocomplete: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    groupPosition: _emberData.default.attr('number'),
    groupName: _emberData.default.attr('string'),
    group: Ember.computed('name', function () {
      return this.get('name').split('_')[0];
    }),
    isSelectInput: Ember.computed.equal('inputType', 'select'),
    isCheckboxInput: Ember.computed.equal('inputType', 'checkbox'),
    reward: _emberData.default.belongsTo('reward', { async: false }),
    value: _emberData.default.attr('string')
  });
});