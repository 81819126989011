define('tunespeak-ember/helpers/ts-time-string', ['exports', '@tunespeak/tunespeak-elements/helpers/ts-time-string'], function (exports, _tsTimeString) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tsTimeString.default;
    }
  });
  Object.defineProperty(exports, 'tsTimeString', {
    enumerable: true,
    get: function () {
      return _tsTimeString.tsTimeString;
    }
  });
});