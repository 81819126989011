define('tunespeak-ember/models/video', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    band: _emberData.default.belongsTo('band', { async: false }),
    extId: _emberData.default.attr('string'),
    lengthDisplay: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    stillframe: _emberData.default.attr('string'),
    uri: _emberData.default.attr('string'),
    viewCount: _emberData.default.attr('number'),
    verb: 'Watch'
  });
});