define('tunespeak-ember/adapters/in-venue-rsvp', ['exports', 'tunespeak-ember/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    queryRecord: function queryRecord(store, type, query) {
      return this.findRecord(store, type, query.in_venue_id);
    }
  });
});