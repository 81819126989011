define('tunespeak-ember/helpers/ts-equal', ['exports', '@tunespeak/tunespeak-elements/helpers/ts-equal'], function (exports, _tsEqual) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tsEqual.default;
    }
  });
  Object.defineProperty(exports, 'tsEqual', {
    enumerable: true,
    get: function () {
      return _tsEqual.tsEqual;
    }
  });
});