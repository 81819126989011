define('tunespeak-ember/app', ['exports', 'ember-data', 'tunespeak-ember/resolver', 'ember-load-initializers', 'tunespeak-ember/config/environment', 'airbrake'], function (exports, _emberData, _resolver, _emberLoadInitializers, _environment, _airbrake) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  FastClick.attach(document.body, {
    excludeNode: '^pac-'
  });

  Ember.$.ajaxSetup({
    crossDomain: true,
    xhrFields: {
      withCredentials: true
    }
  });

  if (_environment.default.environment !== 'development' && _environment.default.environment !== 'test') {
    var airbrakeClient = new _airbrake.default.Client({
      projectId: _environment.default.airbrake.projectId,
      projectKey: _environment.default.airbrake.projectKey
    });

    airbrakeClient.addFilter(function (notice) {
      notice.context.environment = _environment.default.environment;
      return notice;
    });

    airbrakeClient.addFilter(function (notice) {
      if (notice && notice.errors & notice.errors[0] && notice.errors[0].backtrace && notice.errors[0].backtrace.find && notice.errors[0].backtrace.find(function (trace) {
        return trace.file.includes("injectCoinbaseWalletDappProvider");
      })) {
        return null;
      }
      return notice;
    });

    Ember.onerror = function (error) {
      if (error && error.errors && error.errors[0] && error.errors[0].detail === 'The adapter operation was aborted') {
        return;
      }
      airbrakeClient.notify(error);
    };

    Ember.RSVP.on('error', function (err) {
      if (!err || err && err.name === 'TransitionAborted') return;
      airbrakeClient.notify(err);
    });
  }

  _emberData.default.Model.reopen({
    adapter: Ember.computed(function () {
      return Ember.getOwner(this).lookup('adapter:' + this.constructor.modelName);
    })
  });

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});