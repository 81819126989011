define('tunespeak-ember/models/user-band', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    bandName: _emberData.default.attr('string'),
    bandPhoto: _emberData.default.attr('string'),
    notificationsEnabled: _emberData.default.attr('boolean', { defaultValue: true }),
    messagingEnabled: _emberData.default.attr()
  });
});