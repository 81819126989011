define('tunespeak-ember/components/ts-countdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ts-countdown'],
    from: null,
    to: null,
    interval: null,
    diff: Ember.computed('from', 'to', function () {
      var ret = {};

      var diff = Math.max(0, this.get('to') - this.get('from'));
      if (diff === 0) {
        this._countdownComplete();
      }

      var dur = moment.duration(diff, 'milliseconds');
      ret.days = Math.floor(dur.asDays());
      ret.hours = dur.hours();
      ret.minutes = dur.minutes();
      ret.seconds = dur.seconds() < 10 ? '0' + dur.seconds() : dur.seconds();
      return ret;
    }),

    days: Ember.computed.alias('diff.days'),
    hours: Ember.computed.alias('diff.hours'),
    minutes: Ember.computed.alias('diff.minutes'),
    seconds: Ember.computed.alias('diff.seconds'),

    countDown: function countDown() {
      var _this = this;

      this.set('from', new Date());
      this.set('interval', Ember.run.later(function () {
        _this.countDown();
      }, 1000));
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        from: moment(this.get('from')) || new Date(),
        to: moment(this.get('to')) || new Date()
      });
      this.countDown();
    },
    stopCountDown: function stopCountDown() {
      Ember.run.cancel(this.get('interval'));
    },
    _countdownComplete: function _countdownComplete() {
      this.stopCountDown();
      this.get('countdownComplete')();
    },
    countdownComplete: function countdownComplete() {
      // optional callback fired on countdown finish
    },
    willDestroyElement: function willDestroyElement() {
      this.stopCountDown();
    },


    dayLabel: 'day',
    hourLabel: 'hr',
    minuteLabel: 'm',
    secondLabel: 's',
    daysLabel: 'days',
    hoursLabel: 'hrs',
    minutesLabel: 'm',
    secondsLabel: 's',

    showDays: Ember.computed.gt('days', 0),
    showHours: Ember.computed('days', 'hours', function () {
      var days = this.get('days');
      return days > 0 || days === 0 && this.get('hours') > 0;
    }),
    showMinutes: Ember.computed('days', 'hours', function () {
      return this.get('days') === 0 && this.get('hours') >= 0;
    }),
    showSeconds: Ember.computed('days', 'hours', function () {
      return this.get('days') === 0 && this.get('hours') === 0;
    })
  });
});