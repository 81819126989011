define('tunespeak-ember/utils/image-orientation', ['exports', '@tunespeak/tunespeak-elements/utils/image-orientation'], function (exports, _imageOrientation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.keys(_imageOrientation).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(exports, key, {
      enumerable: true,
      get: function () {
        return _imageOrientation[key];
      }
    });
  });
});