define('tunespeak-ember/mixins/serializers/no-id', ['exports', '@tunespeak/tunespeak-elements/mixins/serializers/no-id'], function (exports, _noId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _noId.default;
    }
  });
});