define('tunespeak-ember/services/require-consents', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var deferred = void 0;

  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    showModal: false,
    consents: null,

    cancel: function cancel() {
      this.set('showModal', false);
      this.set('consents', null);
      deferred.reject();
    },
    done: function done() {
      this.set('showModal', false);
      this.set('consents', null);
      deferred.resolve();
    },
    requireConsents: function requireConsents() {
      var _this = this;

      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      deferred = new Ember.RSVP.defer();
      if (options.enrollmentConsentIds && options.enrollmentConsentIds.length > 0) {
        this.get('store').query('consent', {
          ids: options.enrollmentConsentIds.join(','),
          pending: true
        }).then(function (consents) {
          if (consents.get('length')) {
            _this.setProperties({
              showModal: true,
              consents: consents.toArray()
            });
          } else {
            deferred.resolve();
          }
        });
      } else {
        deferred.resolve();
      }
      return deferred.promise;
    }
  });
});