define('tunespeak-ember/torii-providers/session-facebook', ['exports', '@tunespeak/tunespeak-elements/torii-providers/session-facebook'], function (exports, _sessionFacebook) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sessionFacebook.default;
    }
  });
  Object.defineProperty(exports, 'fbInitPromise', {
    enumerable: true,
    get: function () {
      return _sessionFacebook.fbInitPromise;
    }
  });
  Object.defineProperty(exports, 'fbStatusPromise', {
    enumerable: true,
    get: function () {
      return _sessionFacebook.fbStatusPromise;
    }
  });
});