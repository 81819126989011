define('tunespeak-ember/utils/soundcloud-player', ['exports', 'tunespeak-ember/utils/enum', 'tunespeak-ember/utils/media-player'], function (exports, _enum, _mediaPlayer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.State = undefined;
  exports.log = log;
  var State = exports.State = new _enum.default({
    Idle: -1,
    Finished: 0,
    Playing: 1,
    Paused: 2,
    Buffering: 3
  });

  var DEBUG_MODE = true;

  function log() {
    if (DEBUG_MODE) {
      var _console;

      (_console = console).log.apply(_console, arguments);
    }
  }

  var SoundcloudPlayer = _mediaPlayer.default.extend({
    playerId: null,
    width: '100%',
    height: '100%',
    pollingId: null,
    playerVars: null,

    setupPlayer: function setupPlayer() {
      log('SETUP SOUNDCLOUD PLAYER');
      var SC = window.SC;
      var widget = SC.Widget(this.get('playerId'));
      var self = this;
      widget.bind(SC.Widget.Events.READY, function (event) {
        self.onStateChange(State.Idle, null, event);
        self.onReady();
      });
      widget.bind(SC.Widget.Events.FINISH, function (event) {
        widget.getDuration(function (duration) {
          self.onStateChange(State.Finished, duration, event);
        });
      });
      widget.bind(SC.Widget.Events.PLAY, function (event) {
        self.onStateChange(State.Playing, null, event);
      });
      widget.bind(SC.Widget.Events.PAUSE, function (event) {
        self.onStateChange(State.Paused, null, event);
      });
      widget.bind(SC.Widget.Events.LOAD_PROGRESS, function () {
        self.onStateChange(State.Buffering);
      });
      this.set('api', widget);
    },


    play: function play() {
      if (this.get('api.play')) {
        this.get('api').play();
      }
    },

    pause: function pause() {
      if (this.get('api.pause')) {
        this.get('api').pause();
      }
    },

    willDestroy: function willDestroy() {
      this.get('api').destroy();
      this._stopPolling();
    },

    // Custom Polling for YouTube api
    _startPolling: function _startPolling() {
      var self = this;
      this._stopPolling();
      this.set('pollingId', setInterval(function () {
        self._onPolling();
      }, 500));
    },

    _stopPolling: function _stopPolling() {
      clearInterval(this.get('pollingId'));
    },

    _onPolling: function _onPolling() {
      var _this = this;

      this.get('api').getPosition(function (position) {
        var lastPosition = _this.get('position');
        var newPosition = Math.ceil(position);
        var diff = newPosition - lastPosition;
        if (Math.abs(diff) > 3000) {
          _this.onSeek(newPosition);
        } else {
          _this.get('api').getDuration(function (duration) {
            _this.whilePlaying(newPosition, Math.ceil(duration));
          });
        }
      });
    },

    // Custom callbacks for YT state changes
    onReady: function onReady() {
      this._super();
    },
    onStart: function onStart() {
      this._startPolling();
    },
    onResume: function onResume() {
      this._startPolling();
    },
    onPause: function onPause() {
      this._stopPolling();
    },
    onBuffering: function onBuffering() {
      this._stopPolling();
    },
    onFinish: function onFinish(mediaDuration) {
      this._stopPolling();
      // 1 second leeway. The youtube api is sometimes off by 1 second.
      if (this.get('totalPlayed') >= mediaDuration - 1000) {
        this.set('totalPlayed', mediaDuration);
      }
    }
  });

  SoundcloudPlayer.reopenClass({
    loadApi: function loadApi() {
      var _this2 = this;

      Ember.$.getScript("https://w.soundcloud.com/player/api.js").then(function () {
        Ember.run(function () {
          return _this2.resolveApiLoad();
        });
      });
    },

    toString: function toString() {
      return "SoundcloudPlayer";
    }
  });

  exports.default = SoundcloudPlayer;
});