define('tunespeak-ember/controllers/reward', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    backgroundImage: Ember.computed.alias('model.backgroundPhoto'),
    store: Ember.inject.service(),
    claim: null,
    status: '',
    isIneligible: Ember.computed.equal('status', 'ineligible'),
    isEligible: Ember.computed.equal('status', 'eligible'),
    isFull: Ember.computed.equal('status', 'exhausted'),
    isClaimed: Ember.computed.equal('status', 'claimed'),
    working: false,
    success: false,
    showClaimForm: false,
    showQuestions: Ember.computed.gt('relevantQuestions.length', 1),
    showVariantSelection: Ember.computed.gt('model.variants.length', 1),
    showQuestionsOrVariants: Ember.computed.or('showQuestions', 'showVariantSelection'),

    loggedInAndVerified: Ember.computed('session.currentUser', function () {
      return this.get('session.currentUser') && this.get('session.currentUser.profile.emailVerified');
    }),

    relevantQuestions: Ember.computed('variantId', 'model.questions.@each.variantId', function () {
      var _this = this;

      return this.get('model.questions').filter(function (question) {
        var vid = question.get('variantId');
        return !vid || vid == _this.get('variantId');
      });
    }),

    groupedRelevantQuestions: Ember.computed('relevantQuestions', function () {
      var groups = {};
      this.get('relevantQuestions').forEach(function (q) {
        var group = q.get('group');
        // group doesn't exist yet, create it and set its position
        if (!groups[group]) {
          groups[group] = {};
          groups[group].questions = [];
          groups[group].position = q.get('position');
        }
        // add item to the group at its group-position index
        // will overwrite if position is already taken
        groups[group].questions.splice(q.get('groupPosition'), 0, q);
      });

      // build iterable of ordered groups to loop through
      var ret = [];
      Object.keys(groups).forEach(function (groupKey) {
        var group = groups[groupKey];
        ret.splice(group.position, 0, group.questions);
      });
      return ret;
    }),

    // form fields
    firstName: null,
    lastName: null,
    birthday: null,
    validId: null,
    variantId: null,
    answers: Ember.A(),

    firstNameError: null,
    lastNameError: null,
    birthdayError: null,
    validIdError: null,
    variantIdError: null,

    clearErrors: function clearErrors() {
      this.setProperties({
        firstNameError: null,
        lastNameError: null,
        birthdayError: null,
        validIdError: null,
        variantIdError: null
      });
      this.get('model.questions').setEach('error', null);
    },
    formatAttributeName: function formatAttributeName(value) {
      return value.underscore().split('_').join(' ').capitalize();
    },
    formValid: function formValid() {
      var errors = 0;
      // if (!this.get('firstName')) {
      //   this.set('firstNameError', "First name can't be blank");
      //   errors++;
      // }

      // if (!this.get('lastName')) {
      //   this.set('lastNameError', "Last name can't be blank");
      //   errors++;
      // }

      // if (!this.get('validId')) {
      //   this.set('validIdError', "Must have a valid government issued photo ID. Please have a parent or legal guardian fill out this form who can accompany you to pick up your reward.");
      //   errors++;
      // }

      if (this.get('showVariantSelection') && !this.get('variantId')) {
        this.set('variantIdError', "Must select an option");
        errors++;
      }

      // Assume all fields are required for now.
      this.get('relevantQuestions').forEach(function (question) {
        if (!question.get('value')) {
          question.set('error', question.get('label') + ' is required.');
          errors++;
        }
      });

      // const minDate = moment().subtract(16, 'years');
      // const formattedBirthday = moment(this.get('birthday'), 'MM/DD/YYYY');
      // if (!formattedBirthday.isSame(minDate, 'day') && !formattedBirthday.isBefore(minDate, 'day')) {
      //   this.set('birthdayError', 'Birthday must be over 16 years ago and valid');
      //   errors++;
      // }
      return errors === 0;
    },


    actions: {
      checkIfUserCanClaim: function checkIfUserCanClaim() {
        var user = this.get('session.currentUser');
        if (user && user.get('profile.emailVerified')) {
          this.set('showClaimForm', true);
        } else {
          this.transitionToRoute('/login?redirect=' + this.get('session').encodeRouteInfo());
        }
      },
      claimReward: function claimReward() {
        var _this2 = this;

        if (this.get('working')) {
          return;
        }
        this.clearErrors();
        if (this.formValid()) {
          this.set('working', true);
          var firstName = this.firstName,
              lastName = this.lastName,
              birthday = this.birthday,
              variantId = this.variantId,
              answers = this.answers;
          // add form answers

          this.get('relevantQuestions').forEach(function (q) {
            answers.pushObject({ question_id: q.get('id'), value: q.get('value') });
          });
          var options = {
            rewardId: this.get('model.id'),
            // firstName: firstName,
            // lastName: lastName,
            // birthday: moment(birthday, 'MM/DD/YYYY').format('DD/MM/YYYY'),
            variantId: variantId || this.get('model.variants.firstObject.id'),
            answers: answers
          };
          var claim = this.get('store').createRecord('claim', options);
          claim.save().then(function (res) {
            _this2.setProperties({
              claim: res,
              success: true
            });
          }).catch(function () {
            var rewardErrors = claim.get('errors.reward');
            var recipientErrors = claim.get('errors.recipient');
            if (rewardErrors && rewardErrors[0]) {
              var error = rewardErrors[0].message;
              if (error === 'has ended') {
                _this2.set('model.ended', true);
              } else if (error === 'has been exhausted') {
                if (!_this2.get('showVariantSelection')) {
                  _this2.set('status', 'exhausted');
                } else {
                  _this2.set('variantIdError', 'This reward is no longer available');
                  _this2.model.reload();
                }
              }
            }
            if (recipientErrors && recipientErrors[0]) {
              var _error = recipientErrors[0].message;
              if (_error === 'has already claimed') {
                window.location.reload();
              } else if (_error === 'is not eligible') {
                _this2.set('status', 'ineligible');
              }
            }
          }).finally(function () {
            _this2.set('working', false);
          });
        }
      },
      selectVariant: function selectVariant(variantId) {
        var _this3 = this;

        this.set('variantId', variantId);

        this.set('answers', this.get('relevantQuestions').reduce(function (acc, question) {
          var a = _this3.get('answers').findBy('question_id', question.get('id'));
          if (a) {
            acc.pushObject(a);
          }
          return acc;
        }, []));
      }
    }
  });
});