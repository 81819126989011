define('tunespeak-ember/components/ts-mario-coin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isVisible: false,
    classNames: ['ts-mario-coin'],
    classNameBindings: ['isVisible:its-a-me']
  });
});