define('tunespeak-ember/instance-initializers/browser/head', ['exports', 'tunespeak-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(owner) {
    if (_environment.default['ember-cli-head'] && _environment.default['ember-cli-head']['suppressBrowserRender']) {
      return true;
    }

    // clear fast booted head (if any)
    Ember.$('meta[name="ember-cli-head-start"]').nextUntil('meta[name="ember-cli-head-end"] ~').addBack().remove();

    var component = owner.lookup('component:head-layout');
    component.appendTo(document.head);
  }

  exports.default = {
    name: 'head-browser',
    initialize: initialize
  };
});