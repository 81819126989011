define('tunespeak-ember/torii-providers/soundcloud', ['exports', '@tunespeak/tunespeak-elements/torii-providers/soundcloud'], function (exports, _soundcloud) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _soundcloud.default;
    }
  });
});