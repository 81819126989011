define('tunespeak-ember/mixins/enrollment-warnings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var COUNTRY_CODES = {
    GB: 'the United Kingdom',
    US: 'the United States',
    CA: 'Canada',
    DE: 'Germany',
    FR: 'France',
    ES: 'Spain',
    AU: 'Australia',
    AR: 'Argentina',
    MX: 'Mexico'
  };

  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),
    cfCountry: Ember.computed.alias('session.currentUser.cfCountry'),
    enrollmentWarnings: Ember.computed('model.{allowedCountries,warnings}', 'cfCountry', function () {
      var warnings = [];
      if (this.get('model.warnings')) {
        warnings.pushObjects(this.get('model.warnings'));
      }
      if (!this.userInAllowedCountry()) {
        warnings.pushObject({
          isCountryWarning: true,
          allowedCountries: this.get('model.allowedCountries'),
          allowedCountriesFull: this.get('model.allowedCountries').map(function (cc) {
            return COUNTRY_CODES[cc];
          })
        });
      }
      return warnings;
    }),

    userInAllowedCountry: function userInAllowedCountry() {
      var _this = this;

      var allowed = this.get('model.allowedCountries');
      if (allowed && allowed.length) {
        return allowed.find(function (code) {
          return code === _this.get('cfCountry');
        });
      }
      return true;
    }
  });
});