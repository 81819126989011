define('tunespeak-ember/services/appreciation-engine', ['exports', 'tunespeak-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var deferred = new Ember.RSVP.defer(); // AE loading promise
  var loading = false; // AE has started being fetched
  var loaded = false; // AE has loaded

  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    aejs: null,
    widgetId: null,
    authorizeAppleMusicModal: false,
    callback: null,

    _checkAeJsLoaded: function _checkAeJsLoaded(reject) {
      if (!loaded) {
        reject(new Error('AE JS SDK Not Ready'));
      }
      return loaded;
    },
    loaded: function loaded() {
      return deferred.promise;
    },
    load: function load(widgetId) {
      var _this = this;

      var segmentId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (!loading) {
        loading = true;

        window.AEJSReady = function (aeJS) {
          aeJS.events.onLoaded.addHandler(function () {
            window.aejs = aeJS;
            aeJS.settings['auth_window'] = false;
            _this.set('aejs', aeJS);
            loaded = true;
            deferred.resolve(aeJS);
          });
        };

        var src = _environment.default.AE.URL + '/framework/js/' + widgetId;
        this.set('widgetId', widgetId);

        if (segmentId) {
          src += '?segment=' + segmentId;
        }

        // fetch AE JS SDK
        (function (d, s, id) {
          var js,
              fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = src;
          fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'aejs-script');
      }

      return deferred.promise;
    },
    _removeHandlers: function _removeHandlers(eventName) {
      var aejs = this.get('aejs');
      var handlers = aejs.events[eventName].getListeners();
      handlers.forEach(function (handler) {
        return aejs.events[eventName].removeHandler(handler);
      });
    },
    _registerAndLogin: function _registerAndLogin(_ref) {
      var _this2 = this;

      var service = _ref.service,
          contest_id = _ref.contest_id,
          resolve = _ref.resolve,
          reject = _ref.reject;

      var aejs = window.aejs;

      // user authenticated with apple
      aejs.events.onUser.addHandler(function (user) {
        resolve(user.jwt);
      });

      this.get('ajax').post(_environment.default.APP.API_ROOT + '/ae_member_registers', {
        data: {
          contest_id: contest_id,
          ae_jwt: this.get('aejs.user.jwt')
        }
      }).then(function (res) {
        aejs.events.onLogin.addHandler(function (ae_res) {
          Ember.run.next(function () {
            if (service === 'applemusic') {
              // open modal to prevent from being popup blocked - set to resolve function for use in modal component
              _this2.set('callback', resolve);
              _this2.set('authorizeAppleMusicModal', true);
            } else {
              // manually update aejs user
              _this2.set('aejs.user', ae_res);
              resolve(ae_res.jwt);
            }
          });
        });
        aejs.trigger.login(res.token);
        // // manually login with token - since trigger login wasn't firing callback correctly
        // this.get('ajax').request(`${config.AE.URL}/framework-${config.AE.version}/auth_state/${this.get('widgetId')}/${res.token}?ae_token_login=1`).then((res) => {
        //   if (service === 'applemusic') {
        //     // open modal to prevent from being popup blocked - set to resolve function for use in modal component
        //     this.set('callback', resolve);
        //     this.set('authorizeAppleMusicModal', true);
        //   } else {
        //     // manually update aejs user
        //     this.set('aejs.user', res.user);
        //     resolve(res.user.jwt);
        //   }
        // });
      });
    },
    authorize: function authorize(service, callback) {
      var _this3 = this;

      var aejs = window.aejs;
      aejs.events.onUser.addHandler(function (user) {
        callback(user.jwt);
        _this3.set('authorizeAppleMusicModal', false);
      });
      aejs.trigger.authenticate(service, 'auth', window.location.href);
    },
    getService: function getService(service) {
      var aejs = this.get('aejs');
      return aejs && aejs.get && aejs.get.user() && aejs.get.user().services && aejs.get.user().services.find(function (s) {
        return s.Service === service;
      }) || {};
    },
    getJWT: function getJWT() {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        deferred.promise.then(function (aeJS) {
          resolve(aeJS.user && aeJS.user.jwt);
        });
      });
    },
    getAppleMusicToken: function getAppleMusicToken() {
      var aejs = this.get('aejs');
      return aejs && aejs.musickit && aejs.musickit.musicUserToken;
    },
    requireService: function requireService(service, contest_id, force) {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        deferred.promise.then(function (aeJS) {
          var user = aeJS.get.user();
          if (user.data) {
            var aeService = aeJS.user.services.find(function (s) {
              return s.Service === service && s.Domains.includes(window.location.hostname.replace('www.', ''));
            });
            // service found
            if (aeService && !force) {
              // if email service && tunespeak email don't match, force register again with tunespeak email
              if (service === 'email' && aeService.Username !== _this4.get('session.currentUser.profile.email')) {
                _this4._registerAndLogin({ service: service, contest_id: contest_id, resolve: resolve });
                // else resolve with token
              } else {
                resolve(aeJS.user.jwt);
              }
              // No service found
            } else {
              // email isn't an ae-service
              if (service === 'email') {
                _this4._registerAndLogin({ service: service, contest_id: contest_id, resolve: resolve });
              } else {
                aeJS.events.onUser.addHandler(function (user) {
                  resolve(user.jwt);
                });
                _this4.get('aejs').trigger.authenticate(service, 'auth');
              }
            }
          } else {
            // if applemusic or email use our endpoint to register an email user
            if (service === 'applemusic' || service === 'email') {
              _this4._registerAndLogin({ service: service, contest_id: contest_id, resolve: resolve });
            } else {
              aeJS.events.onUser.addHandler(function (user) {
                resolve(user.jwt);
              });
              aeJS.trigger.authenticate(service, 'register');
              // reject(); //?? or is there an event to listen for successful register & resolve  
            }
          }
        });
      });
    }
  });
});