define('tunespeak-ember/routes/redemption', ['exports', 'tunespeak-ember/mixins/ts-route', 'tunespeak-ember/mixins/no-robots'], function (exports, _tsRoute, _noRobots) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tsRoute.default, _noRobots.default, {
    model: function model(params, transition) {
      var _this = this;

      return this.store.findRecord('redemption', params.id, {
        adapterOptions: {
          token: transition.queryParams.token
        }
      }).catch(function (error) {
        if (error.code === 403) {
          transition.abort();
          if (_this.get('session.currentUser')) {
            window.location = '/404';
          } else {
            _this.transitionTo('/login?redirect=' + _this.get('session').encodeRouteInfo());
          }
        }
      });
    },
    afterModel: function afterModel(model, transition) {
      var token = transition.queryParams.token;
      model.set('token', token);
      if (!model.get('available')) {
        this.transitionTo('redemption.index');
      }
    },

    activate: function activate() {
      var _this2 = this;

      Ember.$(window).on('beforeunload.redemption', function () {
        if (_this2.controller.annoyBeforeLeaving()) {
          return true;
        }
      });
      Ember.$('html').addClass('no-contain');
    },
    deactivate: function deactivate() {
      Ember.$(window).off('beforeunload.redemption');
      Ember.$('html').removeClass('no-contain');
    }
  });
});