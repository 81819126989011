define('tunespeak-ember/models/user-contest-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    remaining: _emberData.default.attr('number'),
    nextActionAt: _emberData.default.attr('date'),
    item: _emberData.default.attr()
  });
});