define('tunespeak-ember/components/ticketmaster-modals', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    ticketmaster: Ember.inject.service(),
    actions: {
      toggleMergeAccountModal: function toggleMergeAccountModal() {
        this.toggleProperty('ticketmaster.showMergeAccountModal');
        if (!this.get('ticketmaster.showMergeAccountModal')) {
          this.get('ticketmaster').dontMergeAccount();
        }
      },
      mergeAccount: function mergeAccount() {
        this.get('ticketmaster').mergeAccount();
      },
      dontMergeAccount: function dontMergeAccount() {
        this.get('ticketmaster').dontMergeAccount();
        this.send('logout');
      },
      toggleEmailConflictModal: function toggleEmailConflictModal() {
        this.toggleProperty('ticketmaster.showEmailConflictModal');
        if (!this.get('ticketmaster.showEmailConflictModal')) {
          this.get('ticketmaster').dontResolveEmailConflict();
        }
      },
      resolveEmailConflict: function resolveEmailConflict() {
        this.get('ticketmaster').resolveEmailConflict();
      },
      toggleSessionEmailConflictModal: function toggleSessionEmailConflictModal() {
        this.toggleProperty('session.showEmailConflictModal');
        if (!this.get('session.showEmailConflictModal')) {
          this.get('session').rejectEmailConflict();
        }
      },
      resolveSessionEmailConflict: function resolveSessionEmailConflict() {
        var _this = this;

        this.get('ticketmaster').require().then(function () {
          _this.get('session').resolveEmailConflict();
        }).catch(function () {
          _this.get('session').rejectEmailConflict();
        });
      },
      toggleLogoutModal: function toggleLogoutModal() {
        this.toggleProperty('ticketmaster.showLogoutModal');
      },
      logout: function logout() {
        this.sendAction('logout');
      }
    }
  });
});