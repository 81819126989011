define('tunespeak-ember/components/campaign/presale-template', ['exports', 'tunespeak-ember/mixins/components/campaign-template', 'tunespeak-ember/utils/enum'], function (exports, _campaignTemplate, _enum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PresaleContestState = new _enum.default({
    send_in_groups: 1,
    tm_handling: 2,
    order_of_place: 3,
    pending: 4
  });

  exports.default = Ember.Component.extend(_campaignTemplate.default, {
    presaleContestState: PresaleContestState.send_in_groups,
    sendInGroups: Ember.computed.equal('presaleContestState', PresaleContestState.send_in_groups),
    tmHandling: Ember.computed.equal('presaleContestState', PresaleContestState.tm_handling),
    orderOfPlace: Ember.computed.equal('presaleContestState', PresaleContestState.order_of_place),
    pending: Ember.computed.equal('presaleContestState', PresaleContestState.pending),
    isCopied: false,
    isCopiedError: false,
    showRefreshButton: false,

    demoState: null,
    isDemoEnded: Ember.computed.equal('demoState', 'ended'),
    isDemoCountdown: Ember.computed.equal('demoState', 'countdown'),
    isDemoCode: Ember.computed.equal('demoState', 'code'),

    actions: {
      didCopyToClipboard: function didCopyToClipboard(options) {
        var _this = this;

        this.set('isCopiedError', false);
        this.set('isCopied', true);
        Ember.run.later(function () {
          _this.set('isCopied', false);
        }, 3000);

        if (options) {
          this.get('googleTracking')('presale-view', {
            label: this.get('model.title')
          });
        }
      },
      didNotCopyToClipboard: function didNotCopyToClipboard() {
        this.set('isCopied', false);
        this.set('isCopiedError', true);
      },
      countdownComplete: function countdownComplete() {
        var _this2 = this;

        var timeout = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 250;

        Ember.run.later(function () {
          _this2.get('userContest').reload().then(function (uc) {
            if (!uc.get('presaleCode')) {
              _this2.send('countdownComplete', timeout * 2);
            }
          });
        }, timeout);
        this.send('trackPresale', 'Viewed Countdown Complete');
      },
      countdownInitialized: function countdownInitialized() {
        this.send('trackPresale', 'Viewed Countdown');
      },
      trackPresale: function trackPresale(eventName) {
        this.get("googleTracking")("presale-view", {
          label: this.get("model.title")
        });
      },
      refresh: function refresh() {
        window.location.reload();
      },
      demoEnd: function demoEnd() {
        this.set('demoState', 'ended');
        this.model.setProperties({
          ended: true,
          endsAt: new Date(),
          presaleStartsAt: moment().add('days', 1).toDate()
        });
      },
      demoCountdown: function demoCountdown() {
        var _this3 = this;

        this.set('demoState', 'countdown');
        var seconds = 11;
        this.get('userContest').setProperties({
          presaleCodeVisibleAt: moment().add('seconds', seconds).toDate()
        });
        Ember.run.later(function () {
          _this3.set('demoState', 'code');
          _this3.get('userContest').set('presaleCode', 'ABC123XYZ456');
        }, seconds * 1000);
      },
      demoReload: function demoReload() {
        location.reload();
      }
    }
  });
});